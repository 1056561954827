import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Image,
  IconButton,
  Grid,
  GridItem,
  VStack,
  Select,
  Input,
  Tabs, 
  TabList, 
  TabPanels,
  Tab,
  TabPanel,
  HStack,
  Text,
  UnorderedList,
  ListItem,
  SimpleGrid,
  useDisclosure
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { FiTrash2 } from 'react-icons/fi';
import axiosInstance, { axiosInstanceIMG } from '../../utils/axio-instance';
import { imagecategories } from '../../constants';
import { useToast } from '@chakra-ui/react';

const ImageUploadModal = ({ isOpen, onClose, propertyId }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState(imagecategories);
  const [category, setCategory] = useState("");
  const [showNewCategoryInput, setShowNewCategoryInput] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [files, setFiles] = useState([]);
  const [uploadedPhotos, setUploadedPhotos] = useState({});
  const imageDomain = process.env.REACT_APP_IMAGE_URL;

  const toast = useToast();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles);
    },
  });

  const { isOpen: isImageModalOpen, onOpen: openImageModal, onClose: closeImageModal } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    setCategory(selectedValue);
    if (selectedValue === "add-new") {
      setShowNewCategoryInput(true);
    } else {
      setShowNewCategoryInput(false);
    }
  };

  const handleSaveCategory = () => {
    if (newCategory) {
      const newCategoryOption = { value: newCategory.toLowerCase().replace(/\s+/g, ""), label: newCategory };
      setCategories((prevCategories) => [...prevCategories, newCategoryOption]);
      setCategory(newCategoryOption.value); // Set value as category for consistency
      setNewCategory("");
      setShowNewCategoryInput(false);
    }
  };

  const handleDrop = (acceptedFiles) => {
    if (category === "search-page" && acceptedFiles.length > 1) {
      toast({
        title: "Only one image allowed",
        description: "You can only upload a single image for the Search Page category.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setFiles(acceptedFiles);
    }
  };

  useEffect(() => {
    if (propertyId) {
      fetchPhotos();
    }
  }, [propertyId]);

  const fetchPhotos = async () => {
    try {
      const response = await axiosInstanceIMG.get(`/images/list/${propertyId}`);
      const photoUrls = {};

      await Promise.all(
        Object.entries(response.data.images).map(async ([category, imageList]) => {
          if (!photoUrls[category]) {
            photoUrls[category] = [];
          }

          const imagePromises = imageList.map(async (imageKey) => {
            const imageUrl = imageDomain+ '/images/download/'+ imageKey;
            return { url: imageUrl, key: imageKey };
          });

          photoUrls[category] = await Promise.all(imagePromises);
        })
      );

      setUploadedPhotos(photoUrls); // Update state with structured image URLs
    } catch (error) {
      console.error('Error fetching photos:', error);
    }
  };

  const handleUpload = async () => {
    if (!category) {
      toast({
        title: "Category Required",
        description: "Please select a category before uploading.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      return;
    }

    const uploadPromises = files.map(async (file) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("category", category);

      try {
        const response = await axiosInstanceIMG.post(`/images/upload/${propertyId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.success) {
          toast({
            title: "Upload Successful",
            description: `Image ${file.name} uploaded successfully.`,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        }
      } catch (error) {
        toast({
          title: "Upload Failed",
          description: `Error uploading image ${file.name}.`,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      }
    });

    await Promise.all(uploadPromises);

    toast({
      title: "Upload Complete",
      description: "All images have been uploaded.",
      status: "info",
      duration: 3000,
      isClosable: true,
      position: 'top-right',
    });

    setFiles([]);
    fetchPhotos();
  };

  const handleDelete = async (key) => {
    try {
      await axiosInstanceIMG.delete(`/images/delete/${key}`);
      setUploadedPhotos((prevPhotos) => {
        const updatedPhotos = { ...prevPhotos };
        for (const category in updatedPhotos) {
          updatedPhotos[category] = updatedPhotos[category].filter(photo => photo.key !== key);
        }
        return updatedPhotos;
      });
      toast({
        title: "Delete Successful",
        description: `Image deleted successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      toast({
        title: "Delete Failed",
        description: `Error deleting the image.`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      console.error("Error deleting image:", error);
    }
  };

  const handleImageClick = (url) => {
    setSelectedImage(url);
    openImageModal();
  };

  return (
     <>
    <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
    <ModalOverlay />
    <ModalContent
      maxH={{ base: "95vh", md: "90vh" }}
      borderRadius="md"
      display="flex"
      flexDirection="column"
    >
      <ModalHeader>Upload Property Images</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        overflowY="auto"
        px={6}
        flex="1" // Makes ModalBody take the available space for scrolling
      >
        <VStack align="start" spacing={4}>
          {/* Select Category */}
          <Select
            placeholder="Select Category"
            value={category}
            onChange={handleCategoryChange}
          >
            {categories.map((cat) => (
              <option key={cat.value} value={cat.value}>
                {cat.label}
              </option>
            ))}
            <option value="add-new">Add New</option>
          </Select>
  
          {/* Add New Category */}
          {showNewCategoryInput && (
            <HStack spacing={4}>
              <Input
                placeholder="Enter new category"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
              />
              <Button onClick={handleSaveCategory} colorScheme="blue">
                Save
              </Button>
            </HStack>
          )}
        </VStack>
  
        {/* Drag and Drop */}
        <Box
          mt={6}
          border="1px dashed"
          borderColor="gray.300"
          p={8}
          textAlign="center"
          borderRadius="md"
          {...getRootProps()}
          onDrop={(e) => handleDrop(e)}
        >
          <input {...getInputProps()} />
          <Text>Drag & drop images here, or click to select files</Text>
        </Box>
  
        {/* Selected Files */}
        {files.length > 0 && (
          <Box mt={6}>
            <Text fontWeight="medium" mb={2}>
              Selected files:
            </Text>
            <UnorderedList>
              {files.map((file, index) => (
                <ListItem key={index}>{file.name}</ListItem>
              ))}
            </UnorderedList>
          </Box>
        )}
  
        {/* Tabs for Uploaded Photos */}
        <Tabs variant="enclosed" mt={10}>
          <TabList>
            {Object.entries(uploadedPhotos).map(([category, photos]) => (
              <Tab key={category}>
                {category.charAt(0).toUpperCase() + category.slice(1)} ({photos.length})
              </Tab>
            ))}
          </TabList>
          <TabPanels>
          {Object.entries(uploadedPhotos).length > 0 ? (
            Object.entries(uploadedPhotos).map(([category, photos]) => (
              <TabPanel key={category}>
                {photos.length > 0 ? (
                  <Box
                    maxH={{ base: "auto", md: "50vh" }}
                    overflowY={{ base: "visible", md: "auto" }}
                    textAlign="center"
                    pt={4}
                  >
                    <SimpleGrid
                      columns={{ base: 2, sm: 3, md: 4 }}
                      spacing={4}
                      justifyItems="center"
                    >
                      {photos.map((photo, index) => (
                        <Box
                          key={index}
                          position="relative"
                          width="100%"
                          display="flex"
                          justifyContent="center"
                          onClick={() => handleImageClick(photo.url)}
                          cursor="pointer"
                        >
                          <Image
                            src={photo.url}
                            alt={`${category} - image ${index}`}
                            width="100%"
                            objectFit="cover"
                            borderRadius="md"
                          />
                          <IconButton
                            icon={<FiTrash2 />}
                            size="sm"
                            colorScheme="red"
                            position="absolute"
                            top="2"
                            right="2"
                            onClick={(e) => {
                              e.stopPropagation(); 
                              handleDelete(photo.key);
                            }}
                          />
                        </Box>
                      ))}
                    </SimpleGrid>
                  </Box>
                ) : (
                  <Box textAlign="center" py={10}>
                    <Text fontSize="lg" color="gray.500">
                      Images not found
                    </Text>
                  </Box>
                )}
              </TabPanel>
            ))
          ) : (
            <TabPanel>
              <Box textAlign="center" py={10}>
                <Text fontSize="lg" color="gray.500">
                  Images not found
                </Text>
              </Box>
            </TabPanel>
          )}
        </TabPanels>

        </Tabs>
      </ModalBody>
  
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
        <Button
          colorScheme="blue"
          ml={3}
          onClick={handleUpload}
          isDisabled={files.length === 0}
        >
          Upload
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>

  <Modal isOpen={isImageModalOpen} onClose={closeImageModal} size="5xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={0}>
            <Image src={selectedImage} width='100%' alt="Full View" />
          </ModalBody>
        </ModalContent>
      </Modal>
  </>
  );
};

export default ImageUploadModal;
