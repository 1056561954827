import { useToast } from '@chakra-ui/react';

/**
 * Custom hook for toast notifications
 * @returns {Object} Toast utility methods
 */
export const useCustomToast = () => {
    const toast = useToast();

    /**
     * Displays a toast notification
     * 
     * @param {Object} options - Toast notification options
     * @param {string} options.title - The title of the toast message
     * @param {string} options.description - Detailed description of the notification
     * @param {string} options.status - Status of the notification 
     * @param {number} [options.duration=3000] - Duration the toast is displayed (ms)
     * @param {boolean} [options.isClosable=true] - Whether the toast can be closed manually
     */
    const showToast = ({
        title, 
        description, 
        status = 'info', 
        duration = 3000, 
        isClosable = true 
    }) => {
        return toast({
            title,
            description,
            status,
            duration,
            isClosable,
            position: 'top-right',
            variant: 'subtle',
        });
    };

    /**
     * Predefined toast methods for common scenarios
     */
    return {
        /**
         * Show a success toast
         * @param {string} title - Title of the success message
         * @param {string} description - Detailed success description
         */
        success: (title, description) => showToast({ 
            title, 
            description, 
            status: 'success' 
        }),

        /**
         * Show an error toast
         * @param {string} title - Title of the error message
         * @param {string} description - Detailed error description
         */
        error: (title, description) => showToast({ 
            title, 
            description, 
            status: 'error' 
        }),

        /**
         * Show a warning toast
         * @param {string} title - Title of the warning message
         * @param {string} description - Detailed warning description
         */
        warning: (title, description) => showToast({ 
            title, 
            description, 
            status: 'warning' 
        }),

        /**
         * Show an informational toast
         * @param {string} title - Title of the info message
         * @param {string} description - Detailed info description
         */
        info: (title, description) => showToast({ 
            title, 
            description, 
            status: 'info' 
        })
    };
};

// Example usage in a component:
// function MyComponent() {
//   const toast = useCustomToast();
//   
//   const handleUpload = () => {
//     toast.success('Upload Complete', '3 images uploaded successfully');
//   }
// }