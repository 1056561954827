import React, { useMemo, useState, useEffect } from "react";
import { useReactTable, getCoreRowModel, flexRender, getFilteredRowModel, getPaginationRowModel } from "@tanstack/react-table";
import axiosInstance,{axiosInstanceES} from "../../utils/axio-instance";
import { Button, useToast, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Input, Text, Select,useDisclosure} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next'; 
import Swal from "sweetalert2";
import ImageUploadModal from '../grid/ImageUploadModal';
import axios from 'axios';
import { propertyTypeOptions, mlsStatusOptions } from '../../constants';
import { useParams,useLocation } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight, FaCross, FaFileUpload, FaRemoveFormat, FaUserEdit } from "react-icons/fa";
import {states} from '../../data/us_states.js';
import WebsiteImagesModal from "../grid/websiteImagesModal.js";



const PropertyTable = () => {
  
  const roleInfo = localStorage.getItem('roles');  
  const storedUserInfo = localStorage.getItem('userid');

  let apiUrl;
  // State management
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [editRowId, setEditRowId] = useState(null);
  const [editRowData, setEditRowData] = useState({});
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [focusedField, setFocusedField] = useState(null);
  const [newRow, setNewRow] = useState({});
  const [communityOptions, setCommunityOptions] = useState([]);
  const [builderOptions, setBuilderOptions] = useState([]);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [showDropdownprop, setShowDropdownprop] = React.useState(false);
  
  
  const [communityMapping, setCommunityMapping] = useState({});
  let location = useLocation();


  const toast = useToast();  
  const { t} = useTranslation();
  const segments = location.pathname.split('/').filter(segment => segment);
  const lastSegment = segments[segments.length - 2];
  const [openWebimages, setOpenWebimages] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [validationErrors, setValidationErrors] = React.useState({});
  const isAdminOrStaff = roleInfo && (roleInfo.includes('admin') || roleInfo.includes('staff')|| roleInfo.includes('dataentry'));

  const onWebImagesClose = ()=>{
    setOpenWebimages(false);
  }
  const fetchData = async () => {
    if (isAdminOrStaff) {
      apiUrl = `/listpropertieswithpagination`;
    } else {
      const userAuthorization = storedUserInfo; 
      apiUrl = `/getpropertiesdatabyuserid?userId=${userAuthorization}`;
    }
    try {
      const response = await axiosInstanceES.get(apiUrl, {
        params: {
          start: pageIndex*pageSize,
          size: pageSize,
          filters: columnFilters.reduce((acc, filter) => {
            acc[filter.id] = filter.value;
            return acc;
          }, {}),
        },
      });

      //const { data: rows, totalCount } = response.data;
      setData(response.data.rows);
      setTotalPages(Math.ceil(response.data.totalCount / pageSize));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };
  // Fetch data from server
  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize, columnFilters]);


  useEffect(() => {
    const pathsToCheck = ["properties", "allproperties"];
    if (pathsToCheck.some(path => location.pathname.includes(path))) {
    //fetchCommunities();
    }
  }, [roleInfo, storedUserInfo, location.pathname]);

  const fetchCommunities = async () => {
    try {
      const response = await axiosInstanceES.get(`/listcommunities`);
      const communities = response.data || [];
      const uniqueCommunityNames = [...new Set(communities.map(c => c.communityName))];
      const uniqueBuilderNames = [...new Set(communities.map(c => c.builderName))];
      setCommunityOptions(uniqueCommunityNames);
      setBuilderOptions(uniqueBuilderNames);

      const mapping = {};
      communities.forEach(community => {
        mapping[community.communityName] = {
          id: community.communityId,
          publicId: community.communityPublicID
        };
      });
      //console.log("communityOptions121",communityOptions);
      setCommunityMapping(mapping);
    } catch (error) {
      console.error('Error fetching community data:', error);
    }
  };

  const autoSuggestCommunities = async (term) => {
    try {
      const response = await axiosInstanceES.get(`/listcommunities?term=${term}`);
      const communities = response.data || [];
      const uniqueCommunityNames = [...new Set(communities.map(c => c.communityName))];
      //setCommunityOptions(uniqueCommunityNames);
      const mapping = {};
      communities.forEach(community => {
        mapping[community.communityName] = {
          id: community.communityId,
          publicId: community.communityPublicID
        };
      });
      setCommunityMapping(mapping);
      return communities;
    } catch (error) {
      console.error('Error fetching community data:', error);
    }
  };


  const equalsFilterFn = (row, columnId, filterValue) => {
  
    const cellValue = row.getValue(columnId);
    return filterValue === "" || cellValue.includes(filterValue);
  };


  // Define columns
  const columns = useMemo(
    () => [
      {
        id: 'actions',
        header: "Actions",
        cell: ({ row }) => {
          const isEditing = editRowId === row.original.id || row.original.isNewRow;
      
          if (isEditing) {
            return row.original.isNewRow ? (
              <div className="flex flex-wrap gap-2 justify-center">
                <Button
                  onClick={handleSave}
                  colorScheme="green"
                  style={{ marginRight: "8px", padding: "4px 8px" }}
                  size="sm"
                >
                  {saveRef.current? "Saving...": "Save"}
                </Button>
                <Button
                  onClick={() => handleCancel(row.original)}
                  colorScheme="red"
                  style={{ padding: "4px 8px" }}
                  size="sm"
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div className="flex flex-wrap gap-2 justify-center">
                <Button
                  onClick={() => handleUpdate(row)}
                  colorScheme="blue"
                  style={{ marginRight: "8px", padding: "4px 8px" }}
                  size="sm"
                >
                  Update
                </Button>
                <Button
                  onClick={() => handleCancel(row.original)}
                  colorScheme="red"
                  style={{ padding: "4px 8px" }}
                  size="sm"
                >
                  Cancel
                </Button>
              </div>
            );
          }
         
          return (
            <div className="flex flex-wrap gap-2 justify-center">
              <Button title="Edit Property"
                onClick={() => handleEdit(row.original)}
                style={{ marginRight: "4px", padding: "4px 8px" }}
                size="sm"
              >Edit
              </Button>
              <Button title="Delete Property"
                onClick={() => handleDelete(row.original.id)}
                style={{ marginRight: "4px",padding: "4px 8px" }}
                size="sm"
              >Delete
              </Button>

              <Button title="Upload images"
                onClick={() => handleUploadClick(row.original.id)}
                style={{ padding: "4px 4px" }}
                size="sm"
              >
                Photos
              </Button>
              
              {isAdminOrStaff && 
              <Button title="Website images"
                onClick={() => handleWebImagesClick(row.original.id)}
                style={{ padding: "4px 4px" }}
                size="sm"
              ><FaFileUpload />
              </Button>}
            </div>
          );
        },
        meta: { sticky: true },
        enableResizing: false, 
      },
      {
        accessorKey: "communityName",
        header: "Community Name",
        filterFn: "includesString",
        Filter: ({ column }) => {
          const [filteredOptions, setFilteredOptions] = React.useState(communityOptions);
          const [inputValue, setInputValue] = React.useState("");
      
          // React.useEffect(() => {
          //   setFilteredOptions(
          //     communityOptions.filter((option) =>
          //       option.communityName.toLowerCase().includes(inputValue.toLowerCase())
          //     )
          //   );
          // }, [inputValue]);
      
          return (
            <div style={{ position: "relative" }}>
              <input
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  column.setFilterValue(e.target.value);
                }}
                placeholder="Filter by Community Name"
                style={{
                  marginTop: "5px",
                  width: "100%",
                  padding: "4px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  color: "#000",
                }}
              />
              {inputValue && filteredOptions.length > 0 && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 101,
                    background: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    width: "100%",
                    maxHeight: "150px",
                    overflowY: "auto",
                  }}
                >
                  {filteredOptions.map((option) => (
                    <div
                      key={option}
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                        background: "#fff",
                        borderBottom: "1px solid #eee",
                      }}
                      onClick={() => {
                        setInputValue(option.communityName);
                        column.setFilterValue(option.communityName);
                      }}
                    >
                      {option.communityName}
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        },
        cell: ({ row }) =>
          editRowId === row.original.id ? (
            <div style={{ position: "relative" }}>
              <input
                type="text"
                value={editRowData.communityName || ""}
                onChange={(e) =>{
                  setEditRowData((prev) => ({
                    ...prev,
                    communityName: e.target.value,
                  }))
                  autoSuggestCommunities(e.target.value).then((options) => {
                    setCommunityOptions(options);
                  });  
                }
                }
                placeholder="Edit Community Name"
                style={{
                  width: "100%",
                  height: "100%",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "4px 8px",
                  boxSizing: "border-box",
                }}
                autoFocus={focusedField === "communityName"}
                onFocus={() =>{setFocusedField("communityName");setShowDropdownprop(true) }} 
                onBlur={() => setTimeout(() => setShowDropdownprop(false), 200)}
              />
              {showDropdownprop && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    background: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    width: "100%",
                    maxHeight: "150px",
                    overflowY: "auto",
                  }}
                >
                  {communityOptions
                    .map((option) => (
                      <div
                        key={option.communityPublicID}
                        style={{
                          padding: "8px",
                          cursor: "pointer",
                          background: "#fff",
                          borderBottom: "1px solid #eee",
                        }}
                        onClick={() => {
                          setEditRowData((prev) => ({
                            ...prev,
                            communityName: option.communityName,
                            communityPublicID: option.communityPublicID,
                            communityId: option.id,
                            builderName: option.builderName,
                            city: option.city,
                            state: option.state,
                            zip: option.zip
                          }));
                          setShowDropdownprop(false);
                        }}
                      >
                        {option.communityName} ({option.builderName})<br/>
                        {option.city}, {option.state} {option.zip}
                      </div>
                    ))}
                </div>
              )}
            </div>
          ) : (
            row.original.communityName
          ),
      },      
      
      {
        accessorKey: "builderName",
        header: "Builder Name",
        filterFn: "includesString",
        Filter: ({ column }) => {
          const [inputValue2, setInputValue2] = React.useState("");
          const [filteredOptions, setFilteredOptions] = React.useState(builderOptions);
      
          // React.useEffect(() => {
          //   setFilteredOptions(
          //     builderOptions.filter((option) =>
          //       option.toLowerCase().includes(inputValue2.toLowerCase())
          //     )
          //   );
          // }, [inputValue2]);
      
          return (
            <div style={{ position: "relative" }}>
              <input
                value={inputValue2}
                onChange={(e) => {
                  setInputValue2(e.target.value);
                  column.setFilterValue(e.target.value);
                }}
                placeholder="Filter by Builder Name"
                style={{
                  marginTop: "5px",
                  width: "100%",
                  padding: "4px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  color: "#000",
                }}
              />
              {inputValue2 && filteredOptions.length > 0 && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    background: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    width: "100%",
                    maxHeight: "150px",
                    overflowY: "auto",
                  }}
                >
                  {filteredOptions.map((option) => (
                    <div
                      key={option}
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                        background: "#fff",
                        borderBottom: "1px solid #eee",
                      }}
                      onClick={() => {
                        setInputValue2(option);
                        column.setFilterValue(option);
                      }}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        },
        cell: ({ row }) => {
          return editRowId === row.original.id ? (
            <div style={{ position: "relative" }}>
              <input
                type="text"
                value={editRowData.builderName || ""}
                onChange={(e) =>
                  setEditRowData((prev) => ({
                    ...prev,
                    builderName: e.target.value,
                  }))
                }
                placeholder="Edit Builder Name"
                style={{
                  width: "100%",
                  height: "100%",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "4px 8px",
                  boxSizing: "border-box",
                }}
                autoFocus={focusedField === "builderName"} 
                onFocus={() =>{setFocusedField("builderName");setShowDropdown(true) }} 
                onBlur={() => setTimeout(() => setShowDropdown(false), 200)} // Add a delay to allow clicks
              />
              {showDropdown && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    background: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    width: "100%",
                    maxHeight: "150px",
                    overflowY: "auto",
                  }}
                >
                  {builderOptions
                    .filter((option) =>
                      option
                        .toLowerCase()
                        .includes(editRowData.builderName?.toLowerCase() || "")
                    )
                    .map((option) => (
                      <div
                        key={option}
                        style={{
                          padding: "8px",
                          cursor: "pointer",
                          background: "#fff",
                          borderBottom: "1px solid #eee",
                        }}
                        onClick={() => {
                          setEditRowData((prev) => ({
                            ...prev,
                            builderName: option,
                          }));
                          setShowDropdown(false);
                        }}
                      >
                        {option}
                      </div>
                    ))}
                </div>
              )}
            </div>
          ) : (
            row.original.builderName
          );
        },
      },      
      { 
        accessorKey: "modelName",
         header: "Model Name",
         filterFn: "includesString",
        Filter: ({ column }) => (
          <input
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder="Filter by Model Name"
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
            autoFocus={focusedField === "modelName"} 
            onFocus={() => setFocusedField("modelName")} 
          />
        ),
        cell: ({ row }) =>
          editRowId === row.original.id ? (
            <input
              type="text"
              value={editRowData.modelName || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, modelName: e.target.value }))
              }
              placeholder="Edit Modal Name"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "modelName"} 
              onFocus={() => setFocusedField("modelName")} 
            />
          ) : (
            <span className="cursor-pointer hover:underline" onClick={() => window.open(`/home/en/${row.original.state}/${row.original.city}/${row.original.id}`, '_blank')}>{row.original.modelName}</span>
          ),
         },
           {
        accessorKey: "address",
        header: "Full Address",
        filterFn: "includesString",
        Filter: ({ column }) => (
          <input
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder="Filter by Address"
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
            autoFocus={focusedField === "address"} 
            onFocus={() => setFocusedField("address")} 
          />
        ),
        cell: ({ row }) => {
          const { street, city, state, zip } = row.original;
          return [street, city, state, zip].filter(Boolean).join(", ");
        },
      },
      { 
        accessorKey: "property_type",
        header: "Property Type",
        filterFn: equalsFilterFn,
        Filter: ({ column }) => (
          <select
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
          >
            <option value="">All</option>
            {propertyTypeOptions.map((property) => (
              <option key={property.value} value={property.value}>
                {property.label}
              </option>
            ))}
          </select>
        ),
        cell: ({ row }) =>
          editRowId === row.original.id ? (
            <select
            value={editRowData.property_type || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, property_type: e.target.value }))
              }
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
          >
            <option value="">All</option>
            {propertyTypeOptions.map((property) => (
              <option key={property.value} value={property.value}>
                {property.label}
              </option>
            ))}
          </select>
          ) : (
            propertyTypeOptions.find(option => option.value === row.original.property_type)?.label || row.original.property_type
          ),
       },
      { 
        accessorKey: "price", 
        header: "Price",
        cell: ({ row }) =>
          editRowId === row.original.id ? (
            <input
              type="text"
              value={editRowData.price || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, price: e.target.value }))
              }
              placeholder="Edit Price"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "price"} 
              onFocus={() => setFocusedField("price")} 
            />
          ) : (
            row.original.price
          ),
      },
      { 
        accessorKey: "bedrooms",
        header: "No of Bedrooms",
        cell: ({ row }) =>
          editRowId === row.original.id ? (
            <input
              type="text"
              value={editRowData.bedrooms || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, bedrooms: e.target.value }))
              }
              placeholder="Edit Bed Rooms"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "bedrooms"} 
              onFocus={() => setFocusedField("bedrooms")} 
            />
          ) : (
            row.original.bedrooms
          ),
      },
      { 
        accessorKey: "bathrooms", 
        header: "No of Bathrooms",
        cell: ({ row }) =>
          editRowId === row.original.id ? (
            <input
              type="text"
              value={editRowData.bathrooms || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, bathrooms: e.target.value }))
              }
              placeholder="Edit Bath Rooms"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "bathrooms"} 
              onFocus={() => setFocusedField("bathrooms")} 
            />
          ) : (
            row.original.bathrooms
          ),
       },
       { 
        accessorKey: "partial_bathrooms", 
        header: "Partial Bathrooms",
        cell: ({ row }) =>
          editRowId === row.original.id ? (
            <input
              type="text"
              value={editRowData.partial_bathrooms || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, partial_bathrooms: e.target.value }))
              }
              placeholder="Edit Partial Bath Rooms"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "partial_bathrooms"} 
              onFocus={() => setFocusedField("partial_bathrooms")} 
            />
          ) : (
            row.original.partial_bathrooms
          ),
       },
      { 
        accessorKey: "totalrooms", 
        header: "Total Rooms",
        cell: ({ row }) =>
          editRowId === row.original.id ? (
            <input
              type="text"
              value={editRowData.totalrooms || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, totalrooms: e.target.value }))
              }
              placeholder="Edit Total Rooms"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "totalrooms"} 
              onFocus={() => setFocusedField("totalrooms")} 
            />
          ) : (
            row.original.totalrooms
          ),
      },
      { 
        accessorKey: "sqft",
        header: "Living SQFT",
        cell: ({ row }) =>
          editRowId === row.original.id ? (
            <input
              type="text"
              value={editRowData.sqft || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, sqft: e.target.value }))
              }
              placeholder="Edit Living Sqft"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "sqft"} 
              onFocus={() => setFocusedField("sqft")} 
            />
          ) : (
            row.original.sqft
          ),
       },
      { 
        accessorKey: "stories",
        header: "No of Stories",
        cell: ({ row }) =>
          editRowId === row.original.id ? (
            <input
              type="text"
              value={editRowData.stories || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, stories: e.target.value }))
              }
              placeholder="Edit Stories"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "stories"} 
              onFocus={() => setFocusedField("stories")} 
            />
          ) : (
            row.original.stories
          ),
      },
      // { 
      //   accessorKey: "property_description", 
      //   header: "Description",
      //   cell: ({ row }) => {
      //     const description = row.original.property_description || "";
      //     return description.length > 30
      //       ? `${description.substring(0, 30)}...`
      //       : description;
      //   },
      //  },
      {
        accessorKey: "property_description",
        header: "Description",
        cell: ({ row }) =>
          editRowId === row.original.id ? (
            <input
              type="text"
              value={editRowData.property_description || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({
                  ...prev,
                  property_description: e.target.value,
                }))
              }
              placeholder="Edit Description"
              style={{
                width: "100%",
                height: "100%",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "property_description"}
              onFocus={() => setFocusedField("property_description")}
            />
          ) : (
            row.original?.property_description?.length > 30
              ? `${row.original?.property_description?.substring(0, 30)}...`
              : row.original?.property_description
          ),
      },
      
      { 
        accessorKey: "street",
         header: "Street",
         cell: ({ row }) =>
          editRowId === row.original.id ? (
            <input
              type="text"
              value={editRowData.street || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, street: e.target.value }))
              }
              placeholder="Edit Street Name"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "street"} 
              onFocus={() => setFocusedField("street")} 
            />
          ) : (
            row.original.street
          ),
        },
      {
         accessorKey: "city",
          header: "City",
          filterFn: "includesString",
        Filter: ({ column }) => (
          <input
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder="Filter by City"
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
            autoFocus={focusedField === "city"} 
            onFocus={() => setFocusedField("city")} 
          />
        ),
        cell: ({ row }) =>
          editRowId === row.original.id ? (
            <input
              type="text"
              value={editRowData.city || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, city: e.target.value }))
              }
              placeholder="Edit City Name"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "city"} 
              onFocus={() => setFocusedField("city")} 
            />
          ) : (
            row.original.city
          ),
        },
      { 
        accessorKey: "state",
        header: "State",
        filterFn: "includesString",
        Filter: ({ column }) => (
          <input
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder="Filter by State"
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
            autoFocus={focusedField === "state"} 
            onFocus={() => setFocusedField("state")} 
          />
        ),
        cell: ({ row }) =>
          editRowId === row.original.id ? (
            <select
              value={editRowData.state || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, state: e.target.value }))
              }
              style={{
                width: "100%",
                height: "100%",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "state"}
              onFocus={() => setFocusedField("state")}
            >
              <option value="" disabled>
                Select a state
              </option>
              {states.map((state) => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </option>
              ))}
            </select>
          ) : (
            row.original.state
          ),
      
      },
      { 
        accessorKey: "zip",
         header: "Zip",
         cell: ({ row }) =>
          editRowId === row.original.id ? (
            <input
              type="text"
              value={editRowData.zip || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, zip: e.target.value }))
              }
              placeholder="Edit Zip code"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "zip"} 
              onFocus={() => setFocusedField("zip")} 
            />
          ) : (
            row.original.zip
          ),
        }
    ],
    [editRowId, editRowData]
  );

  const table = useReactTable({
    data,
    columns,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    manualFilters: true,
    getFilteredRowModel: getFilteredRowModel(),
    pageCount: Math.ceil(data.length / pageSize),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
    state: {
      globalFilter,
      columnFilters,
      pagination: {
        pageSize,
        pageIndex: 0, // Initial page index
      },
    },
  });

  // Handle pagination changes
  const handlePageClick = (page) => setPageIndex(page);

  const handlePreviousPage = () => {
    if (pageIndex > 0) setPageIndex((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (pageIndex < totalPages - 1) setPageIndex((prev) => prev + 1);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setPageIndex(0);
  };

  // Pagination Range Logic
  const getPaginationRange = () => {
      const visiblePages = 5; // Show 5 page buttons at most
      const pages = [];
  
      // Add first page
      if (pageIndex > 2) {
        pages.push(0);
        if (pageIndex > 3) pages.push("...");
      }
  
      // Add pages around the current page
      for (
        let i = Math.max(pageIndex - 2, 0);
        i <= Math.min(pageIndex + 2, totalPages - 1);
        i++
      ) {
        pages.push(i);
      }
  
      // Add last page
      if (pageIndex < totalPages - 3) {
        if (pageIndex < totalPages - 4) pages.push("...");
        pages.push(totalPages - 1);
      }
  
      return pages;
    };
  
    const paginationRange = getPaginationRange();

  // Edit Handlers
  const handleEdit = (rowData) => {
    setEditRowId(rowData.id);
    setEditRowData(rowData);
    setFocusedField(null);
  };

 const handleCancel = (row) => {
    if (row.isNewRow) {
      // Remove the new row from data
      setData((prevData) => prevData.filter((item) => item !== row));
      setNewRow(null);
    } else {
      setEditRowId(null);
     setEditRowData({});
     setFocusedField(null);
    }
  };

  const handleAddRow = () => {
    const newRow = { 
      id: null,
      communityId: '',
      communityPublicID: '',
      modelName : '',
      address: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      mlsStatus: '',
      property_type: '',
      price: '',
      bedrooms: '',
      bathrooms: '',
      partial_bathrooms: '',
      sqft: '',
      no_of_stories: '',
      photos: '',
      property_description: '',
      lot_area: '',
      built_year: '',
      daysonthemarket: '',
      pool: '',
      totalrooms: '',
      isNewRow: true,
    };
    setNewRow(newRow);
    setEditRowData(newRow);
    setData((prevData) => [newRow, ...prevData]); // Prepend the new row
  };

  const handleUploadClick = (propertyId) => {
    setSelectedPropertyId(propertyId); // Set the propertyId for the modal
    onOpen(); // Open the modal
  };

  const handleWebImagesClick = (propertyId) => {
    setSelectedPropertyId(propertyId); // Set the propertyId for the modal
    setOpenWebimages(true); 
  };


  const fetchLatLong = async (address) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);
      const data = response.data;

      if (data.status === "OK" && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        console.log("enterif",  data.results[0].geometry.location);
        return { lat: Number(lat), lng: Number(lng) };
      } else {
        console.warn("No results found for the address:", address);
        return { lat: null, lng: null };  // Return null if no results are found
      }
    } catch (error) {
      console.error('Error fetching latitude and longitude:', error);
      return { lat: null, lng: null };
    }
  };


  const transformData = async (row) => {
    let lat = null;
    let lng = null;
    const fullAddress = `${row.street|| ''}, ${row.city || ''}, ${row.state || ''}, ${row.zip || ''}`;
    if (fullAddress !== ", , , ") {
      try {
        // Wait for the fetchLatLong function to resolve
        const locationData = await fetchLatLong(fullAddress);
        lat = locationData?.lat;
        lng = locationData?.lng;
      } catch (error) {
        console.error("Error fetching lat/lng:", error);
      }
   
    }
    return {
      location:lat && lng 
      ? `${lat},${lng}` 
      : null, 
      main: {
        sourceId: row.builderName || '',
        publicId: row.id,
        communityPublicID: row.communityPublicID,
        propertyStatus: "active",
        recordCreatedDate: new Date().toISOString(),
        createdRole: roleInfo,
        createdByUser: storedUserInfo,
        recordType: 'property',
      },
      data: {
        propertyInfo: {
          address: {
            label: row.address,
            street: row.street,
            city: row.city,
            state: row.state,
            zip: row.zip
          },
          bedrooms: parseInt(row.bedrooms) || null,
          bathrooms: parseInt(row.bathrooms) || null,
          buildingSquareFeet: parseInt(row.sqft) || null,
          roomsCount: parseInt(row.totalrooms) || null,
          livingSquareFeet: row.sqft || '', 
          stories: parseInt(row.stories) || null,
          partialBathrooms: parseInt(row.partial_bathrooms) || null,
        },
        mlsListingPrice: parseInt(row.price) || null,
        lotInfo: {
          lotSquareFeet: parseInt(row.lot_area) || null,
          legalDescription: row.property_description || null
        },
        propertyType: row.property_type,
      
      },
       extraData: {
        modelName: row.modelName || null,
        modelConstructionStatus: "Ready to Build",
        numberOfGarageSpaces: 1
      },
      builder : {
        builderName: row.builderName || ''
      },
      community: {
        communityId:  row.communityId || null,
        communityName: row.communityName || ''
      }
    };
  
  };


  const savetransformData = async (row) => {
    const response = await axiosInstanceES.get(`/latestpropertyid`);
    const data = response.data;
    const latestId =  data.latestPropertyId || 0; // Handle default case
    const newId = latestId + 1;

    let lat = null;
    let lng = null;
    const fullAddress = `${row.street|| ''}, ${row.city || ''}, ${row.state || ''}, ${row.zip || ''}`;
    if (fullAddress !== ", , , ") {
      try {
        // Wait for the fetchLatLong function to resolve
        const locationData = await fetchLatLong(fullAddress);
        lat = locationData?.lat;
        lng = locationData?.lng;
      } catch (error) {
        console.error("Error fetching lat/lng:", error);
      }
   
    }
    return {
      location:lat && lng 
      ? `${lat},${lng}` 
      : null, 
      main: {
        sourceId: row.builderName || '',
        publicId: newId,
        communityPublicID: row.communityPublicID,
        propertyStatus: "active",
        recordCreatedDate: new Date().toISOString(),
        createdRole: roleInfo,
        createdByUser: storedUserInfo,
        recordType: 'property',
      },
      data: {
        propertyInfo: {
          address: {
            label: fullAddress,
            street: row.street,
            city: row.city,
            state: row.state,
            zip: row.zip
          },
          bedrooms: parseInt(row.bedrooms) || null,
          bathrooms: parseInt(row.bathrooms) || null,
          buildingSquareFeet: parseInt(row.sqft) || null,
          roomsCount: parseInt(row.totalrooms) || null,
          livingSquareFeet: row.sqft || '', 
          stories: parseInt(row.stories) || null,
          partialBathrooms: parseInt(row.partial_bathrooms) || null,
        },
        mlsListingPrice: parseInt(row.price) || null,
        lotInfo: {
          lotSquareFeet: parseInt(row.lot_area) || null,
          legalDescription: row.property_description || null
        },
        propertyType: row.property_type,
      
      },
       extraData: {
        modelName: row.modelName || null,
        modelConstructionStatus: "Ready to Build",
        numberOfGarageSpaces: 1
      },
      builder : {
        builderName: row.builderName || ''
      },
      community: {
        communityId:  row.communityId || null,
        communityName: row.communityName || ''
      }
    };
  
  };

  const handleUpdate = async (event) => {
    try {
      const transformedData = await transformData(editRowData);
      if(transformData!=null){
      await axiosInstanceES.put(`/updatepropertie/${event.original.id}`, transformedData);
      setEditRowId(null);
      setFocusedField(null);
      toast({
        title: t('property-updatedmessage'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      await fetchData();
    }
    } catch (error) {
      console.error('Error updating row:', error);
      toast({
        title: 'Error',
        description: t('updatefailed'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }
const saveRef = React.useRef();

  const handleSave = async () => {
    try {
        if(saveRef.current) return;
        saveRef.current = true;
        const isValid = await validateNewRow(editRowData);
        if (!isValid) {
          saveRef.current = false;
          return; // Stop the save operation if validation fails
        }
      
      const transformedData =   await savetransformData(editRowData);
      
      const storedUserInfo = localStorage.getItem('userid');
  
      // Prepare payload with user information and properties
      const payload = {
        role : roleInfo,
        properties: [transformedData.main.publicId] 
      };

      //console.log("payload data",payload);

      const dynamoApiUrl = `/users/${storedUserInfo}/properties`;
      const elasticsearchApiUrl = `/addproperty`;
  
      // Save to DynamoDB
      const dynamoResponse = await axiosInstance.post(dynamoApiUrl, payload);
  
      // Save to Elasticsearch
      const elasticsearchResponse = await axiosInstanceES.post(elasticsearchApiUrl, transformedData);
  
      // Check if both requests were successful
      if (dynamoResponse.status === 200 && elasticsearchResponse.status === 200) {
        setNewRow(null);
        toast({
          title: t('property-addedmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        await fetchData();
      } else {
        throw new Error('One of the requests failed');
      }
    } catch (error) {
      console.error('Error saving new row:', error);
      toast({
        title: 'Error',
        description : "There was an error adding the property.",
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }finally{
      saveRef.current = false;
    }
  };

  const validateNewRow = async (newRow) => {
    const requiredFields = ['communityName', 'builderName','modelName', 'street', 'city', 'state', 'zip', 'property_type', 'price', 'bedrooms', 'bathrooms', 'partial_bathrooms', 'totalrooms', 'sqft', 'stories'];
    const numericFields = ['bedrooms', 'bathrooms', 'partial_bathrooms', 'totalrooms', 'stories', 'price', 'zip'];
    const errors = [];
    const numericErrors = [];
  
    // Fetch existing addresses from the backend
    // let existingAddresses = [];
    // try {
    //   const response = await axiosInstanceES.get(`/addresslist`);
    //   existingAddresses = response.data;
    // } catch (error) {
    //   console.error('Error fetching existing addresses:', error);
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Validation Error',
    //     text: 'Unable to validate addresses. Please try again later.'
    //   });
    //   return false;
    // }
  
    const isDuplicateAddress = (newRow) => {
      // Check if the new row's street1 matches any existing address's street1
      // return existingAddresses.some(
      //   (address) =>
      //     address.street=== newRow.street
      // );
      return false;
    };
    if(!newRow.communityPublicID){
      errors.push('communityName');
    }

    requiredFields.forEach(field => {
      if (!newRow[field]) {
        errors.push(field);
      }
    });
  
    numericFields.forEach(field => {
      if (newRow[field] !== undefined && newRow[field] !== null && isNaN(newRow[field])) {
        numericErrors.push(field);
      }
    });
  
    // Check for duplicate addresses
    if (isDuplicateAddress(newRow)) {
      errors.push('street already exists');
    }
  
    if (errors.length > 0 || numericErrors.length > 0) {
      let errorMessage = '';
  
      if (errors.length > 0) {
        // Check for combined address fields
        if (errors.includes('street already exists')) {
          errorMessage += `The address with street '${newRow.street}' already exists. `;
          errors.splice(errors.indexOf('street'), 1);
        }
        errorMessage += `Please fill in the following required fields: ${errors.join(', ')}. `;
      }
  
      if (numericErrors.length > 0) {
        errorMessage += `The following fields should only contain numeric values: ${numericErrors.join(', ')}.`;
      }
  
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: errorMessage.trim()
      });

      return false;
    }

    return true;
  };

  const handleDelete = async (id) => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure you want to delete?',
      text: 'You will not be able to recover this Property!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
  
    if (isConfirmed) {
      try {
        // Check if the row is a new row (not yet saved in the database)
        const isNewRow = data === id;
  
        if (isNewRow) {
          setData(data.filter(row => row.id !== id));
          toast({
            title:t('property-removemessage'),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        } else {
          const response = await axiosInstanceES.delete(`/deleteproperty/${id}`);
          console.log(response.status, response.data);
          if (response.status === 200) {
            setData((prevRowData) => prevRowData.filter(row => row.id !== id));
            //gridOptions.api.refreshInfiniteCache();
            toast({
              title:  t('property-deletemessage'),
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'top-right',
            });
            fetchData();
          } else {
            throw new Error('Failed to delete Property');
          }
        }
      } catch (error) {
        console.error('Error deleting Property:', error);
        toast({
          title: 'Error',
          description:  t('Error deleting Property'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      }
    }
  };

  return (
    <div className="bg-primary font-linksans">
    <div className="w-full">
         <div className="flex justify-start mb-4">
            <Button colorScheme="blue" style={{borderWidth:"1px", borderColor: '#3b82f6', borderRadius:"8px" }} onClick={handleAddRow}>
              Add New Property
            </Button>
          </div>
      {/* Table */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
        <div className="relative overflow-x-auto white-space-nowrap">
        <table
         className="w-full text-sm text-left text-gray-500 border border-gray-300 bg-gray-50 min-h-32"
         style={{
           borderCollapse: "collapse",
         }}
        >
            <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className={`border-b border-gray-300 p-2 text-center ${
                  header.column.id === 'actions' ? 'sticky right-0 bg-gray-100 z-20 shadow-md' : ''
                }`}
                style={{
                  minWidth: "250px", // Adjust this width as needed for better responsiveness
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}

                 {/* Column Filters */}
                 {header.column.getCanFilter() ? (
                    <div className="mt-1">
                      {flexRender(
                        header.column.columnDef.Filter,
                        header.getContext()
                      )}
                    </div>
                  ) : null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className={`border-b border-gray-300 p-2 text-center ${
                  cell.column.id === 'actions' ? 'sticky right-0 bg-white z-10 shadow-md' : ''
                }`}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
          </table>
          </div>
         <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        <Button
          onClick={handlePreviousPage}
          disabled={pageIndex === 0}
          style={{ marginRight: "10px" }}
        >
           <FaChevronLeft />
        </Button>

        {paginationRange.map((page, idx) =>
          page === "..." ? (
            <span key={idx} style={{ margin: "0 5px" }}>
              ...
            </span>
          ) : (
            <Button
              key={page}
              onClick={() => handlePageClick(page)}
              style={{
                marginRight: "5px",
                backgroundColor: pageIndex === page ? "blue" : "gray",
                color: pageIndex === page ? "gray" : "black",
              }}
            >
              {page + 1}
            </Button>
          )
        )}

        <Button
          onClick={handleNextPage}
          disabled={pageIndex >= totalPages - 1}
          style={{ marginLeft: "10px" }}
        >
           <FaChevronRight />
        </Button>
      </Box>
      {/* Page Size Selector */}
      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        <Select
          value={pageSize}
          onChange={handlePageSizeChange}
          width="auto"
          style={{ marginLeft: "10px" }}
        >
          {[100, 200, 300, 400, 500].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </Select>
      </Box>
           {/* Community Upload Modal */}
                {isOpen && (
                    <ImageUploadModal
                    isOpen={isOpen}
                    onClose={onClose}
                    propertyId={selectedPropertyId} // Pass the selected propertyId
                    />
                )} 
                {openWebimages && (
                    <WebsiteImagesModal
                    isOpen={openWebimages}
                    onClose={onWebImagesClose}
                    propertyId={selectedPropertyId} // Pass the selected propertyId
                    />
                )}
        </>
      )}
    </div>
    </div>
  );
};

export default PropertyTable;
