import React, { useState, useEffect } from 'react';
import { useAuth } from '../../components/user/AuthProvider';
import { useNavigate, Link, NavLink, useLocation } from 'react-router-dom';
import { Links } from "../../constants";
import { useProducts } from '../compare/ProductsProvider';

const getUserLinks = (roles) => {
  const uniqueLinks = new Set();
  
  roles.forEach(role => {
    if (Links[role]) {
      Links[role].forEach(link => uniqueLinks.add(JSON.stringify(link)));
    }
  });

  return Array.from(uniqueLinks).map(link => JSON.parse(link));
};

const HeaderLinks = ({ profileInfo }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation(); 
  const [activeIndex, setActiveIndex] = useState(null);

  const drawerItems = profileInfo ? getUserLinks(profileInfo.role) : [];
  const { selectedProducts, groupProductCounts } = useProducts();


   // Set active index based on the current URL path on component mount
   useEffect(() => {
    const currentPath = location.pathname;
    const initialIndex = drawerItems.findIndex(item => item.path === currentPath);
    if (initialIndex !== -1) {
      setActiveIndex(initialIndex);
    }else{
      setActiveIndex(-1);
    }
  }, [location.pathname, drawerItems]);

  const handleLinkClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      {drawerItems.map((item, index) => (
          <Link 
          key={index}
          to={item.path}
          onClick={() => handleLinkClick(index)} // Set active index on click
          style={{
          display: 'inline-block',
          paddingBottom: '5px',  // Space for the underline
          borderBottom: activeIndex === index ? '2px solid black' : 'none',  // Active underline
          textDecoration: 'none',  // Remove default underline
          color: 'black',  // Link color
          fontWeight: activeIndex === index ? 'bold' : 'normal',
        }}
          >
            <span className="flex items-center">
            {item.label}

            {groupProductCounts !== null && item.label === 'Shortlists' && (
              <span className="inline-flex items-center py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                ( {groupProductCounts?.total > 100 ? '100+' : groupProductCounts?.total || 0} )
              </span>
            )}
            </span>
          </Link>
    ))}
    </>
  );
};

export default HeaderLinks;
