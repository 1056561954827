import React, { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react'; 
import axiosInstance,{axiosInstanceES} from '../../utils/axio-instance';
import { propertyTypeOptions, creditScoreRange, whenToBuyPlan } from '../../constants';
import { useTranslation } from 'react-i18next'; 
import {states} from '../../data/us_states.js';
import { useAuth } from '../user/AuthProvider.js';
import { FaInfoCircle } from "react-icons/fa";
import InfoBar from '../../utils/InfoBar.js';



const GroupBuyerPreference = () => {
  const profileId = localStorage.getItem('userid');
  const [creditScoreRangeValue, setCreditScoreRangeValue] = useState("");
  const [whenToBuyPlanValue, setWhenToBuyPlanValue] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [message, setMessage] = useState("");
  const [location, setLocation] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState("");
  
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [priceMin, setPriceMin] = useState('');
  const [priceMax, setPriceMax] = useState('');
  const [complist, setCompList] = useState([]);
  const [company, setCompany] = useState("");
  const [companyId, setCompanyId] = useState(""); 
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);


  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t} = useTranslation();
  const toast = useToast();
  const {globalMsg, setGlobalMsg} = useAuth();

  useEffect(()=>{
    axiosInstanceES.get(`/companies`)
      .then(response => {
        const frags = {states:new Set(), cities: new Set()};
        setCompList(response.data);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
  },[]);

  useEffect(() => {
    // Fetch initial preferences
    const fetchPreferences = async () => {
      try {
        const response = await axiosInstance.get(`/users/${profileId}/preferences`);
        
        const { preferredLocation = [], preferredPropertyTypes = [], preferredPriceRange = [],  creditScoreRangeValue = '', whenToBuyPlanValue = '', downPayment = '', message='',  companyId='', company='', latitude='', longitude='' } = response.data || {};
        
        const city = preferredLocation.length > 0 ? preferredLocation[0] : '';
        const state = preferredLocation.length > 1 ? preferredLocation[1] : '';
        const address = `${city || ''}${city && state ? ', ' : ''}${state || ''}`;
        setCompany(company || '');
        setCompanyId(companyId || '');
        setLocation(preferredLocation.length > 0 ? preferredLocation[1] : '');
        setCity(preferredLocation.length > 0 ? preferredLocation[0] : '');
        setAddress(address.trim());
        setCreditScoreRangeValue(creditScoreRangeValue || '');
        setWhenToBuyPlanValue(whenToBuyPlanValue || '');
        setDownPayment(downPayment || '');
        setMessage(message || '');
        setLatitude(latitude || '');
        setLongitude(longitude || '');
        setZipCode(zipCode || '');
        setPropertyType(preferredPropertyTypes.length > 0 ? preferredPropertyTypes[0] : '');
        setPriceMin(preferredPriceRange.length > 0 ? preferredPriceRange[0] : '');
        setPriceMax(preferredPriceRange.length > 1 ? preferredPriceRange[1] : '');
      } catch (error) {
        console.error("Error fetching preferences:", error);
      }
    };
    
    fetchPreferences();
  }, [profileId]);


  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
  
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=initAutocomplete&libraries=places&v=weekly`;
    script.defer = true;
    document.head.appendChild(script);

    window.initAutocomplete = () => {
      const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById("address"), {
        types: ["(cities)"], // Restrict to cities only
        componentRestrictions: { country: "us" },
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
  
        // Extract city and state
        const addressComponents = place.address_components || [];
        const cityComponent = addressComponents.find((component) =>
          component.types.includes('locality')
        );
        const stateComponent = addressComponents.find((component) =>
          component.types.includes('administrative_area_level_1')
        );

        const zipCodeComponent = addressComponents.find((component) =>
          component.types.includes("postal_code")
        );
  
        setCity(cityComponent?.long_name || '');
        setLocation(stateComponent?.short_name || '');
        setZipCode(zipCodeComponent?.long_name || "");
        // Extract latitude and longitude
        const lat = place.geometry?.location?.lat();
        const lng = place.geometry?.location?.lng();
        setLatitude(lat || '');
        setLongitude(lng || '');
  
        // Update address field with formatted address
        setAddress(place.formatted_address || '');
      });
    };
  }, []);

  const handleSuggestionClick = (description) => {
    setAddress(description);
    setSuggestions([]);
  };

  const validate = () => {
    let formErrors = {};
    if (!address) formErrors.address = 'Address is required';
    if (!propertyType) formErrors.propertyType = 'Property type is required';
    if (!creditScoreRangeValue) formErrors.creditScoreRange = 'creditScoreRange is required';
    if (!whenToBuyPlanValue) formErrors.whenToBuyPlan = 'When to Buy filed is required';
    if (!downPayment) formErrors.downPayment = 'Down Payment is required';
    if (!company) {
        formErrors.company = "Company is required";
      }
    if (!priceMin || parseInt(priceMin) < 0) formErrors.priceMin = 'Min price must be a valid number';
    if (!priceMax || parseInt(priceMax) < parseInt(priceMin)) formErrors.priceMax = 'Max price must be greater than Max price';
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage('');
    setErrorMessage('');

    if (!validate()) {
      setIsSubmitting(false);
      return;
    }
    const kcuserid = localStorage.getItem("kc_uid");
    const updatedPreferences = {
      company: company,
      companyId: companyId,
      preferredLocation: [city, location],
      preferredPropertyTypes: [propertyType],
      preferredPriceRange: [priceMin, priceMax],
      creditScoreRangeValue: creditScoreRangeValue,
      whenToBuyPlanValue: whenToBuyPlanValue,
      downPayment: downPayment,
      message : message,
      latitude : latitude,
      longitude : longitude,
      zipcode : zipCode,
      kc_uid: kcuserid
    };

    try {
      const response = await axiosInstance.post(`/users/${profileId}/updatePreferences`, updatedPreferences);
      
      if (response.status === 200) {
        setSuccessMessage(t('saved'));
        setGlobalMsg('');
        toast({
          title: t('preference-successmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (error) {
      let errorMessage = 'Failed to update preferences. Please try again later.';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      setErrorMessage(t('failed'));
      toast({
        title: 'Error',
        description: t('preference-errormessage'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCompanyChange = (e) => {
    const value = e.target.value.trim();
    setCompany(value);

    // Filter the companies based on the input
    const filtered = complist.filter((company) =>
      company.company_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCompanies(filtered);
  };

  const handleSelectCompany = (company) => {
    setCompany(company.company_name || "");
    setCompanyId(company.companyid || ""); // Set the company ID
    setFilteredCompanies([]); // Clear the filtered list
  };

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timeout
    }
  }, [successMessage, errorMessage]);

  return (
    <div className="bg-primary  font-linksans pb-12">
      <div className="container px-5 min-h-svh">
      {globalMsg && (
        <InfoBar message={t(globalMsg)} />
        )}

        <div className="flex-col items-center flex justify-center py-12">
        <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-textColor pb-10">
        Preferences
        </h3>

      <div className="w-full flex justify-center">
      <form action="" className="w-[500px] max-w-xl flex flex-col gap-3">

      <div className="flex flex-col gap-2">
                <label className="text-sm text-paragraphColor" htmlFor="company">
                  Company <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="company"
                  placeholder="Enter Company Name"
                  className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white"
                  value={company}
                  onChange={handleCompanyChange}
                />
                {errors.company && <span className="text-sm text-red-400">{errors.company}</span>}

                {filteredCompanies.length > 0 && (
                  <ul className="bg-white border border-gray-300 rounded-lg mt-2 max-h-60 overflow-y-auto">
                    {filteredCompanies.map((company) => (
                      <li
                        key={company.companyid}
                        className="px-3 py-2 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleSelectCompany(company)}
                      >
                        {company.company_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="flex flex-col gap-2">
          <label  className="text-sm text-paragraphColor" htmlFor="address">Address <span style={{ color: "red" }}>*</span></label>
          <input
            id="address"
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white"
            placeholder="Enter your address"
          />
          {loading && <p>Loading suggestions...</p>}
          {suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleSuggestionClick(suggestion.description)}
                >
                  {suggestion.description}
                </li>
              ))}
            </ul>
          )}
         
           {errors.address && (
              <span className="text-sm text-red-400">{errors.address}</span>
            )}
        </div>
          <input type="hidden" name="latitude" value={latitude}/>
          <input type="hidden" name="longitude" value={longitude}/>
          <input type="hidden" name="city" value={city}/>
          <input type="hidden" name="location" value={location}/>
          <input type="hidden" name="zipcode" value={zipCode}/>

            <div className="flex flex-col gap-2">
              <label className="text-sm text-paragraphColor" htmlFor="propertyType">
                Property Type <span style={{ color: "red" }}>*</span>
              </label>
              <select 
                className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" 
                id="propertyType" 
                value={propertyType} 
                onChange={(e) => setPropertyType(e.target.value)}
              >
                <option value="">Select Property Type</option>
                {propertyTypeOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.propertyType && <span className="text-sm text-red-400">{errors.propertyType}</span>}
            </div>

            <div className="flex flex-wrap gap-4">
              <div className="flex flex-col gap-2 w-full md:flex-1">
                <label className="text-sm text-paragraphColor" htmlFor="min-price">
                  Min Price <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="number"
                  placeholder="Enter Min Price"
                  className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white w-full"
                  id="min-price"
                  value={priceMin}
                  onChange={(e) => setPriceMin(e.target.value)}
                  min="0"
                />
                {errors.priceMin && (
                  <span className="text-sm text-red-400">{errors.priceMin}</span>
                )}
              </div>

              <div className="flex flex-col gap-2 w-full md:flex-1">
                <label className="text-sm text-paragraphColor" htmlFor="max-price">
                  Max Price <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="number"
                  placeholder="Enter Max Price"
                  className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white w-full"
                  id="max-price"
                  value={priceMax}
                  onChange={(e) => setPriceMax(e.target.value)}
                  min="0"
                />
                {errors.priceMax && (
                  <span className="text-sm text-red-400">{errors.priceMax}</span>
                )}
              </div>
            </div>


             {/* Credit Score Range Dropdown */}
          <div className="flex flex-col gap-2">
            <label className="text-sm text-paragraphColor" htmlFor="creditScoreRange">
              Credit Score Range <span style={{ color: "red" }}>*</span>
            </label>
            <select 
              className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" 
              id="creditScoreRange" 
              value={creditScoreRangeValue} 
              onChange={(e) => setCreditScoreRangeValue(e.target.value)}
            >
              <option value="">Select Credit Score Range</option>
              {creditScoreRange.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {errors.creditScoreRange && (
              <span className="text-sm text-red-400">{errors.creditScoreRange}</span>
            )}
          </div>

    {/* When to Buy Plan Dropdown */}
    <div className="flex flex-col gap-2">
      <label className="text-sm text-paragraphColor" htmlFor="whenToBuyPlan">
        When to Buy Plan <span style={{ color: "red" }}>*</span>
      </label>
      <select 
        className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" 
        id="whenToBuyPlan" 
        value={whenToBuyPlanValue} 
        onChange={(e) => setWhenToBuyPlanValue(e.target.value)}
      >
        <option value="">Select When to Buy Plan</option>
        {whenToBuyPlan.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errors.whenToBuyPlan && (
        <span className="text-sm text-red-400">{errors.whenToBuyPlan}</span>
      )}
    </div>

    <div className="flex flex-col gap-2">
      <label className="text-sm text-paragraphColor" htmlFor="downPayment">Down Payment <span style={{ color: "red" }}>*</span></label>
      <input 
        type="text"
        className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" 
        id="downPayment" 
        placeholder="Enter down payment amount"
        value={downPayment}
        onChange={(e) => setDownPayment(e.target.value)}
        onInput={(e) => {
          // Restrict input to numbers and decimals
          e.target.value = e.target.value.replace(/[^0-9.]/g, "");
        }}
      />
      {errors.downPayment && (
        <span className="text-sm text-red-400">{errors.downPayment}</span>
      )}
    </div>


      {/* Message Textarea */}
            <div className="flex flex-col gap-2">
            <label className="text-sm text-paragraphColor" htmlFor="message">Message</label>
            <textarea 
              id="message" 
              className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" 
              placeholder="Enter your message here..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {errors.message && (
              <span className="text-sm text-red-400">{errors.message}</span>
            )}
          </div>

            <div className="flex  gap-8 items-center pt-6">
            <div className="flex-1"><button className="w-full border-gray-300 2xl:py-4 xl:py-4 lg:py-3.5 md:py-3 py-3 rounded-lg font-semibold text-gray-600  border">Cancel</button></div>
                 <div className="flex-1"><button type="submit" className="w-full text-sm 2xl:text-base lg:text-base xl:text-base md:text-sm 2xl:py-4 xl:py-4 lg:py-3.5 md:py-3 py-3 rounded-lg font-semibold text-paragraphColor bg-white  border" onClick={handleSubmit}>Update Preferences</button></div>
            </div>
        </form>
      </div>
        </div>
      </div>
    </div>
  );
};

export default GroupBuyerPreference;
