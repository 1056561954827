import React, { useState, useEffect } from 'react';
import { Grid, GridItem, Input, Button, Flex, Select, useToast, Badge, FormLabel, FormControl } from '@chakra-ui/react'; // Import Chakra UI components

import axiosInstance from '../../utils/axio-instance.js';
import {states} from '../../data/us_states.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next'; 
import { useAuth } from './AuthProvider.js';
import { FaInfoCircle } from "react-icons/fa";
import InfoBar from '../../utils/InfoBar.js';


const AgentLicenseInfo = ({profileInfo}) => { 
  const userId = localStorage.getItem('userid');
  const [licenseInfo, setLicenseInfo] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const { t} = useTranslation();
  const {globalMsg, setGlobalMsg} = useAuth();
  const localtion = useLocation();

  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [zipCode, setZipCode] = useState("");

  // useEffect to set the state when profileInfo changes
  useEffect(() => {
    if(localtion.pathname.indexOf("agent-licence-info")>0){
      getLicenseInfo();
    }
  }, [localtion.pathname]);

  const isValidObject = ()=>{
      let error = {}, ecount = 0;
      if(!licenseInfo.brokerEmail ||  !validateEmail(licenseInfo.brokerEmail)){
        error.brokerEmail = 'Broker Email is required';
        ecount++;
      }
      if(!licenseInfo.brokerName || (licenseInfo.brokerName) === ''){
        error.brokerName = "Broker Name is required"; ecount++;
      }
      if(!licenseInfo.brokerPhone ||  (licenseInfo.brokerPhone) ===''){
        error.brokerPhone = "Broker Phone is required"; ecount++;
      }
      if(!licenseInfo.brokerAddress ||  (licenseInfo.brokerAddress) === ''){
        error.brokerAddress = "Broker Address is required"; ecount++;
      }

      if(!licenseInfo.state){
        error.State = "State is mandatory"; ecount++;
      }
      if(!licenseInfo.licenseNo){
        error.licenseNo = "License No. is mandatory"; ecount++;
      }
      if(!licenseInfo.jobTitle){
        error.jobTitle = "Job Title is mandatory"; ecount++;
      }

      if(!licenseInfo.closedDeals){
        error.closedDeals = "Closed Deals is mandatory"; ecount++;
      }

      if(!licenseInfo.yearOfExperience){
        error.yearOfExperience = "Experience is mandatory"; ecount++;
      }

      if(!licenseInfo.websiteUrl){
        error.websiteUrl = "Website Url is mandatory"; ecount++;
      }

      if(!licenseInfo.narNumber){
        error.narNumber = "Realtor NAR Number  is mandatory"; ecount++;
      }
      if(!licenseInfo.address){
        error.address = ("Mailing Address is mandatory"); ecount++;
      }
      if(ecount>0){
        setErrors(error);
        toast({
          title: 'Error',
          description: "Invalid values, Fill all mandatory fields and try again!",
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        throw new Error("invalid values");
      }
  }
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=initAutocomplete&libraries=places&v=weekly`;
    script.defer = true;
    document.head.appendChild(script);
  
    const initializeAutocomplete = (inputId, stateUpdater, fieldName) => {
      const autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById(inputId),
        {
          types: ["geocode"], // For general address suggestions
          componentRestrictions: { country: "us" },
        }
      );
  
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const address = place.formatted_address || "";

        const addressComponents = place.address_components || [];

        const zipCodeComponent = addressComponents.find((component) =>
          component.types.includes("postal_code")
        );
  
        if (fieldName === "address") {
          const lat = place.geometry?.location?.lat() || "";
          const lng = place.geometry?.location?.lng() || "";
          const zip = zipCodeComponent?.long_name || "";
  
          // Update latitude and longitude for mailing address
          stateUpdater((prev) => ({
            ...prev,
            [fieldName]: address,
            latitude: lat,
            longitude: lng,
            zipcode:zip
          }));
        } else {
          // Update only the address field for other cases
          stateUpdater((prev) => ({
            ...prev,
            [fieldName]: address,
          }));
        }
      });
    };
  
    // Initialize autocomplete for both address fields
    window.initAutocomplete = () => {
      initializeAutocomplete("brokerAddress", setLicenseInfo, "brokerAddress");
      initializeAutocomplete("mailingAddress", setLicenseInfo, "address");
    };
  
    return () => {
      // Cleanup script to prevent duplicates
      document.head.removeChild(script);
    };
  }, [GOOGLE_MAPS_API_KEY]);
  

  const getLicenseInfo = ()=>{
    // Send updated profile information to the server using Axios
    axiosInstance.get(`/users/${userId}/agent-license/`)
    .then(response => {
      setLicenseInfo({...response.data});
      })
    .catch(error => {
      console.error('Error in loading getLicenseInfo:', error);
    });
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setErrors({});
     try{
      isValidObject();
      const kcuserid = localStorage.getItem("kc_uid");
      const licenseinfoFinal = {...licenseInfo, kc_uid: kcuserid}
    // Send updated profile information to the server using Axios
    axiosInstance.put(`/users/${userId}/agent-license/`, licenseinfoFinal)
    .then(response => {
      setSuccessMessage(t('saved'));
      setGlobalMsg('');
        toast({
          title: t('licenceinfo-updatemessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      })
    .catch(error => {
      console.error('Error updating profile:', error);
      let errorMessage = t('licenceinfo-failedmessage');
      if (error.response && error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
      }
      setErrorMessage(t('failed'));
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    });
    }catch(e){
      console.log(e);
    }
  };
  const handleKeyEvent = (e)=>{
    //console.log(e.target.value, e.target.name);
    setLicenseInfo({...licenseInfo, [e.target.name]: e.target.value});
    e.preventDefault();
  }
  const setState= (e)=>{
    setLicenseInfo({...licenseInfo, state: e.target.value});
  }

  return (
    <div className="bg-primary font-linksans">
  <div className="container px-5 min-h-svh">
   {globalMsg && (
        <InfoBar message={t(globalMsg)} />
        )}
  <div className="flex-col items-center flex justify-center py-12">
  <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-textColor">
        Agent Preferences & Information
        </h3>
        </div>
    <div className="top-sec w-full  flex justify-center pb-10">
      <form onSubmit={handleSubmit} className="w-[600px] max-w-xl flex flex-col">
        {/* Responsive grid layout */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
          {/* Left Column */}
          <div className="w-full">
          <div className="mb-4">
              <label className="block font-medium">
                Job Title <span className="text-red-500">*</span>
              </label>
              <input
                name="jobTitle"
                type="text"
                value={licenseInfo.jobTitle}
                onChange={handleKeyEvent}
                placeholder="Job Title"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
              {errors.jobTitle && (
                <p className="text-red-500 text-sm mt-1">{errors.jobTitle}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                Licensed State <span className="text-red-500">*</span>
              </label>
              <select
                name="state"
                value={licenseInfo.state}
                onChange={setState}
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              >
                <option value="">Select State</option>
                {states.map((st) => (
                  <option key={st.abbreviation} value={st.abbreviation}>
                    {st.name}
                  </option>
                ))}
              </select>
              {errors.State && (
                <p className="text-red-500 text-sm mt-1">{errors.State}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                State License Number <span className="text-red-500">*</span>
              </label>
              <input
                name="licenseNo"
                type="number"
                value={licenseInfo.licenseNo}
                onChange={handleKeyEvent}
                placeholder="License Number"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
               {errors.licenseNo && (
                <p className="text-red-500 text-sm mt-1">{errors.licenseNo}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                Website URL <span className="text-red-500">*</span>
              </label>
              <input
                name="websiteUrl"
                type="text"
                value={licenseInfo.websiteUrl}
                onChange={handleKeyEvent}
                placeholder="Website Url"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
               {errors.websiteUrl && (
                <p className="text-red-500 text-sm mt-1">{errors.websiteUrl}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                Realtor NAR Number <span className="text-red-500">*</span>
              </label>
              <input
                name="narNumber"
                type="number"
                value={licenseInfo.narNumber}
                onChange={handleKeyEvent}
                placeholder="Realtor NAR Number"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
              {errors.narNumber && (
                <p className="text-red-500 text-sm mt-1">{errors.narNumber}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                Mailing Address <span className="text-red-500">*</span>
              </label>
              <input
                name="address"
                 id="mailingAddress"
                type="text"
                value={licenseInfo.address}
                onChange={handleKeyEvent}
                placeholder="Mailing Address"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
              {errors.address && (
                <p className="text-red-500 text-sm mt-1">{errors.address}</p>
              )}
            </div>
            <input type="hidden" name="latitude" value={licenseInfo.latitude}/>
            <input type="hidden" name="longitude" value={licenseInfo.longitude}/>
            <input type="hidden" name="zipcode" value={licenseInfo.zipcode}/>
          </div>
          
          {/* Right Column */}
          <div className="w-full">
          <div className="mb-4">
              <label className="block font-medium">
                Year of Experience <span className="text-red-500">*</span>
              </label>
              <input
                name="yearOfExperience"
                type="number"
                value={licenseInfo.yearOfExperience}
                onChange={handleKeyEvent}
                placeholder="Year Of Experience"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
              {errors.yearOfExperience && (
                <p className="text-red-500 text-sm mt-1">{errors.yearOfExperience}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                Closed Deals <span className="text-red-500">*</span>
              </label>
              <input
                name="closedDeals"
                type="number"
                value={licenseInfo.closedDeals}
                onChange={handleKeyEvent}
                placeholder="Closed Deals"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
              {errors.closedDeals && (
                <p className="text-red-500 text-sm mt-1">{errors.closedDeals}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                Broker Name <span className="text-red-500">*</span>
              </label>
              <input
                name="brokerName"
                type="text"
                value={licenseInfo.brokerName}
                onChange={handleKeyEvent}
                placeholder="Broker Name"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
               {errors.brokerName && (
                <p className="text-red-500 text-sm mt-1">{errors.brokerName}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                Broker Email <span className="text-red-500">*</span>
              </label>
              <input
                name="brokerEmail"
                type="email"
                value={licenseInfo.brokerEmail}
                onChange={handleKeyEvent}
                placeholder="Broker Email"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
                {errors.brokerEmail && (
                <p className="text-red-500 text-sm mt-1">{errors.brokerEmail}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                Broker Phone <span className="text-red-500">*</span>
              </label>
              <input
                name="brokerPhone"
                type="tel"
                value={licenseInfo.brokerPhone}
                onChange={handleKeyEvent}
                placeholder="Broker Phone"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
               {errors.brokerPhone && (
                <p className="text-red-500 text-sm mt-1">{errors.brokerPhone}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                Broker Office Address <span className="text-red-500">*</span>
              </label>
              <input
                name="brokerAddress"
                id="brokerAddress"
                type="text"
                value={licenseInfo.brokerAddress}
                onChange={handleKeyEvent}
                placeholder="Broker Office Address"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
              {errors.brokerAddress && (
                <p className="text-red-500 text-sm mt-1">{errors.brokerAddress}</p>
              )}
            </div>
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-center mt-4">
          <button
            type="submit"
            className="w-full max-w-xs text-sm py-3 rounded-lg font-semibold text-gray-700 bg-white border hover:bg-gray-100 focus:ring focus:ring-blue-500"
            onClick={handleSubmit}
          >
            Update Agent License Info
          </button>
        </div>
      </form>
    </div>
  </div>
   </div>
  );
};

export default AgentLicenseInfo;
