

function TestimonialInfo() {
  return (
    <div className="w-full h-fit">

        <div className="p-14 rounded-l-lg bg-primary flex flex-col ">
            <h3 className="2xl:pb-8 pb-4 xl:pb-6 lg:pb-6 md:pb-6 text-textColor font-semibold 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl">New Home Buyers</h3>
            <div className="flex items-center pb-2 gap-6 ">
                <p className="text-paragraphColor text-justify text-center w-full">No matter where you are in your real estate journey, Knock The Door can help you reach your real estate purchase goals when it comes to purchasing new construction homes. </p>
            </div>
        </div>
        
    </div>
  )
}

export default TestimonialInfo