import React, { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react'; // Chakra UI components
import axiosInstance,{axiosInstanceES} from '../../utils/axio-instance';
import { propertyTypeOptions, creditScoreRange, whenToBuyPlan, countryCodes} from '../../constants';
import { useTranslation } from 'react-i18next'; 
import {states} from '../../data/us_states.js';
import { useNavigate } from 'react-router-dom';
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";



const AgentGetStarted = () => {
  const [message, setMessage] = useState("");
  const [jobTitle, setJobTitle] = useState('');
  const [complist, setCompList] = useState([]);
  const [company, setCompany] = useState("");
  const [licenseNo, setLicenseNo] = useState("");
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [phone, setPhone] =useState('');
  const[firstName, setFirstName]=useState('');
  const[lastName, setLastName]=useState('');
  const[role, setRole]=useState('selleragent');
  const [canWeSms, setCanWeSms] = useState('no');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t} = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const [state, setStateValue] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [yearOfExperience, setYearOfExperience] = useState('');
  const [closedDeals, setClosedDeals] = useState('');
  const [brokerName, setBrokerName] = useState('');
  const [brokerEmail, setBrokerEmail] = useState('');
  const [brokerPhone, setBrokerPhone] = useState('');
  const [brokerWebsite, setBrokerWebsite] = useState('');
  const [city, setCity] = useState("");
  const [location, setLocation] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  useEffect(()=>{
    axiosInstanceES.get(`/companies`)
      .then(response => {
        const frags = {states:new Set(), cities: new Set()};
        setCompList(response.data);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
  },[]);

  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
  
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=initAutocomplete&libraries=places&v=weekly`;
    script.defer = true;
    document.head.appendChild(script);

    window.initAutocomplete = () => {
      const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById("address"), {
        types: ["(cities)"], // Restrict to cities only
        componentRestrictions: { country: "us" },
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
  
        // Extract city and state
        const addressComponents = place.address_components || [];
        const cityComponent = addressComponents.find((component) =>
          component.types.includes('locality')
        );
        const stateComponent = addressComponents.find((component) =>
          component.types.includes('administrative_area_level_1')
        );
  
        setCity(cityComponent?.long_name || '');
        setLocation(stateComponent?.short_name || '');
  
        // Extract latitude and longitude
        const lat = place.geometry?.location?.lat();
        const lng = place.geometry?.location?.lng();
        setLatitude(lat || '');
        setLongitude(lng || '');
  
        // Update address field with formatted address
        setAddress(place.formatted_address || '');
      });
    };
  }, []);

  const handleSuggestionClick = (description) => {
    setAddress(description);
    setSuggestions([]);
  };

  
  const validateWebsite = (website) => {
    const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    return regex.test(website);
  };

  const validate = () => {
    let formErrors = {};
    if (!firstName) formErrors.firstName = 'FirstName is required';
    if (!lastName) formErrors.lastName = 'LastName is required';
    if (!email) {
      formErrors.email = 'Email is required';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        formErrors.email = 'Invalid email format';
      }
    }
  
    // Phone Number Validation
    if (!phone) {
      formErrors.phone = 'Phone Number is required';
    } else {
      const phoneDigits = phone.replace(/\D/g, ''); // Remove non-digit characters
      if (phoneDigits.length < 10 || phoneDigits.length > 14) {
        formErrors.phone = 'Invalid Phone Number';
      }
    }

    if (!jobTitle) formErrors.jobTitle = 'jobTitle is required';
    if (!address) formErrors.address = 'Address is required';
    if (!state) formErrors.state = 'State is required';
    if (!licenseNo) formErrors.licenseNo = 'License Number is required';
    if (!websiteUrl) formErrors.websiteUrl = 'websiteUrl is required';
    else if (!validateWebsite(websiteUrl)) {
      formErrors.websiteUrl = 'Please enter a valid website URL.';
    }
    if (!yearOfExperience) formErrors.yearOfExperience = 'yearOfExperience is required';
    if (!closedDeals) {
        formErrors.closedDeals = "ClosedDeals is required";
      }
      if (!brokerName) formErrors.brokerName = 'BrokerName is required';

      if (!brokerEmail) {
        formErrors.brokerEmail = 'Broker Email is required';
      } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(brokerEmail)) {
          formErrors.brokerEmail = 'Invalid email format';
        }
      }

      if (!brokerPhone) {
        formErrors.brokerPhone = 'BrokerPhone Number is required';
      } else {
        const phoneDigits = brokerPhone.replace(/\D/g, ''); // Remove non-digit characters
        if (phoneDigits.length < 10 || phoneDigits.length > 14) {
          formErrors.brokerPhone = 'Invalid Phone Number';
        }
      }

      if (!brokerWebsite) formErrors.brokerWebsite = 'BrokerWebsite is required';
      else if (!validateWebsite(brokerWebsite)) {
        formErrors.brokerWebsite = 'Please enter a valid website URL.';
      }

      setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage('');
    setErrorMessage('');

    if (!validate()) {
      setIsSubmitting(false);
      return;
    }

    const updatedPreferences = {
      role: "selleragent",
      preferences: {
        email: email,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        canWeSms : canWeSms,
        jobTitle: jobTitle,
        address: address,
        state: state,
        licenseNo: licenseNo,
        closedDeals: closedDeals,
        websiteUrl: websiteUrl,
        yearOfExperience: yearOfExperience,
        brokerName: brokerName,
        brokerPhone: brokerPhone,
        brokerEmail: brokerEmail,
        brokerWebsite: brokerWebsite,
        message: message,
        latitude: latitude,
        longitude: longitude,
      }
    };

    try {
      const response = await axiosInstance.post(`/users/online/preferences`, updatedPreferences);
      if (response.status === 200) {
        setSuccessMessage(t('saved'));
        toast({
          title: t('getstarted-successmessage'),
          status: 'success',
          duration: 10000,
          isClosable: true,
          position: 'top-right',
        });

        sessionStorage.setItem('userPreferences', JSON.stringify({
          firstName,
          lastName,
          email,
          role, 
        }));

        window.scrollTo({ top: 0, behavior: 'smooth' });

        setFirstName('');
        setLastName('');
        setEmail('');
        setCanWeSms('');
        setPhone('');
        setJobTitle('');
        setMessage('');
        setAddress('');
        setLicenseNo('');
        setStateValue('');
        setWebsiteUrl('');
        setYearOfExperience('');
        setClosedDeals('');
        setBrokerName('');
        setBrokerEmail('');
        setBrokerPhone('');
        setBrokerWebsite('');

        navigate('/sign-up');
      }
    } catch (error) {
      let errorMessage = 'Failed to update preferences. Please try again later.';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      setErrorMessage(t('failed'));
      toast({
        title: 'Error',
        description: t('getstarted-errormessage'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value.trim();
    setEmail(value);
  }

  const handlePhoneChange = (value) => {
    setPhone(value); // Value is the phone number string
  };

  const handleBrokerPhoneChange = (value) => {
    setBrokerPhone(value); // Value is the phone number string
  };

  const handleStateChange = (e) => {
    setStateValue(e.target.value); 
  };



  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timeout
    }
  }, [successMessage, errorMessage]);

  const formattedPhone = phone ? formatPhoneNumber(phone, "NATIONAL") : "";


  return (
    <div className="bg-primary  font-linksans pb-12">
      <div className="container px-5 py-2 min-h-svh">
        <div className="flex-col items-center flex justify-center mt-4">
        <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-textColor pb-10">
        Getting Started
        </h3>

      <div className="w-full flex justify-center">
      <form action="" className="w-[500px] max-w-xl flex flex-col gap-3">

      <div className="flex flex-wrap gap-4">
            {/* First Name */}
            <div className="flex flex-col gap-2 w-full md:flex-1">
                <label className="text-sm text-paragraphColor" htmlFor="first-name">
                First Name <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                type="text"
                placeholder="Enter First Name"
                className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white w-full"
                id="first-name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                />
                {errors.firstName && (
                <span className="text-sm text-red-400">{errors.firstName}</span>
                )}
            </div>

            {/* Last Name */}
            <div className="flex flex-col gap-2 w-full md:flex-1">
                <label className="text-sm text-paragraphColor" htmlFor="last-name">
                Last Name <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                type="text"
                placeholder="Enter Last Name"
                className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white w-full"
                id="last-name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                />
                {errors.lastName && (
                <span className="text-sm text-red-400">{errors.lastName}</span>
                )}
            </div>
            </div>

           
        <div className="flex flex-col gap-2 w-full md:flex-1">
            <label className="text-sm text-paragraphColor" htmlFor="email">
            Email <span style={{ color: "red" }}>*</span>
            </label>
            <input
            type="email"
            id="email"
            placeholder="Enter Email"
            className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white "
            value={email}
            onChange={handleEmailChange}
            />
            {errors.email && <span className="text-sm text-red-400">{errors.email}</span>}
          </div>

       <div className="flex flex-wrap gap-4">
          {/* Phone Section */}
          <div className="flex flex-col gap-2 w-full md:w-[48%]">
            <label className="text-sm text-paragraphColor" htmlFor="phone">
              Phone <span style={{ color: "red" }}>*</span>
            </label>
            <div className="flex gap-2">
              {/* Phone Number Field */}
              <PhoneInput
                id="phone"
                placeholder="Enter Phone Number"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                value={phone}
                onChange={handlePhoneChange}
                defaultCountry="US" 
                international
                withCountryCallingCode={false} 
              />
            </div>
            {errors.phone && <span className="text-sm text-red-400">{errors.phone}</span>}
          </div>

          {/* Can We Send SMS Section */}
          <div className="flex flex-col gap-2 w-full md:w-[48%]">
            <div className="flex items-center gap-6 md:mt-10 md:ml-2">
              <label className="text-sm text-paragraphColor" htmlFor="can-we-sms">
                Can We Send SMS?
              </label>
              <label className="inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" checked={canWeSms === 'yes'} onChange={(e) => setCanWeSms(e.target.checked ? 'yes' : 'no')}/>
                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-4">
          <div className="flex flex-col gap-2  w-full md:flex-1">
          <label className="text-sm text-paragraphColor">
              Job Title <span className="text-red-500">*</span>
            </label>
            <input
              className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
              name="jobTitle"
              placeholder="Enter Job Title"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
            {errors.jobTitle && (
              <span className="text-sm text-red-400">{errors.jobTitle}</span>
            )}
          </div>

          <div className="flex flex-col gap-2  w-full md:flex-1">
                <label  className="text-sm text-paragraphColor" htmlFor="address">Mailing Address <span style={{ color: "red" }}>*</span></label>
                <input
                  id="address"
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white"
                  placeholder="Enter your address"
                />
                {loading && <p>Loading suggestions...</p>}
                {suggestions.length > 0 && (
                  <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        className="suggestion-item"
                        onClick={() => handleSuggestionClick(suggestion.description)}
                      >
                        {suggestion.description}
                      </li>
                    ))}
                  </ul>
                )}
              
                {errors.address && (
                    <span className="text-sm text-red-400">{errors.address}</span>
                  )}
              </div>
        </div>

          <div className="flex flex-wrap gap-4">
            <div className="flex flex-col gap-2  w-full md:flex-1">
                  <label className="text-sm text-paragraphColor">
                    Licensed State <span className="text-red-500">*</span>
                  </label>
                  <select
                    name="state"
                    value={state}
                    onChange={handleStateChange}
                    className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  >
                    <option value="">Select State</option>
                    {states.map((st) => (
                      <option key={st.abbreviation} value={st.abbreviation}>
                        {st.name}
                      </option>
                    ))}
                  </select>
                  {errors.state && (
                    <p className="text-red-400 text-sm mt-1">{errors.state}</p>
                  )}
              </div>
              <div className="flex flex-col gap-2  w-full md:flex-1">
                <label className="text-sm text-paragraphColor">
                  State License Number <span className="text-red-500">*</span>
                </label>
                <input
                  name="licenseNo"
                  type="number"
                  value={licenseNo}
                  onChange={(e) => setLicenseNo(e.target.value)}
                  placeholder="License Number"
                  className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
                {errors.licenseNo && (
                  <p className="text-red-400 text-sm mt-1">{errors.licenseNo}</p>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <label className="text-sm text-paragraphColor">
                Website URL <span className="text-red-500">*</span>
              </label>
              <input
                name="websiteUrl"
                type="text"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
                placeholder="Website Url"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
               {errors.websiteUrl && (
                <p className="text-red-400 text-sm mt-1">{errors.websiteUrl}</p>
              )}
            </div>

            <div className="flex flex-wrap gap-4">
            <div className="flex flex-col gap-2 w-full md:flex-1">
              <label className="text-sm text-paragraphColor">
                Year of Experience <span className="text-red-500">*</span>
              </label>
              <input
                name="yearOfExperience"
                type="number"
                value={yearOfExperience}
                onChange={(e) => setYearOfExperience(e.target.value)}
                placeholder="Year Of Experience"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
              {errors.yearOfExperience && (
                <p className="text-red-400 text-sm mt-1">{errors.yearOfExperience}</p>
              )}
            </div>
            <div className="flex flex-col gap-2 w-full md:flex-1">
              <label className="text-sm text-paragraphColor">
                Closed Deals <span className="text-red-500">*</span>
              </label>
              <input
                name="closedDeals"
                type="number"
                value={closedDeals}
                onChange={(e) => setClosedDeals(e.target.value)}
                placeholder="Closed Deals"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
              {errors.closedDeals && (
                <p className="text-red-400 text-sm mt-1">{errors.closedDeals}</p>
              )}
            </div>
            </div>

            <div className="flex flex-wrap gap-4">
            <div className="flex flex-col gap-2 w-full md:flex-1">
              <label className="text-sm text-paragraphColor">
                Broker Name <span className="text-red-500">*</span>
              </label>
              <input
                name="brokerName"
                type="text"
                value={brokerName}
                onChange={(e) => setBrokerName(e.target.value)}
                placeholder="Broker Name"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
               {errors.brokerName && (
                <p className="text-red-400 text-sm mt-1">{errors.brokerName}</p>
              )}
            </div>
            <div className="flex flex-col gap-2 w-full md:flex-1">
              <label className="text-sm text-paragraphColor">
                Broker Email <span className="text-red-500">*</span>
              </label>
              <input
                name="brokerEmail"
                type="email"
                value={brokerEmail}
                onChange={(e) => setBrokerEmail(e.target.value)}
                placeholder="Broker Email"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
                {errors.brokerEmail && (
                <p className="text-red-400 text-sm mt-1">{errors.brokerEmail}</p>
              )}
            </div>
            </div>
            <div className="flex flex-wrap gap-4">
            <div className="flex flex-col gap-2 w-full md:w-[48%]">
            <label className="text-sm text-paragraphColor" htmlFor="brokerPhone">
             Broker Phone <span style={{ color: "red" }}>*</span>
            </label>
            <div className="flex gap-2">
              {/* Broker Phone Number Field */}
              <PhoneInput
                id="brokerPhone"
                placeholder="Enter Broker Phone Number"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                value={brokerPhone}
                onChange={handleBrokerPhoneChange}
                defaultCountry="US" 
                international
                withCountryCallingCode={false} 
              />
            </div>
            {errors.brokerPhone && <span className="text-sm text-red-400">{errors.brokerPhone}</span>}
          </div>

           
            <div className="flex flex-col gap-2 w-full md:flex-1">
              <label className="text-sm text-paragraphColor">
                Broker Website <span className="text-red-500">*</span>
              </label>
              <input
                name="brokerWebsite"
                type="tel"
                value={brokerWebsite}
                onChange={(e) => setBrokerWebsite(e.target.value)}
                placeholder="Broker Website"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
               {errors.brokerWebsite && (
                <p className="text-red-400 text-sm mt-1">{errors.brokerWebsite}</p>
              )}
            </div>
            </div>

      {/* Message Textarea */}
            <div className="flex flex-col gap-2">
            <label className="text-sm text-paragraphColor" htmlFor="message">Message</label>
            <textarea 
              id="message" 
              className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" 
              placeholder="Enter your message here..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {errors.message && (
              <span className="text-sm text-red-400">{errors.message}</span>
            )}
          </div>

            <div className="flex  gap-8 items-center pt-6">
            <div className="flex-1"><button className="w-full border-gray-300 2xl:py-4 xl:py-4 lg:py-3.5 md:py-3 py-3 rounded-lg font-semibold text-gray-600  border">Cancel</button></div>
                 <div className="flex-1"><button type="submit" className="w-full text-sm 2xl:text-base lg:text-base xl:text-base md:text-sm 2xl:py-4 xl:py-4 lg:py-3.5 md:py-3 py-3 rounded-lg font-semibold text-paragraphColor bg-white  border" onClick={handleSubmit}>Save</button></div>
            </div>
        </form>
      </div>
        </div>
      </div>
    </div>
  );
};

export default AgentGetStarted;