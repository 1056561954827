import PropTypes from 'prop-types';
import { FiMail, FiCheck, FiX } from 'react-icons/fi';


function CommonStatusButton({ color, text }) {
    // Default colors
    let textColor = 'text-gray-500'; // Fallback color
    let bgColor = 'bg-gray-200';      // Fallback background color
    let iconColor = 'bg-gray-500';    // Fallback icon color

    // Define colors based on the `color` prop
    switch (color) {
        case 'green':
            bgColor = 'bg-[#BBF7D0]';
            textColor = 'text-[#16A34A]';
            iconColor = 'text-[#16A34A]';
            break;
        case 'red':
            bgColor = 'bg-[#CD4D4F33]';
            textColor = 'text-[#EA5353]';
            iconColor = 'text-[#EA5353]';
            break;
        case 'yellow':
            bgColor = 'bg-[#FFAB0033]';
            textColor = 'text-[#FFAB00]';
            iconColor = 'text-[#FFAB00]';
            break;
      
            default:
            // You can add default case handling if needed
            break;
    }

    let Icon;
    switch (text) {
        case 'Invited':
            Icon = FiMail; // Mail icon
            break;
        case 'Accepted':
            Icon = FiCheck; // Check icon
            break;
        case 'Pending':
            Icon = FiX; // Cross icon
            break;
        default:
            Icon = null; // No icon if not matched
            break;
    }

    return (
        <div className={`flex items-center justify-center w-fit gap-2 px-3 py-1 ${bgColor}`}>
        {Icon && <Icon className={`w-4 h-4 ${iconColor}`} />} 
        <span className={`font-medium text-sm ${textColor}`}>{text}</span>
      </div>
    );
}

// Define prop types
CommonStatusButton.propTypes = {
    color: PropTypes.oneOf(['green', 'red', 'yellow']).isRequired,
    text: PropTypes.string.isRequired,
};

export default CommonStatusButton;
