import { useNavigate } from "react-router-dom";
import { useProducts } from "../compare/ProductsProvider";
import React, { useState, useEffect} from 'react';
//import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';
import GroupMenu from '../user/GroupMenu';
import CommonButton from "../../components/compare/CommonButton";
import PriceDisplay from "../../pages/DetailsPage/PriceDisplay";
import {Box, Button, Text, Flex, Stack, useDisclosure} from "@chakra-ui/react";
import ModalComponent from '../ModalComponent';
import PhotoModal from '../user/PhotoModal';
import ShareGroupNames from '../user/ShareGroupNames';
import { propTypeLabels} from '../../constants';
import {formatAddress, formatPrice} from '../../utils/format.js'

const handleMarkerClick = (props) => {  
    if (props.hit.data.propertyInfo && props.hit.data.propertyInfo.address.label) {
      window.open(`https://www.google.com/maps/place/${encodeURIComponent(props.hit.data.propertyInfo.address.label)}`, '_blank');
    }
  };

const PropertyCard = (props) => {
    const { data, main = {}, community = {}, extraData={}} = props.hit;
    const {communityName} = community;
    const {
      propertyInfo = {},
     // lotInfo = {},
      mlsListingPrice = 'N/A',
      propertyType = 'N/A',
    } = data;
  
    const {
      address = {},
      bedrooms = 'N/A',
      bathrooms = 'N/A',
      buildingSquareFeet = 'N/A',
    } = propertyInfo;
    
    //const { lotSquareFeet = 'N/A' } = lotInfo;
    const publicId = main.publicId || 'N/A'; 
    const communityId = main.communityPublicID;
    const city = propertyInfo.address.city;
    const state = propertyInfo.address.state;
    
    //const [isPhotoOpen, setIsPhotoOpen] = useState(false);
    
    const { selectedProducts,addProductToSelection, removeProductFromSelection, lovedProducts,viewProducts,addViewProductToSelection} = useProducts();
    const isProductSelected = selectedProducts  && selectedProducts.includes(publicId);
  
    //const isLoveitSelected = lovedProducts &&  lovedProducts.includes(publicId);
    const isviewSelected = viewProducts &&  viewProducts.includes(publicId);
  
    
    const navigate = useNavigate();
  
    const { isOpen, onOpen, onClose,onToggle } = useDisclosure();
//    const { ismenu=isOpen, menOpen = onOpen,closeMenu= onClose } = useDisclosure();
    const [modalHeader, setModalHeader] = useState('');
    const [modalBody, setModalBody] = useState(null);
    //const [menuOpen, setMenuOpen] = useState(false);
    const [userId, setUserId] = useState(null);
    // const fetchImages = async (propertyId) => {
    //   try {
    //     const response = await axiosInstanceES.get(`/properties/${propertyId}/photos`);
    //     //setImages(response.data);
    //     onOpen();
    //   } catch (error) {
    //     console.error('Error fetching images:', error);
    //   }
    // };
  
    useEffect(() => {
      // Check if user ID exists in local storage
      const storedUserId = localStorage.getItem('userid');
      if (storedUserId) {
        setUserId(storedUserId);
      }
    }, []);
  
    let storedLanguage = localStorage.getItem('i18nextLng');
  
    if (!storedLanguage || storedLanguage.trim() === '') {
        storedLanguage = 'en';
    }
  
     const handleCompare = productId => {
      if (isProductSelected) {
        removeProductFromSelection(productId);
      } else {
        addProductToSelection(productId);
      }
     }
  
     const handleViewList = productId =>{
       if(isviewSelected){
        addViewProductToSelection(productId);
       } else{
        addViewProductToSelection(productId);
        }
        let url;
        if (productId && productId !== 'N/A') {
          url = `/home/${storedLanguage}/${state}/${city}/${productId}/${props.hit.community?.communityName ? encodeURIComponent(props.hit.community.communityName) : ''}/${props.hit.data.propertyType ? encodeURIComponent(props.hit.data.propertyType) : ''}/`;
        } else {
           url =`/community/${storedLanguage}/${state}/${city}/${communityId}`;
        }
        window.open(url, '_blank');
     }
  
    const handleCloseModal = () => {
      setModalHeader('');
      setModalBody(null);
      onClose();
    };
  
    const handleOpenPhotoModal = () => {
      setModalHeader('Property Images');
      if (publicId && publicId !== 'N/A') {
      setModalBody(<PhotoModal isOpen={true} onClose={onClose} propertyId={publicId}/>);
      } else {
        setModalBody(<PhotoModal isOpen={true} onClose={onClose} propertyId={communityId}/>);  
      }
      onOpen();
    };
    
return (
    <>
  <Flex justifyContent="center" alignItems="flex-start" position="relative" width="100%">
    <Stack direction="column" spacing={5} width="100%" maxW="600px"> 
      <Box pt={4} width="100%">
        <Box className="2xl:col-span-1 xl:col-span-1 2xl:order-1 xl:order-1 lg:order-1 order-2 lg:col-span-1 md:col-span-2 col-span-2">
          {/* Price and details */}
          <Box className="flex items-center gap-2 2xl:pb-3 xl:pb-3 lg:pb-3 md:pb-3 pb-2">
          {mlsListingPrice && mlsListingPrice !== 'N/A' ? (
           <>
            <Text className="2xl:text-3xl xl:text-3xl lg:text-2xl: md:text-2xl text-2xl font-bold text-paragraphColor">
            <PriceDisplay price={mlsListingPrice} />
            </Text>
            {extraData.modelName && <Text fontWeight="medium" fontSize="14px" padding={'10px 0px'}>
                {extraData.modelName}</Text>}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0C3.5813 0 0 3.5813 0 8C0 12.4187 3.5813 16 8 16C12.4187 16 16 12.4187 16 8C16 3.5813 12.4187 0 8 0ZM8 14.5455C4.3908 14.5455 1.4546 11.6092 1.4546 8C1.4546 4.3908 4.3908 1.4545 8 1.4545C11.6092 1.4545 14.5454 4.3909 14.5454 8C14.5454 11.6091 11.6092 14.5455 8 14.5455ZM7.102 7.3171H8.898V12.859H7.102V7.3171ZM7.102 3.7906H8.898V5.5866H7.102V3.7906Z"
                fill="#C1C4CC"
              />
            </svg> </> )
             : communityName && <Text fontWeight="medium" fontSize="14px" padding={'10px 0px'}>
                    {communityName}
                  </Text>
                  }
          </Box>
          {propertyType && propertyType !== 'N/A' &&
          <Text className="text-paragraphColor 2xl:text-base xl:text-base lg:text-base md:text-base text-sm pb-3 border-b border-dashed border-borderColor">
            {propTypeLabels[propertyType]}
          </Text>
           }
  
         {address.label && address.label !== 'N/A' &&
          <Text className="text-paragraphColor 2xl:text-base xl:text-base lg:text-base md:text-base text-sm pb-3 border-b border-dashed border-borderColor">
            {formatAddress(address.label)}
          </Text>
           }
  
          {/* Property details */}
          {publicId && publicId !== 'N/A' &&
          <Box className='py-3 flex items-center flex-wrap gap-1.5 border-b border-dashed'>
          {bedrooms && bedrooms !== 'N/A' &&
            <CommonButton icon="bed" value={bedrooms} name="Bedrooms" isLink={false} color="green" />
          }
           {bathrooms && bathrooms !== 'N/A' &&
            <CommonButton icon="bath" value={bathrooms} name="Bathrooms" isLink={false} color="blue" />
           }
            {buildingSquareFeet && buildingSquareFeet !== 'N/A' && 
           <CommonButton icon="length" value={buildingSquareFeet} name="Sq Ft" isLink={false} color="purple" />
            }
  
           </Box>
           }
  
          { publicId && publicId !== 'N/A' && communityName && communityName !== 'N/A' && 
          <Box className='py-3 border-b border-dashed'>
            <CommonButton icon="property" value={null} name={communityName} isLink={false} color="pink" />
          </Box>
           }
  
          {(userId && publicId && publicId != "N/A" ) && 
          <Box className='py-3 border-b border-dashed'>
          <ShareGroupNames propertyId={publicId}/>
          </Box>
          } 
  
        </Box>
        </Box>
        <Box pt={0} position="relative" width="100%">
          {/* Action buttons */}
          {publicId && publicId !== 'N/A' &&
          <Box className="flex flex-wrap  gap-1.5 py-3 border-b border-dashed">
          {userId && publicId && publicId !== 'N/A' ? (
            <>
              <GroupMenu propertyId={publicId} />
            </>
          ) : (
            publicId && publicId !== 'N/A' && (
              <CommonButton
                icon="compare"
                name={isProductSelected ? "Remove" : "Shortlist"}
                color={isProductSelected ? "red" : "blue"}
                disablePointer={true}
                isLink={false} // if it's a clickable button and not a link
                onClick={() => handleCompare(props.hit.main.publicId)}
              />
            )
          )}
  
           <CommonButton icon="photo" isLink={false} name="Photos" onClick={handleOpenPhotoModal} color="gray" disablePointer={true}/>
           <CommonButton icon="map" isLink={false} name="View Map" disablePointer={true} onClick={() => handleMarkerClick(props)} color="gray"  />
      
           {isOpen && (
              <ModalComponent isOpen={isOpen} onClose={handleCloseModal} modalHeader={modalHeader} modalBody={modalBody} />
            )} 
           </Box>
          }
              
  
          {/* View Details Button */}
          <Box mt={4}>
              <Button onClick={() => handleViewList(publicId)} className="text-textColor text-sm md:text-base lg:text-base xl:text-base 2xl:text-base  font-semibold 2xl:py-4 xl:py-4 lg:py-4 md:py-3 py-2.5 bg-primary rounded-xl w-full">
                View Details
              </Button>
          </Box>
          </Box>
  
    </Stack>
  </Flex>  
  </>)
  }
  export default  PropertyCard;