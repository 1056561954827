import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Spinner,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  useDisclosure,
  useToast,
  Badge
} from '@chakra-ui/react';
import { FaExchangeAlt } from 'react-icons/fa';
import axiosInstance from '../../utils/axio-instance';
import Popoverlogin from '../../components/Popover';
import ModalComponent from '../ModalComponent';
import ShareWithFriendsModal from '../user/ShareWithFriendsModal';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useProducts } from '../compare/ProductsProvider';
import { useTranslation } from 'react-i18next'; 

const GroupMenu = ({ propertyId }) => { 
  const { groups, addPropertyToGroup, removePropertyFromGroup } =useProducts();

 // const [groups, setGroups] = useState([]);
 const [checkedGroupsByProperty, setCheckedGroupsByProperty] = useState({});
 const { t} = useTranslation();

  const [loadingGroups, setLoadingGroups] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalBody, setModalBody] = useState(null);
  const [lastCheckedGroup, setLastCheckedGroup] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fetchedCheckedGroups, setFetchedCheckedGroups] = useState(false);
  const toast = useToast();
  //const {productGroups } = useProducts();

  const handleOpenMenu = async () => {
    if (!fetchedCheckedGroups[propertyId] && propertyId) {
      try {
        const url= `/usrgroup/property/${propertyId}`;
        const checkedResponse = await axiosInstance.get(url);
        const checkedGroupsData = checkedResponse.data;
        const checkedGroupsMap = checkedGroupsData.reduce((acc, group) => {
          acc[group.id] = true;
          return acc;
        }, {});
        setCheckedGroupsByProperty((prev) => ({
          ...prev,
          [propertyId]: checkedGroupsMap, // Store checked groups per property
        }));

        setFetchedCheckedGroups((prev) => ({
          ...prev,
          [propertyId]: true, // Mark the propertyId as fetched
        }));
      } catch (error) {
        console.error('Error fetching checked groups:', error);
      }
    }

   // onOpen(); // Open the menu after the data fetch starts
  };

  const handleCheckChange = async (groupId, e) => {
    setLoadingGroups((prev) => ({ ...prev, [groupId]: true }));

    let isChecked = e.target.checked;

    try {
      if (isChecked) {
        await addPropertyToGroup(groupId, propertyId);
        setCheckedGroupsByProperty((prev) => ({
          ...prev,
          [propertyId]: { ...prev[propertyId], [groupId]: true }, // Update the checked state for this property
        }));
        setSuccessMessage(t('saved'));
        toast({
          title: t('propertysavemessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        await removePropertyFromGroup(groupId, propertyId);
        setCheckedGroupsByProperty((prev) => ({
          ...prev,
          [propertyId]: { ...prev[propertyId], [groupId]: false }, // Update unchecked state for this property
        }));
        setSuccessMessage(t('removed'));
        toast({
          title: t('propertyremovedmessage'),
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      }
      setErrorMessage('');
    } catch (error) {
      setCheckedGroupsByProperty((prev) => ({
        ...prev,
        [propertyId]: { ...prev[propertyId], [groupId]: !isChecked }, // Revert the state on failure
      }));
      setSuccessMessage('');
      setErrorMessage(t('failed'));
    } finally {
      setLoadingGroups((prev) => ({ ...prev, [groupId]: false }));
      setLastCheckedGroup(groupId);
    }
  };

  const handleOpenModal = () => {
    setModalHeader(t('inviteshareheader'));
    setModalBody(<ShareWithFriendsModal isOpen={true} onClose={onClose} propertyId={propertyId} />);
    onOpen();
  };
  const handleShareWithFriend = () => {
    setModalBody(
      <div>
        <Popoverlogin onClose={handleClose} />
      </div>
    );
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const handleCloseModal = () => {
    setModalHeader('');
    setModalBody(null);
    onClose();
  };

  return (
    <>
      <Menu isOpen={isMenuOpen} >
      <MenuButton
          as={Button}
          size="sm"
          leftIcon={<FaExchangeAlt />}
          colorScheme="blue"
          onMouseEnter={() => {
            setIsMenuOpen(true);
            handleOpenMenu(); // Fetch data when hovered
          }}
          onMouseLeave={() => setIsMenuOpen(false)}
          onClick={handleOpenMenu}
          borderRadius={5}
          backgroundColor={"#e6ebef"}
        >Shortlist
        </MenuButton>
        <MenuList
          onMouseEnter={() => setIsMenuOpen(true)} 
          onMouseLeave={() => setIsMenuOpen(false)}
          sx={{ maxHeight: '200px', overflowY: 'auto', width: '300px' }}>
          {groups.length > 0 ? (
            groups.map((group) => (
              <MenuItem key={group.id}>
                {loadingGroups[group.id] ? (
                  <Spinner size="sm" />
                ) : (
                  <Checkbox
                    id={`prop_sel_${group.id}`}
                    isChecked={!!checkedGroupsByProperty[propertyId]?.[group.id]} // Check based on propertyId and groupId
                    colorScheme="green"
                    onChange={(e) => handleCheckChange(group.id, e)}
                    onClick={(e) => e.stopPropagation()} // Prevent event bubbling
                    sx={{
                      '& .chakra-checkbox__control': {
                        border: '2px solid #3182ce',
                        boxShadow: 'none',
                      },
                    }}
                    mr={5}
                  />
                )}
                <label htmlFor={`prop_sel_${group.id}`}>{group.name}</label>
                {/* {lastCheckedGroup === group.id && successMessage && (

                <Checkbox
                  id={"prop_sel_"+group.id}
                  isChecked={!!checkedGroups[group.id]}
                  colorScheme="green"
                  onChange={(e) => handleCheckChange(group.id, e)}
                  onClick={(e) => e.stopPropagation()} // Prevents closing the menu
                  sx={{
                    '& .chakra-checkbox__control': {
                      border: '2px solid #3182ce',
                      boxShadow: 'none',
                    },
                  }}
                  mr={5}
                />
               )}
                <label htmlFor={"prop_sel_"+group.id}>{group.name}</label> */}
                { lastCheckedGroup === group.id && successMessage && (

                  <Badge colorScheme="green" display="flex" ml={3} alignItems="center" fontSize="md" textTransform="none">
                    <CheckIcon boxSize="12px" color="green.500" mr={1} /> {successMessage}
                  </Badge>
                )}
                {lastCheckedGroup === group.id && errorMessage && (
                  <Badge colorScheme="red" display="flex" alignItems="center" fontSize="md" textTransform="none">
                    <CloseIcon boxSize="12px" color="red.500" mr={1} /> {errorMessage}
                  </Badge>
                )}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No groups available</MenuItem>
          )}
          <MenuDivider />
          {localStorage.getItem('userid') ? (
            <MenuItem onClick={handleOpenModal}> + Create New</MenuItem>
          ) : (
            <MenuItem onClick={() => {/* Handle sign in */}}> + Signin</MenuItem>
          )}
        </MenuList>
      </Menu>

      {/* Modal for Share with Friends */}
      {isOpen && (
        <ModalComponent isOpen={isOpen} onClose={handleCloseModal} modalHeader={modalHeader} modalBody={modalBody} />
      )}
    </>
  );
};

export default GroupMenu;
