import React from "react";
import {  Tabs, TabList, Tab} from "@chakra-ui/react";
import { useState } from 'react';
import ElasticSearchComponent from './ElasticSearchComponent';
const SearchHeader = () => {

const [showMap, setShowMap] = useState(true);
const [selectedTab, setSelectedTab] = useState(0);

const handleTabChange = (index) => {
  setSelectedTab(index);
  setShowMap(index === 0); 
};


  return (
    <>
    <div className="p-1 items-center justify-between">
      <div className="hidden md:block ml-auto w-full">
        <Tabs
          align="end"
          variant="enclosed"
          index={selectedTab}
          onChange={handleTabChange}
          color="black"
          borderBottom="1px solid #c4c8d8"
        >
          <TabList>
            {/* <Tab _selected={{ color: "#000", bg: "#fff" }}>Map</Tab>
            <Tab _selected={{ color: "#000", bg: "#fff" }}>List</Tab>
          </TabList>
        </Tabs> */}
        <Tab
            borderTop="1px solid #c4c8d8"
            borderLeft="1px solid #c4c8d8"
            borderRight="1px solid #c4c8d8"
            borderRadius="12px 12px 0 0"
            width={150}
            bg={selectedTab === 0 ? "#e6ebef" : "#fff"}
            px={4}
            py={2}
            _hover={{
              bg: "#f5f5f5",
            }}
            _selected={{
              bg: "#e6ebef",
              color: "#000",
            }}
          >
            Map
          </Tab>
          {/* Tab 2 */}
          <Tab
            borderTop="1px solid #c4c8d8"
            borderLeft="1px solid #c4c8d8"
            borderRight="1px solid #c4c8d8"
            borderRadius="12px 12px 0 0"
            width={150}
            bg={selectedTab === 1 ? "#e6ebef" : "#fff"}
            px={4}
            py={2}
            _hover={{
              bg: "#f5f5f5",
            }}
            _selected={{
              bg: "#e6ebef",
              color: "#000",
            }}
          >
            List
          </Tab>
        </TabList>
      </Tabs>
      </div>
    </div>
    <ElasticSearchComponent mapView={showMap} />
    </>
  );
};

export default SearchHeader;
