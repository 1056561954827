import React, { createContext, useState, useEffect, useRef, useContext } from 'react';
import keycloak from '../../keycloak';
import { useLocation, useNavigate } from 'react-router-dom';
import {updateToken} from '../../utils/axio-instance';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [globalMsg, setGlobalMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!initialized && location.pathname == '/login') {
      setInitialized(true);
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');
      keycloak.init({ onLoad: 'check-sso',
        checkLoginIframe: false, token, refreshToken })
        .then(authenticated => {
          setIsAuthenticated(authenticated);
          if(authenticated){
            localStorage.setItem("token", keycloak.token);
            localStorage.setItem("refreshToken", keycloak.refreshToken);
            localStorage.removeItem('usrgroups');
           // "sub": "fefab536-d4fd-4c9c-8f58-e3c42681b709",
            const parsedToken = parseJwt(keycloak.token);

            localStorage.setItem("userid", parsedToken.user_id)
            localStorage.setItem("kc_uid", parsedToken.sub);
            const clientRoles = parsedToken.resource_access[process.env.REACT_APP_CLIENT_ID] || {};
            console.log(clientRoles);
            localStorage.setItem('roles', clientRoles?.roles || ['buyer']);
            updateToken(keycloak.token);
            const curUserRole = clientRoles?.roles[0];
            const user_flags = parsedToken?.user_flags || "";

            console.log("user_flags: "+ user_flags.indexOf("firstUpdate"));
            if(user_flags.indexOf("firstUpdate") == -1){
              //show message for redirectioin
              setGlobalMsg("update_profile_g_message");
              if(curUserRole == 'builder'){
                navigate("/builder/builder-info/");
              }else if(curUserRole == 'selleragent'){
                navigate("/agent/agent-licence-info/");
              }else if(curUserRole == 'buyer' || curUserRole=='buyergrouprental'){
                navigate("/buyer/buyer-preferences/");
              }else if( curUserRole=='grouprental') {
                navigate("/groupbuyers/preferences/");                
              }else{
                const requestedPage = localStorage.getItem('requestedPage') || '/search';
                navigate(requestedPage);
                localStorage.removeItem('requestedPage');
              }
              return;
            }
            const requestedPage = localStorage.getItem('requestedPage') || '/search';
            navigate(requestedPage);
            localStorage.removeItem('requestedPage');
          }else{
            keycloak.login();
          }
        });
       
    }else if (location.pathname != '/sign-up') {
      const storedUserInfo = localStorage.getItem('userid');
      if(storedUserInfo && localStorage.getItem("token")) {
        const parsedToken = parseJwt(localStorage.getItem("token"));
        let currentDate = new Date();
        if (parsedToken.exp * 1000 < currentDate.getTime()) {
          // localStorage.removeItem("userid");
          // localStorage.removeItem("token");
          // localStorage.removeItem("roles");
            navigate('/login');
        }else{
         // navigate('/login');
        }
      }
    }
  }, [location.pathname]);

  const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }

  const logout = () => {
    console.log("logout to clear cache");
    try{
      keycloak.logout({redirectUri: process.env.REACT_APP_BASE_URL}).then(()=>{
       // keycloak.clearToken();
      });
     // handleLogout();
    }catch(e){
      console.log(e);
    }finally{
      localStorage.clear();
    }
  };

  const checkLogIn = () => {
    const loggedIn = !!localStorage.getItem('userid');
    if(loggedIn){
      setUser({
        accessToken: localStorage.getItem('token'),
        refreshToken: localStorage.getItem("refreshToken"),
        userId: localStorage.getItem('userid')
      })
    }
    return loggedIn;
  };
  
  return (
    <AuthContext.Provider value={{ isAuthenticated, globalMsg, setGlobalMsg, user, setUser, logout,checkLogIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
