import React, { useState, useEffect} from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Box,
} from '@chakra-ui/react';
import Popoverlogin from '../Popover';
import ShareWithFriendsModal from '../user/ShareWithFriendsModal';
import DetailsSidebar from "../../pages/DetailsPage/DetailsSidebar";
import ShowImages from "../../pages/DetailsPage/ShowImages";
import CommunityMap from "../../components/user/CommunityMap";
import {formatAddress} from '../../utils/format.js'
import { HashLink } from 'react-router-hash-link';
import TicketCreation from "../../components/forms/TicketCreation";


const CommunityInfo = (props) => {
  
  const { PropertyInfo, imageInfo, id } = props;
  const [toggle, setToggle] = useState(true );
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [isTalkAgentDrawerOpen, setIsTalkAgentDrawerOpen] = useState(false);


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleTalkAgentDrawer = () => {
    setIsTalkAgentDrawerOpen(!isTalkAgentDrawerOpen);
  };

  return (
  <div className="bg-white font-linksans">
      <div className="container py-12">
        <div className="grid grid-cols-12 gap-6 ">
        <div className=" 2xl:col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12 col-span-12 ">
           
          {/* price and quantity */}
          <div className="flex justify-between ">
              <div className="flex flex-col 2xl:gap-4 xl:gap-4 lg:gap-4 md:gap-3 gap-2">
              {PropertyInfo.community.communityName && (
                <h3 className="text-textColor font-bold 2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl ">
                {PropertyInfo.community.communityName}
                  </h3>
                    )}

                <h4 className="text-paragraphColor text-sm ">
                  <span className="font-medium">{formatAddress(PropertyInfo?.data.propertyInfo.address.label)}</span>
                </h4>
              </div>
            </div>

            <div className="px-6 2xl:py-4 text-sm md:text-base lg:text-base xl:text-base 2xl:text-base xl:py-4 lg:py-3.5 md:py-3 py-3 rounded-xl border mt-3 flex justify-between items-center">
              <div className="flex items-center gap-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 0.5C3.5813 0.5 0 4.0813 0 8.5C0 12.9187 3.5813 16.5 8 16.5C12.4187 16.5 16 12.9187 16 8.5C16 4.0813 12.4187 0.5 8 0.5ZM8 15.0455C4.3908 15.0455 1.4546 12.1092 1.4546 8.5C1.4546 4.8908 4.3908 1.9545 8 1.9545C11.6092 1.9545 14.5454 4.8909 14.5454 8.5C14.5454 12.1091 11.6092 15.0455 8 15.0455ZM7.102 7.8171H8.898V13.359H7.102V7.8171ZM7.102 4.2906H8.898V6.0866H7.102V4.2906Z"
                    fill="#C1C4CC"
                  />
                </svg>
                <h4 className="text-paragraphColor font-medium">
                {PropertyInfo?.builder.builderName} Builder
                </h4>
              </div>
              <div>
              <HashLink
                  to={'#talkToAgent'}
                  onClick={toggleTalkAgentDrawer}
                  className="underline text-textColor font-semibold"
                >
                  Talk to Agent
                </HashLink>

                {isTalkAgentDrawerOpen && (
                <Drawer isOpen={isTalkAgentDrawerOpen} placement="right" onClose={toggleTalkAgentDrawer}>
                <DrawerOverlay />
                <DrawerContent maxWidth={{ base: "100%", sm: "80%", md: "60%", lg: "40%", xl: "30%" }}>
                  <DrawerCloseButton />
                  <DrawerHeader>Talk to Agent</DrawerHeader>
                  <DrawerBody>
                    <TicketCreation propertyId={PropertyInfo?.main?.communityPublicID}/>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
                )}

                {isSidebarOpen && (
           <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          {/* Outer container for modal */}
          <div className="relative bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl h-[90vh] overflow-hidden">
            {/* Close button */}
            <button
              onClick={toggleSidebar}
              className="absolute top-2 right-4 text-gray-600"
            >
              X {/* Close button */}
            </button>
            
            {/* Scrollable content */}
            <div className="overflow-y-auto h-full">
              <DetailsSidebar />
            </div>
          </div>
        </div>
               )}
              </div>
            </div>
            <div className="grid grid-cols-2 bg-primary p-3 rounded-xl mt-6">
              <div className="w-full justify-center items-center">
                <button
                  onClick={() => setToggle(true)}
                  className={
                    toggle
                      ? "w-full rounded-md bg-white py-3"
                      : "w-full py-3 rounded-md"
                  }
                >
                  Show Photos
                </button>
              </div>
              <div className="w-full justify-center items-center">
                <button
                  onClick={() => setToggle(false)}
                  className={
                    toggle
                      ? "w-full rounded-md  py-3"
                      : "w-full bg-white py-3 rounded-md"
                  }
                >
                  Show Map
                </button>
              </div>
            </div>
            <div className="pt-6">
              {PropertyInfo ? (
                toggle ? <ShowImages images={imageInfo} /> : <CommunityMap MapInfo={PropertyInfo} />
              ) : (
                <p>Loading...</p> // Optionally show a loading message or spinner
              )}
            </div>
            </div>
        </div>
        </div>
        </div>
  );
};

export default CommunityInfo;
