import { useEffect, useState } from 'react';
import {axiosInstanceES} from '../../utils/axio-instance';
import { Link } from 'react-router-dom';
import {states} from '../../data/us_states';

function SitemapCityStates() {
  const [usstates, setUsStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [showLessCities, setShowlessCities] = useState('hidden');
  const [showMoreTxt, setShowMoreTxt] = useState('Show more...');

  useEffect(()=>{
    axiosInstanceES.get(`/unique-locations`)
      .then(response => {       
        const dbStates = response.data.states;
        setCities(response.data.cities);
        const updatedStates =dbStates.map((stateObj)=>{
          const filState = states.filter(st=>st.abbreviation == stateObj || st.name == stateObj);
          if(filState.length>0){            
            return filState[0];
          }
          return null;
        });
        const stateset = new Set(updatedStates);
        setUsStates([...stateset]);

      }).catch(error => {
        console.error('Error fetching data:', error);
      });
  },[]);

  const showMoreCitiesHandle = ()=>{
    if(showLessCities == 'hidden'){
      setShowlessCities("");
      setShowMoreTxt("Show less...")
    }else{
      setShowlessCities("hidden");
      setShowMoreTxt("Show more...")
    }
  }

  const getStateName = (abr)=>{
    const filState = states.filter(st=>st.abbreviation == abr);
    if(filState.length>0){
      return filState[0].name;
    }
    return abr;
  }
  return (
    <div className='container mx-auto 2xl:py-10 xl:py-10 lg:py-14 md:py-14 py-8'>
        <div className="xl:pb-12 lg:pb-10 md:pb-8 pb-8 2xl:pb-14 font-semibold text-textColor">
          <h3 className='2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl '>
            Browse Homes By States & Cities
          </h3>
        </div>
        <div className=''>
          <div className='bg-primary px-3 overflow-auto text-nowrap 2xl:flex-nowrap xl:flex-wrap  gap-2.5 2xl:gap-0  py-3 flex justify-between rounded-lg'>
          <h4 className={'p-2 bg-white shadow-md text-textColor rounded-md'}>
            Browse Homes By Cities
          </h4>
          </div>

				<div className='py-5'>
					<div className='grid 2xl:grid-cols-5 lg:grid-cols-4 xl:grid-cols-5 md:grid-cols-3 grid-cols-2 xl:gap-y-3.5 md:gap-y-2.5 lg:gap-y-3 2xl:gap-y-4 gap-y-2 w-full h-full px-4 '>
						{cities?.map((cityObj, index) => (
							cityObj.city&& (<div className={`2xl:pb-4 pb-3 ${index>25? showLessCities: '' }`} key={index}>
								<div className='w-fit'>
									<div className='relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-[rgb(27,29,31)] after:bg-[rgb(27,29,31)] before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-[rgb(0,102,204)]after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]'>
										<Link to={`/search/?city=${cityObj.city}&state=${cityObj.state}`} onClick={() => window.scrollTo(0, 0)} className='text-gray-700 font-medium'>
											{cityObj.city}, {getStateName(cityObj.state)}
										</Link>
									</div>
								</div>
							</div>
						)))}
					</div>

          <div className='cursor-pointer 2xl:flex-nowrap xl:flex-wrap  gap-2.5 2xl:gap-0  py-3 flex justify-between rounded-lg'>
            <Link to="#" className={'p-2 bg-white shadow-md  text-textColor rounded-md'} onClick={showMoreCitiesHandle}>
              {showMoreTxt}
            </Link>
          </div>

				</div>
			</div>
      
      <div className="xl:pb-12 lg:pb-10 md:pb-8 pb-8 2xl:pb-14 text-textColor">
        <div className='bg-primary px-3 overflow-auto text-nowrap 2xl:flex-nowrap xl:flex-wrap  gap-2.5 2xl:gap-0  py-3 flex justify-between rounded-lg'>
          <h4 className={'p-2 bg-white shadow-md text-textColor rounded-md'}>
            Browse Homes By States
          </h4>
          </div>
        </div>

        <div className='py-1'>
					<div className='grid 2xl:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5 md:grid-cols-3 grid-cols-2 xl:gap-y-3.5 md:gap-y-2.5 lg:gap-y-3 2xl:gap-y-4 gap-y-2 w-full h-full px-4 '>
						{usstates?.map((stateObj, index) => (
							stateObj && (<div className='2xl:pb-4 pb-3' key={index}>
								<div className='w-fit'>
									<div className='relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-[rgb(27,29,31)] after:bg-[rgb(27,29,31)] before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-[rgb(0,102,204)]after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]'>
										<Link to={`/search/?state=${stateObj.abbreviation}`} className='text-gray-700 font-medium'>
										{stateObj.name}
										</Link>
									</div>
								</div>
							</div>)
						))}
					</div>
				</div>

    </div>
  )
}

export default SitemapCityStates