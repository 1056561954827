import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";

import "./testimonialslider.css";

import { EffectCoverflow, Navigation } from "swiper/modules";
import { MdArrowForwardIos } from "react-icons/md";

function TestimonialSlider() {
  return (
    <div className=" testimonial-page">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 80,
          modifier: 2.5,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <div className="px-8 py-8 bg-white flex content-between 2xl:h-[500px] xl:h-[450px] lg:h-[420px] h-[400px] flex-col justify-between border-borderColor border rounded-lg">
            <div className="flex flex-col">
              <div>
              <h4 className="text-textColor font-xl  font-semibold">Collaborative home shortlists/picks for best decisions</h4>
              </div>
              <div className="text-paragraphColor pt-6 space-y-4">
              <p>Our Technology and model makes a big difference in buying beautiful new construction homes. Imagine that you can sit in your chair, invite your friends, family or colleagues and pick the best home through our innovative collaborative shortlists groups.</p>
              <p>All can be done collaboratively and remotely within our platform. For the first time in the world, a collective decision is possible with our powerful collaborative shortlists and makes it easy to buy Beautiful New Home.</p>
              </div>
              </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="px-8 py-8 bg-white flex content-between 2xl:h-[500px] xl:h-[450px] lg:h-[420px] h-[400px] flex-col justify-between border-borderColor border rounded-lg">
            <div className="flex flex-col">
              <div>
              <h4 className="text-textColor font-xl  font-semibold">New Construction focus at first</h4>
              </div>
              <p className="text-paragraphColor pt-6">
              New homes rarely need repairs/renovations, lower maintenance costs, less hassle, better joy in the home and can be customizable as per your interests. 
              Most new-construction homes have warranties, financial incentives, new modern models/floor plans and price holding.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="px-8 py-8 bg-white flex content-between 2xl:h-[500px] xl:h-[450px] lg:h-[420px] h-[400px] flex-col justify-between border-borderColor border rounded-lg">
            <div className="flex flex-col">
            <div>
              <h4 className="text-textColor font-xl  font-semibold">Awesome Technology</h4>
              </div>
              <p className="text-paragraphColor pt-6">
              We analyze tons of data and make it super easy for you and our agents to work as a team and make sure that you get the right home.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="px-8 py-8 bg-white flex content-between 2xl:h-[500px] xl:h-[450px] lg:h-[420px] h-[400px] flex-col justify-between border-borderColor border rounded-lg">
            <div className="flex flex-col">
              <div>
              <h4 className="text-textColor font-xl  font-semibold">Outstanding Agents & Delivering Exceptional Results
              </h4>
              </div>
              <p className="text-paragraphColor pt-6">
              For Individual Buyers, we match and help you find the perfect real estate agent based on local market data to help you achieve your goals. Right agent will tour the right homes, guide you through making an offer, negotiation, inspections, paperwork, and more.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="px-8 py-8 bg-white flex content-between 2xl:h-[500px] xl:h-[450px] lg:h-[420px] h-[400px] flex-col justify-between border-borderColor border rounded-lg">
            <div className="flex flex-col">
              <div>
              <h4 className="text-textColor font-xl  font-semibold">Buying a house can be a less pain
              </h4>
              </div>
              <p className="text-paragraphColor pt-6">
              We're making it less painful by matching with the perfect agents.  Our agents are expert negotiators, and will help you understand your options so you can make an informed decision.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="px-8 py-8 bg-white flex content-between 2xl:h-[500px] xl:h-[450px] lg:h-[420px] h-[400px] flex-col justify-between border-borderColor border rounded-lg">
            <div className="flex flex-col">
              <div>
              <h4 className="text-textColor font-xl  font-semibold">Local Agents Expertise</h4>
              </div>
              <p className="text-paragraphColor pt-6">
              We connect with local agents who have experience and skill in helping you. Local agents as experts with experience in your neighborhood, able to provide top service at competitive rates, getting you the best deal possible. Our goal is to make things easier for you to go through all options and make the right decision.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <div className="slider-controler">
          <div className="swiper-button-prev  slider-arrow">
            <MdArrowForwardIos
              className="rotate-180"
              size={8}
              color="#222224"
            />
          </div>
          <div className="swiper-button-next slider-arrow">
            <MdArrowForwardIos size={8} color="#222224" />
          </div>
        </div>
      </Swiper>
    </div>
  );
}

export default TestimonialSlider;
