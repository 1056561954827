import React, { useState } from 'react';
import { Grid, Container, Flex, Button, Text } from '@chakra-ui/react';
import { FiSearch, FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import ProductCard from './ProductCard';
import { useProducts } from './ProductsProvider'; 


const ProductList = (props) => {
  const { products, selectedProducts, suggestionProducts } = useProducts();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const productsPerPage = 4;
  const totalProducts = suggestionProducts.length;
  const totalPages = Math.ceil(totalProducts / productsPerPage);

  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = Math.min(startIndex + productsPerPage, totalProducts);
  let currentProducts=[];
  if(props.autocompletedata && props.autocompletedata.length>0){
     currentProducts = props.autocompletedata.slice(startIndex, endIndex);
  }
  else{
    currentProducts = suggestionProducts.slice(startIndex, endIndex);
  }

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <>
    {currentProducts.length > 0 && (
        <>
       <div className="bg-primary font-linksans">
       <div className="w-full px-5 py-12">
      <Container maxW='100%'>
        <Text fontSize="lg" color={'blue.500'} fontWeight="bold" mt={6}>Recommendations</Text>
      </Container>
      <Container maxW='100%' mt={5}>
        <Grid templateColumns={`repeat(${currentProducts.length}, 1fr)`} gap={2} justifyContent="center">
          {currentProducts.map((item, idx) => (
            <ProductCard
              key={idx}
              data={item}
              added={(selectedProducts.length>0) && selectedProducts.some(sel => sel === item.id)}
              // removeFromCompare={() => props.removeFromCompare(item)}
              // addToCompare={() => props.addToCompare(item)}
            />
          ))}
        </Grid>
        <Flex mt='5' justifyContent="space-between">
           <Button leftIcon={<FiArrowLeft />}  px="4" py="5" onClick={goToPreviousPage} isDisabled={currentPage === 1} mr={2}>
          Previous
        </Button>
        <Button leftIcon={<FiArrowRight />}  px="4" py="5" onClick={goToNextPage} isDisabled={currentPage === totalPages || endIndex === totalProducts}>
          Next
        </Button>
        </Flex>
      </Container>
      </div>
      </div>
    </>
     )}
    </>
  );
}

export default ProductList;
