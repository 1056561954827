import { useState, useEffect  } from "react";
import { Link } from "react-router-dom";
import ShowImages from "../ShowImages";
import DetailsSidebar from "../DetailsSidebar";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Box,
  Text
} from '@chakra-ui/react';
import axiosInstance, {axiosInstanceES, axiosInstanceIMG} from '../../../utils/axio-instance';
import { useParams } from 'react-router-dom';
import PriceDisplay from "../PriceDisplay";
import MapContainer from "../../../components/MapContainer";
import DynamicBreadcrumb from '../../../components/Breadcrumb';
import ShareGroupNames from '../../../components/user/ShareGroupNames';
import { propTypeLabels  } from '../../../constants';
import {formatAddress} from '../../../utils/format.js';
import { HashLink } from 'react-router-hash-link';
import TicketCreation from "../../../components/forms/TicketCreation";



function DetailsMain() {

  const { id } = useParams();
  const [toggle, setToggle] = useState(true);
  const [images, setImages] = useState([]);
  const [data, setData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false); 
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isTalkAgentDrawerOpen, setIsTalkAgentDrawerOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleTalkAgentDrawer = () => {
    setIsTalkAgentDrawerOpen(!isTalkAgentDrawerOpen);
  };

  const MAX_LENGTH = 250;

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const getPropertyLabel = (propertyType) => {
    return propTypeLabels[propertyType] || 'Unknown Property Type'; // Fallback for unknown property types
  };

  useEffect(() => {
    // Check if user ID exists in local storage
    const storedUserId = localStorage.getItem('userid');
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);
  
  const propertyDescription = data?.modelDescription;

  const textToDisplay = isExpanded
  ? data?.modelDescription // Full text when expanded
  : data?.modelDescription?.slice(0, MAX_LENGTH); // Shortened text


  useEffect(() => {
    try {
      axiosInstanceES.get(`/properties/${id}`)
      .then(response => {
        setData(response.data[0]);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
      
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [id]);


  
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axiosInstanceIMG.get(`/images/list/${id}`);
        // Assuming the response is an array of objects with 'url' field
        setImages(response.data?.images); 
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

      fetchImages();

  }, [id]); 


  const formattedSqft = new Intl.NumberFormat('en-US').format(data?.sqft);

  const shouldDisplayDescription =
    propertyDescription && propertyDescription !== "N/A";


  return (
    <div className="bg-white font-linksans pb-12">
      <div className="container px-5 py-5">
        <div className="grid grid-cols-12">
          <div className=" 2xl:col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12 col-span-12">
          {data != null && <DynamicBreadcrumb PropertyInfo={data} />}
          <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
          <div className="col-span-1 md:col-span-8">

            {/* time and sale info */}
            <div className="flex items-center gap-4 pb-4">
              <div className="flex gap-1.5 items-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="9"
                  viewBox="0 0 8 9"
                  fill="none"
                >
                  <circle cx="4" cy="4.5" r="4" fill="#00BC38" />
                </svg>
                <span className="text-sm text-paragraphColor">{data?.modelStatus}</span>
              </div>
          
            </div>
            {/* price and quantity */}
            <div className="flex justify-between ">
              {/* <div className="flex flex-col 2xl:gap-4 xl:gap-4 lg:gap-4 md:gap-3 gap-2">
                <h3 className="text-textColor font-bold 2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl ">
                  <PriceDisplay price={data?.price} />
                  </h3>
                  {data?.modelName && <Text fontWeight="medium" fontSize="sm" className="text-base md:text-lg" padding={'10px 0px'}>
                  {data?.modelName}</Text>}

                  {data?.address && data?.address !== 'N/A' && (
                <h4 className="text-paragraphColor text-sm ">
                  <span className="font-medium">{formatAddress(data?.address)}</span>
                </h4>
                 )}
              </div> */}
              <div className="flex flex-col gap-2 md:gap-3 lg:gap-4">
                  {/* Price and Model Name side by side */}
                  <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                    <h3 className="text-textColor font-bold text-2xl md:text-3xl lg:text-4xl">
                      <PriceDisplay price={data?.price} />
                    </h3>
                    {data?.modelName && (
                      <Text
                        fontWeight="medium"
                        fontSize="sm"
                        className="text-base md:text-lg"
                        padding="0"
                      >
                        {data?.modelName}
                      </Text>
                    )}
                  </div>

                  {/* Address below Price and Model Name */}
                  {data?.address && data?.address !== "N/A" && (
                    <h4 className="text-paragraphColor text-sm">
                      <span className="font-medium">{formatAddress(data?.address)}</span>
                    </h4>
                  )}
                </div>

              <div className="flex items-center 2xl:gap-10 xl:gap-10 lg:gap-10 md:gap-8 gap-6">
                <div className="flex flex-col justify-center items-center 2xl:gap-1.5 xl:gap-1.5 lg:gap-1.5 md:gap-1 gap-px">
                  <h4 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl  font-bold">{data?.bedrooms}</h4>
                  <span>beds</span>
                </div>
                <div className="flex flex-col justify-center items-center 2xl:gap-1.5 xl:gap-1.5 lg:gap-1.5 md:gap-1 gap-px">
                  <h4 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl  font-bold">{data?.bathrooms}</h4>
                  <span>baths</span>
                </div>
                <div className="flex flex-col justify-center items-center 2xl:gap-1.5 xl:gap-1.5 lg:gap-1.5 md:gap-1 gap-px">
                  <h4 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl  font-bold">{formattedSqft}</h4>
                  <span>sqft</span>
                </div>
              </div>
            </div>
            {/* estimated price */}
            <div className="flex gap-2 items-center pt-5 text-gray-400">
                           {(userId && data?.id && data?.id != "N/A" ) && 
                    <Box className='py-3 border-b border-dashed'>
                    <ShareGroupNames propertyId={data?.id}/>
                    </Box>
                    } 
            </div>

            {/* box */}
            <div className="px-6 2xl:py-4 text-sm md:text-base lg:text-base xl:text-base 2xl:text-base xl:py-4 lg:py-3.5 md:py-3 py-3 mt-3 flex justify-between items-center">
              <div className="flex items-center gap-1.5">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 0.5C3.5813 0.5 0 4.0813 0 8.5C0 12.9187 3.5813 16.5 8 16.5C12.4187 16.5 16 12.9187 16 8.5C16 4.0813 12.4187 0.5 8 0.5ZM8 15.0455C4.3908 15.0455 1.4546 12.1092 1.4546 8.5C1.4546 4.8908 4.3908 1.9545 8 1.9545C11.6092 1.9545 14.5454 4.8909 14.5454 8.5C14.5454 12.1091 11.6092 15.0455 8 15.0455ZM7.102 7.8171H8.898V13.359H7.102V7.8171ZM7.102 4.2906H8.898V6.0866H7.102V4.2906Z"
                    fill="#C1C4CC"
                  />
                </svg>
                <h4 className="text-paragraphColor font-medium">
                  Est.<PriceDisplay price={data?.price} />
                </h4> */}
              </div>
              <div>
              <HashLink
                  to={'#talkToAgent'}
                  onClick={toggleTalkAgentDrawer}
                  className="underline text-textColor font-semibold"
                >
                  Talk to Agent
                </HashLink>

                {isSidebarOpen && (
           <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          {/* Outer container for modal */}
          <div className="relative bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl h-[90vh] overflow-hidden">
            {/* Close button */}
            <button
              onClick={toggleSidebar}
              className="absolute top-2 right-4 text-gray-600"
            >
              X {/* Close button */}
            </button>
            
            {/* Scrollable content */}
            <div className="overflow-y-auto h-full">
              <DetailsSidebar />
            </div>
          </div>
        </div>
      )}
              </div>
            </div>

            {/* details text */}
            <div className="py-6 text-sm md:text-base lg:text-base xl:text-base 2xl:text-base">
            {shouldDisplayDescription && (
              <p className="text-paragraphColor ">
                 {textToDisplay}
                {data?.modelDescription?.length > MAX_LENGTH && (
                  <>
                    {isExpanded ? null : '...'}
                    <div className="text-right">
                    <Link to="#" onClick={toggleReadMore}>
                      <h4 className="underline font-semibold pt-2">
                        {isExpanded ? 'Read Less' : 'Read More'}
                      </h4>
                    </Link>
                    </div>
                  </>
                )}
              </p>
                )}
            </div>

            {/* table */}
            <div className="p-6 rounded-xl bg-primary ">
              {/* title */}
              <h3 className="2xl:text-2xl xl:text-2xl lg:text-2xl md:text-lg text-lg  font-semibold pb-6">Property Facts</h3>

              <div className="grid grid-cols-12 gap-4">
                {/* Left Section */}
                <div className="grid grid-cols-2 2xl:col-span-5 xl:col-span-5 lg:col-span-5 md:col-span-12 col-span-12">
                  <div className="flex flex-col text-gray-500 gap-3">
                    {data?.modelStatus && data.modelStatus !== 'N/A' && <h4>Status:</h4>}
                    {data?.city && data.city !== 'N/A' && <h4>City:</h4>}
                    {data?.street && data.street !== 'N/A' && <h4>County:</h4>}
                  </div>
                  <div className="flex flex-col gap-3">
                    {data?.modelStatus && data.modelStatus !== 'N/A' && <h4>{data?.modelStatus}</h4>}
                    {data?.city && data.city !== 'N/A' && <h4>{data?.city}</h4>}
                    {data?.street && data.street !== 'N/A' && <h4>{data?.street}</h4>}
                  </div>
                </div>

                {/* Right Section */}
                <div className="grid grid-cols-2 2xl:col-span-7 xl:col-span-7 lg:col-span-7 md:col-span-12 col-span-12">
                  <div className="flex text-gray-500 flex-col gap-3">
                    {data?.property_type && data.property_type !== 'N/A' && <h4>Property Type:</h4>}
                    {formattedSqft && <h4>Lot Size:</h4>}
                    {data?.built_year && data.built_year !== 'N/A' && <h4>Year Built:</h4>}
                  </div>
                  <div className="flex flex-col gap-3">
                    {/* {data?.property_type && data.property_type !== 'N/A' && <h4>{data?.property_type}</h4>} */}
                    {data?.property_type && data.property_type !== 'N/A' && (
                      <h4>{getPropertyLabel(data.property_type)}</h4> 
                    )}
                    {formattedSqft && <h4>{formattedSqft}</h4>}
                    {data?.built_year && data.built_year !== 'N/A' && <h4>{data?.built_year}</h4>}
                  </div>
                </div>
              </div>

            </div>
            {/* table */}
            <div className="p-6 mt-6 rounded-xl bg-primary ">
              {/* title */}
              <h3 className="2xl:text-2xl xl:text-2xl lg:text-2xl md:text-lg text-lg font-semibold pb-6">Exterior Features</h3>

              <div className="grid grid-cols-12 gap-4">
              {/* Left Section */}
              <div className="grid grid-cols-2 2xl:col-span-5 xl:col-span-5 lg:col-span-5 md:col-span-12 col-span-12">
                <div className="flex flex-col text-gray-500 gap-3">
                  {data?.buildername && data.buildername !== 'N/A' && <h4>Builder:</h4>}
                  {data?.communityname && data.communityname !== 'N/A' && <h4>Community:</h4>}
                </div>
                <div className="flex flex-col gap-3">
                  {data?.buildername && data.buildername !== 'N/A' && <h4>{data?.buildername}</h4>}
                  {data?.communityname && data.communityname !== 'N/A' && <h4>{data?.communityname}</h4>}
                </div>
              </div>

              {/* Right Section */}
              <div className="grid grid-cols-2 2xl:col-span-7 xl:col-span-7 lg:col-span-7 md:col-span-12 col-span-12">
                <div className="flex text-gray-500 flex-col gap-3">
                  {data?.stories && data.stories !== 'N/A' && <h4>Design:</h4>}
                  {data?.garageSquareFeet && data.garageSquareFeet !== 'N/A' && <h4>Garage Space:</h4>}
                </div>
                <div className="flex flex-col gap-3">
                  {data?.stories && data.stories !== 'N/A' && <h4>{data?.stories} Story</h4>}
                  {data?.garageSquareFeet && data.garageSquareFeet !== 'N/A' && <h4>{data?.garageSquareFeet}</h4>}
                </div>
              </div>
            </div>

            </div>
            </div>
            <div className="col-span-1 md:col-span-4">
              <TicketCreation propertyId={data?.id}/>
              </div>
            </div>

            {isTalkAgentDrawerOpen && (
                <Drawer isOpen={isTalkAgentDrawerOpen} placement="right" onClose={toggleTalkAgentDrawer}>
                <DrawerOverlay />
                <DrawerContent maxWidth={{ base: "100%", sm: "80%", md: "60%", lg: "40%", xl: "30%" }}>
                  <DrawerCloseButton />
                  <DrawerHeader>Talk to Agent</DrawerHeader>
                  <DrawerBody>
                    <TicketCreation propertyId={data?.id}/>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
                )}

            <div className="grid grid-cols-2 bg-primary p-3 rounded-xl mt-6">
              <div className="w-full justify-center items-center">
                <button
                  onClick={() => setToggle(true)}
                  className={
                    toggle
                      ? "w-full rounded-md bg-white py-3"
                      : "w-full py-3 rounded-md"
                  }
                >
                  Show Photos
                </button>
              </div>
              <div className="w-full justify-center items-center">
                <button
                  onClick={() => setToggle(false)}
                  className={
                    toggle
                      ? "w-full rounded-md  py-3"
                      : "w-full bg-white py-3 rounded-md"
                  }
                >
                  Show Map
                </button>
              </div>
            </div>
   
            <div className="pt-6">
              {data ? (
                toggle ? <ShowImages images={images} /> : <MapContainer MapInfo={data} />
              ) : (
                <p>Loading...</p> // Optionally show a loading message or spinner
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsMain;
