import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { HashLink } from 'react-router-hash-link';
import TicketCreation from "../../components/forms/TicketCreation";

function ShowImages({ images }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const categories = Object.keys(images);

  const [activeCategory, setActiveCategory] = useState(categories[0] || '');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const allImagesAvailable = categories.some(category => images[category]?.length);
  const [isTalkAgentDrawerOpen, setIsTalkAgentDrawerOpen] = useState(false);


  useEffect(() => {
    if (categories.length > 0 && !categories.includes(activeCategory)) {
      setActiveCategory(categories[0]);
    }
  }, [images, categories, activeCategory]);

  // Helper function to generate the download URL for each image key
  const getImageUrl = (key) => `${process.env.REACT_APP_IMAGE_URL}/images/download/${key}`;

  const handleNextImage = () => {
    if (images[activeCategory]?.length) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images[activeCategory].length);
    }
  };

  const toggleTalkAgentDrawer = () => {
    setIsTalkAgentDrawerOpen(!isTalkAgentDrawerOpen);
  };

  const handlePreviousImage = () => {
    if (images[activeCategory]?.length) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? images[activeCategory].length - 1 : prevIndex - 1
      );
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 overflow-hidden rounded-xl">
    {allImagesAvailable ? (
      <>
        {/* Left Side: Display all images with categories */}
        <div className="relative overflow-hidden max-h-[80vh] col-span-2 pr-4">
          <img
            src={getImageUrl(images[activeCategory]?.[currentImageIndex])}
            alt={`${activeCategory} - image ${currentImageIndex}`}
            className="max-h-[80vh] w-full object-cover"
          />
          <button
            onClick={handlePreviousImage}
            className="absolute left-0 top-1/2 bg-gray-200 transform -translate-y-1/2 text-teal-500 z-10 p-2 rounded-full"
          >
            <ChevronLeftIcon />
          </button>
          <button
            onClick={handleNextImage}
            className="absolute right-0 top-1/2 bg-gray-200 transform -translate-y-1/2 text-teal-500 z-10 p-2 rounded-full"
          >
            <ChevronRightIcon />
          </button>
        </div>
  
        {/* Right Side: Display a single image from each category */}
        <div className="overflow-y-auto pr-4 max-h-[80vh]">
          {categories.map((category) => (
            <div key={category} className="mb-4">
              {images[category]?.[0] && (
                <button
                  onClick={() => {
                    setActiveCategory(category);
                    setCurrentImageIndex(0);
                    onOpen();
                  }}
                  className="relative w-full overflow-hidden"
                >
                  <img
                    src={getImageUrl(images[category][0])}
                    alt={`${category} - image 0`}
                    className="object-cover w-full h-48 rounded-md"
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 text-center">
                    <p className="text-lg font-medium">
                      {category.charAt(0).toUpperCase() + category.slice(1)}
                    </p>
                  </div>
                </button>
              )}
            </div>
          ))}
        </div>
  
        {/* Modal for larger image view */}
        <Modal isOpen={isOpen} onClose={onClose} size="full">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Photos</ModalHeader>
            <ModalCloseButton />
            <ModalBody className="flex flex-col items-center justify-center">
              <div className="relative text-center w-full">
                <Tabs
                  isLazy
                  variant="enclosed"
                  className="w-full"
                  onChange={(index) => setActiveCategory(categories[index])}
                >
                  <TabList>
                    {categories.map((category) => (
                      <Tab key={category}>
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </Tab>
                    ))}
                  </TabList>
                  <TabPanels>
                    {categories.map((category) => (
                      <TabPanel key={category}>
                        <div className="grid gap-4 mt-5 relative w-full">
                          {images[category]?.map((image, index) => (
                            <img
                              key={image}
                              src={getImageUrl(image)}
                              alt={`${category} - image ${index}`}
                              className="max-h-[80vh] w-full object-cover"
                            />
                          ))}
                        </div>
                      </TabPanel>
                    ))}
                  </TabPanels>
                </Tabs>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
                onClick={onClose}
              >
                Close
              </button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    ) : (
      <div className="col-span-3 text-center py-8">
      <p className="text-gray-500 text-sm font-medium">
        <HashLink
          to={"#talkToAgent"}
          onClick={toggleTalkAgentDrawer}
          className="underline text-black-500 font-semibold"
        >
           Contact us to get Photos and other information
        </HashLink>
      </p>
    </div>
    )}
     {isTalkAgentDrawerOpen && (
          <Drawer isOpen={isTalkAgentDrawerOpen} placement="right" onClose={toggleTalkAgentDrawer}>
          <DrawerOverlay />
          <DrawerContent maxW="25%">
            <DrawerCloseButton />
            <DrawerHeader>Talk to Agent</DrawerHeader>
            <DrawerBody>
              <TicketCreation />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
          )}
    </div>  
  );
}

export default ShowImages;
