import React from 'react';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';

const InfoBar = ({ message }) => {
  if (!message) return null;

  return (
    <div className="flex items-center justify-center px-4">
      <div className="flex bg-blue-100 text-blue-800 font-medium px-4 py-8 rounded-md border border-blue-600 w-full max-w-[500px] md:max-w-[80%] lg:max-w-[600px]">
        <FaInfoCircle className="mr-2 text-blue-600" size={20} />
        <p>{message}</p>
      </div>
    </div>
  );
};

InfoBar.propTypes = {
  message: PropTypes.string.isRequired, 
};

export default InfoBar;
