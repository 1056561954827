import React, { useMemo, useState, useEffect } from "react";
import { useReactTable, useTable, getCoreRowModel, flexRender, getFilteredRowModel, getPaginationRowModel } from "@tanstack/react-table";
import axiosInstance from "../../utils/axio-instance";
import { Button, useDisclosure,useToast, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Input, Text, Select} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next'; 
import { referralStatus} from '../../constants';
import ReferralHistoryModal from "./ReferralHistoryModal";
import Swal from "sweetalert2";
import DateDisplay, {formatDateHumanReadable} from '../../utils/DateDisplay';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ProfileModal from "./ProfileModal";




const AssignAgent = () => {
  const apiUrl = "referral/list";

  // State management
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const toast = useToast();  
  const { t} = useTranslation();
  const [refreshKey, setRefreshKey] = useState(0); 

  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);


  const { isOpen, onOpen, onClose } = useDisclosure();
  const [validationErrors, setValidationErrors] = React.useState({});
  const [filters, setFilters] = useState({
    buyer_email: "",
    agent_email: "",
    property_id: "",
  }); // Filter state
  const [editRowId, setEditRowId] = useState(null);
  const [editRowData, setEditRowData] = useState({});
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [focusedField, setFocusedField] = useState(null);
  
  const [userProfile, setUserProfile] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);

  // Fetch data from server

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(apiUrl, {
        params: {
          from: pageIndex*pageSize,
          limit: pageSize,
          filters: columnFilters.reduce((acc, filter) => {
            acc[filter.id] = filter.value;
            return acc;
          }, {}),
        },
      });

      const { data: users, totalCount } = response.data.data;
      setData(users);
      setTotalPages(Math.ceil(totalCount / pageSize));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [apiUrl, pageIndex, pageSize, columnFilters, refreshKey]);

  // Define columns
  const columns = useMemo(
    () => [
      {
        id: 'actions',
        header: "Actions",
        cell: ({ row }) => {
          const isEditing = editRowId === row.original.referral_id; // Assuming you have a state like editRowId
      
          return isEditing ? (
            <div className="flex flex-wrap gap-2 justify-center">
              <Button 
              onClick={() => handleUpdateRow(row.original.referral_id)}
              style={{
                marginRight: '8px',
                padding: '4px 8px',
              }}
              size="sm"
              >Save</Button>
              <Button 
              onClick={handleCancel}
              style={{
                padding: '4px 8px',
              }}
              size="sm"
              >Cancel</Button>
            </div>
          ) : (
            <div className="flex flex-wrap gap-2 justify-center">
            <Button 
             onClick={() => handleEdit(row.original)}
             style={{
              marginRight: '8px',
              padding: '4px 8px',
            }}
            size="sm"
              >Edit</Button>
            <Button
              onClick={() => handleHistory(row.original.referral_id)}
              style={{
                padding: '4px 8px',
              }}
              size="sm"
            >
              View
            </Button>
            </div>
          );
        },
      },
      {
        accessorKey: "buyer_email",
        header: "Buyer Email",
        filterFn: "includesString",
        Filter: ({ column }) => (
          <input
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder="Filter by email"
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
            autoFocus={focusedField === "buyer_email"} 
            onFocus={() => setFocusedField("buyer_email")}  
          />
        ),
        cell: ({ row }) =>
          editRowId === row.original.buyer_id ? (
            <input
              type="text"
              value={editRowData.buyer_email || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, buyer_email: e.target.value }))
              }
              placeholder="Enter Input"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "buyer_email"} 
             onFocus={() => setFocusedField("buyer_email")} 
            />
          ) : (
            <span className="cursor-pointer hover:underline" onClick={() => handleInfoClick(row.original.buyer_id)}>{row.original.buyer_email}</span>
          ),
      },
      
      {
        accessorKey: "agent_user_id",
        header: "Agent/Builder Email",
        cell: ({ row }) =>
          editRowId === row.original.referral_id ? (
            <input
              type="text"
              value={editRowData.agent_email || ""}
              onChange={(e) =>{
                setEditRowData((prev) => ({ ...prev, agent_email: e.target.value }));
              }}
              placeholder="Enter Input"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "agent_user_id"} 
             onFocus={() => setFocusedField("agent_user_id")}  
            />
          ) : (
            row.original.agent_email
          ),
      },
      { accessorKey: "property_id", header: "Property" },
      {
        accessorKey: 'updated_time',
        header: 'Last Modified',
        cell: ({ row }) => {
          const updatedTime = row.original.updated_time;
          return updatedTime
            ? <DateDisplay date={updatedTime} />
            : 'N/A'; // Display 'N/A' if no updated_time is available
        },
      },
      {
        accessorKey: "referral_status",
        header: "Status",
        filterFn: "equals",
        Filter: ({ column }) => (
          <select
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
          >
            <option value="">All</option>
            {referralStatus.map((status) => (
              <option key={status.value} value={status.value}>
                {status.label}
              </option>
            ))}
          </select>
        ),
        cell: ({ row }) => {
          const status = row.original.referral_status;
          return editRowId === row.original.referral_id ? (
            <Select
              value={editRowData.referral_status || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, referral_status: e.target.value }))
              }
              placeholder="Select Status"
              style={{
                width: "100%",
                height: "100%",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
            >
              {referralStatus.map((statusOption) => (
                <option key={statusOption.value} value={statusOption.value}>
                  {statusOption.label}
                </option>
              ))}
            </Select>
          ) : (
            ['no-response'].includes(status) ? '' : getStatusLabel(status)
          );
        },
      },
      {
        accessorKey: "latest_comment",
        header: "Note",
        cell: ({ row }) =>
          editRowId === row.original.referral_id ? ( // Match the same condition used for editing
            <input
              type="text"
              value={editRowData.latest_comment || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, latest_comment: e.target.value }))
              }
              placeholder="Enter Note"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "latest_comment"} 
              onFocus={() => setFocusedField("latest_comment")} 
            />
          ) : (
            row.original.latest_comment // Fallback if no note exists
          ),
        }
    ],
    [editRowId, editRowData]
  );

  const table = useReactTable({
    data,
    columns,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    manualFilters: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    pageCount: Math.ceil(data.length / pageSize),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
    state: {
      globalFilter,
      columnFilters,
      pagination: {
        pageSize,
        pageIndex: 0, // Initial page index
      },
    },
  });

  const getStatusLabel = (value) => {
    const status = referralStatus.find((item) => item.value === value);
    return status ? status.label : value; // Fallback to value if no match is found
  };

  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
    setPageIndex(0); // Reset to the first page when filters change
  };

  // Handle pagination changes
  const handlePageClick = (page) => setPageIndex(page);

  const handlePreviousPage = () => {
    if (pageIndex > 0) setPageIndex((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (pageIndex < totalPages - 1) setPageIndex((prev) => prev + 1);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setPageIndex(0);
  };

  // Pagination Range Logic
  const getPaginationRange = () => {
    const visiblePages = 5; // Show 5 page buttons at most
    const pages = [];

    // Add first page
    if (pageIndex > 2) {
      pages.push(0);
      if (pageIndex > 3) pages.push("...");
    }

    // Add pages around the current page
    for (
      let i = Math.max(pageIndex - 2, 0);
      i <= Math.min(pageIndex + 2, totalPages - 1);
      i++
    ) {
      pages.push(i);
    }

    // Add last page
    if (pageIndex < totalPages - 3) {
      if (pageIndex < totalPages - 4) pages.push("...");
      pages.push(totalPages - 1);
    }

    return pages;
  };

  const paginationRange = getPaginationRange();


  const handleAddNewReferral = () => {
    setEditRowData({ buyer_id: "", agent_user_id: "", property_id: "" }); // Reset data
    setEditRowId(null);
    setIsModalOpen(true);
  };
  // Edit Handlers
  const handleEdit = (rowData) => {
    setEditRowId(rowData.referral_id);
    setEditRowData({
      ...rowData,
      latest_comment: "",
    });
    setFocusedField(null);
  };

  const handleCancel = () => {
    setEditRowId(null);
    setEditRowData({});
    setFocusedField(null);
  };

  const handleInfoClick = async (userId) => {
    setSelectedUserId(userId); 
    setIsInfoModalOpen(true); 
  };

  const handleCloseModal = () => {
    setIsInfoModalOpen(false); // Close the modal
    setSelectedUserId(null); // Reset the userId
  };

  const handleSave = async () => {
    const errors = {};
    let buyer_user_id = '';
    let agent_user_id = '';
    // Validate Buyer/Seller Email
    if (!editRowData.buyer_id) {
      errors.buyer_id = "Buyer Email is required.";
    } else {
      const buyerResponse = await checkEmailExistence(editRowData.buyer_id);
      if (!buyerResponse.exists) {
        errors.buyer_id = "This email does not exist.";
      } else if (!["buyer", "seller"].includes(buyerResponse.role)) {
        errors.buyer_id = "Email must belong to a buyer.";
      }
       buyer_user_id = buyerResponse.userid;
    }
  
    // Validate Agent Email
    if (!editRowData.agent_user_id) {
      errors.agent_user_id = "Agent/Builder Email is required.";
    } else {
      const agentResponse = await checkEmailExistence(editRowData.agent_user_id);
      if (!agentResponse.exists) {
        errors.agent_user_id = "This email does not exist.";
      } else if (!["selleragent", "builder"].includes(agentResponse.role)) {
        errors.agent_user_id = "Email must belong to an agent or builder.";
      }
      agent_user_id = agentResponse.userid;
    }
  
    // Validate Property ID
    if (!editRowData.property_id) {
      errors.property_id = "Property ID is required.";
    }
  
    setValidationErrors(errors);
  
    if (Object.keys(errors).length === 0) {
      try {
        const payload = {
          propertyId: editRowData.property_id,
          buyerEmail: editRowData.buyer_id,
          agentEmail: editRowData.agent_user_id,
          buyerId: buyer_user_id,
          agentUserId: agent_user_id,
          userType: "agent",
        };
  
        const response = await axiosInstance.post(`/referral/invite-agent`, payload);
  
        toast({
          title: t('assignment-successmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        setIsModalOpen(false);
        setFocusedField(null);
        fetchData();
      } catch (error) {
        toast({
          title: 'Error',
            description: t('assignment-failuremessage'),
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
        });
      }
    }
  };

  const handleUpdateRow = async (referral_id) => {
    let errors = [];
  let agent_user_id = '';

  // Validate Agent Email
  if (!editRowData.agent_email) {
    errors.push("Agent Email is required.");
  } else {
    const agentResponse = await checkEmailExistence(editRowData.agent_email);
    if (!agentResponse.exists) {
      errors.push("This email does not exist.");
    } else if (!["selleragent", "builder"].includes(agentResponse.role)) {
      errors.push("Agent Email must belong to a 'selleragent' or 'builder'.");
    } else {
      agent_user_id = agentResponse.userid; // Assign valid agent ID
    }
  }

  // Validate Referral Status
  if (!editRowData.referral_status) {
    errors.push("Referral Status is required.");
  }

  if (errors.length > 0) {
    Swal.fire({
      icon: "error",
      title: "Validation Errors",
      html: errors.map((error) => `<div>${error}</div>`).join(""), // Format errors as HTML
      confirmButtonText: "OK",
    });
    return; // Stop execution if validation fails
  }

      try {
        const payload = {
          agent_email: editRowData.agent_email,
          agent_user_id : agent_user_id,
         status: editRowData.referral_status,
         note:  editRowData.latest_comment,
         // userType: "agent",
        };
  
        const response = await axiosInstance.put(`/referral/update-status/${referral_id}`, payload);
  
        toast({
          title: t('assignment-successmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        setIsModalOpen(false);
        setEditRowId(null);
        setFocusedField(null);
        fetchData();
      } catch (error) {
        toast({
          title: 'Error',
            description: t('assignment-failuremessage'),
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
        });
      }
  };
  
  
  const checkEmailExistence = async (email) => {
    try {
      const response = await axiosInstance.get(`/users/getUserByEmail?email=${email}`);
  
      if (response.data && response.data.userId) {
        return {
          exists: true, // Email exists if userId is present
          userid : response.data.userId,
          role: response.data.roles || null, // Extract role or default to null
        };
      }
  
      // Default return if userId is not present
      return { exists: false, role: null };
    } catch (error) {
      console.error("Error checking email existence:", error);
      return { exists: false, role: null }; // Return default on error
    }
  };
  

  const handleDelete = (id) => {
    setData(data.filter((row) => row.id !== id));
  };

  const handleHistory = async (id) => {
    try {
      // Fetch referral history based on ID (replace with your actual API call)
      const response = await axiosInstance.get(`/referral/get-events/${id}`);
      console.log("responsedata", response );
      setHistoryData(response.data.data || []); // Assuming the API returns { history: [...] }
      setIsHistoryModalOpen(true);
    } catch (error) {
      console.error("Failed to fetch referral history:", error);
    }
  };

  return (
    <div className="bg-primary  font-linksans">
    <div className="w-full px-5 py-12">
    <h3 className="text-2xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-4xl font-bold text-textColor pb-10">
     Referrals
        </h3>

        <div className="flex justify-end mb-4">
          <Button onClick={() => setRefreshKey((prev) => prev + 1)}  colorScheme="blue" mr={2}>
              Refresh
            </Button>
            <Button onClick={handleAddNewReferral} colorScheme="blue">
              Add New Referrals
            </Button>
          </div>
     
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="overflow-x-auto">
          <table
             className="w-full border border-gray-300 bg-gray-50"
             style={{
               borderCollapse: "collapse",
             }}
          >
            <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="border-b bg-slate-300 border-gray-300 text-center p-2"
                style={{
                  minWidth: "150px", // Adjust this width as needed for better responsiveness
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}

                 {/* Column Filters */}
                 {header.column.getCanFilter() ? (
                    <div  className="mt-1">
                      {flexRender(
                        header.column.columnDef.Filter,
                        header.getContext()
                      )}
                    </div>
                  ) : null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
      {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                   className="border-b border-gray-300 p-2 text-center"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={table.getAllColumns().length} style={{ textAlign: 'center', padding: '16px', verticalAlign: 'middle'}}>
                No data available.
              </td>
            </tr>
          )}

      </tbody>
          </table>

          <ReferralHistoryModal
            isOpen={isHistoryModalOpen}
            onClose={() => setIsHistoryModalOpen(false)}
            historyData={historyData}
          />

          {/* Profile Modal */}
          {selectedUserId && (
            <ProfileModal
              userId={selectedUserId}
              isOpen={isInfoModalOpen}
              onClose={handleCloseModal}
            />
          )}

          {/* Modal for adding referrals */}
      {/* Modal for adding/editing referrals */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="2xl" isCentered>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Add Referrals</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <Box mb={4}>
        <Input
          placeholder="Buyer Email"
          value={editRowData.buyer_id || ""}
          onChange={(e) =>
            setEditRowData((prev) => ({
              ...prev,
              buyer_id: e.target.value,
            }))
          }
        />
        {validationErrors.buyer_id && (
          <Text color="red.500" fontSize="sm">
            {validationErrors.buyer_id}
          </Text>
        )}
      </Box>
      <Box mb={4}>
        <Input
          placeholder="Agent/Builder Email"
          value={editRowData.agent_user_id || ""}
          onChange={(e) =>
            setEditRowData((prev) => ({
              ...prev,
              agent_user_id: e.target.value,
            }))
          }
        />
        {validationErrors.agent_user_id && (
          <Text color="red.500" fontSize="sm">
            {validationErrors.agent_user_id}
          </Text>
        )}
      </Box>
      <Box mb={4}>
        <Input
          placeholder="Property ID"
          value={editRowData.property_id || ""}
          onChange={(e) =>
            setEditRowData((prev) => ({
              ...prev,
              property_id: e.target.value,
            }))
          }
        />
        {validationErrors.property_id && (
          <Text color="red.500" fontSize="sm">
            {validationErrors.property_id}
          </Text>
        )}
      </Box>
    </ModalBody>
    <ModalFooter>
      <Button colorScheme="blue" onClick={handleSave} mr={3}>
        Save
      </Button>
      <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
        Cancel
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>


          {/* Pagination Controls */}
              
      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        <Button
          onClick={handlePreviousPage}
          disabled={pageIndex === 0}
          style={{ marginRight: "10px" }}
        >
           <FaChevronLeft />
        </Button>

        {paginationRange.map((page, idx) =>
          page === "..." ? (
            <span key={idx} style={{ margin: "0 5px" }}>
              ...
            </span>
          ) : (
            <Button
              key={page}
              onClick={() => handlePageClick(page)}
              style={{
                marginRight: "5px",
                backgroundColor: pageIndex === page ? "blue" : "gray",
                color: pageIndex === page ? "gray" : "black",
              }}
            >
              {page + 1}
            </Button>
          )
        )}

        <Button
          onClick={handleNextPage}
          disabled={pageIndex >= totalPages - 1}
          style={{ marginLeft: "10px" }}
        >
         <FaChevronRight />
        </Button>
      </Box>
      {/* Page Size Selector */}
      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        <Select
          value={pageSize}
          onChange={handlePageSizeChange}
          width="auto"
          style={{ marginLeft: "10px" }}
        >
          {[10, 20, 50, 100, 500].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </Select>
      </Box>

          {/* <div style={{ marginTop: "10px" }}>
            Page {pageIndex + 1} of {totalPages} ({data.length} records on this
            page)
          </div> */}
        </div>
      )}
    </div>
    </div>
  );
};

export default AssignAgent;
