import { useProducts } from "../compare/ProductsProvider";
import React, { useState, useEffect} from 'react';
//import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';
import GroupMenu from '../user/GroupMenu';
import CommonButton from "../../components/compare/CommonButton";
import PriceDisplay from "../../pages/DetailsPage/PriceDisplay";
import {Box, Button, Text, Flex, Stack, useDisclosure} from "@chakra-ui/react";
import ModalComponent from '../ModalComponent';
import PhotoModal from '../user/PhotoModal';
import ShareGroupNames from '../user/ShareGroupNames';
import { propTypeLabels} from '../../constants';
import {formatAddress} from '../../utils/format.js'

const handleMarkerClick = (props) => {  
    if (props.hit.data.propertyInfo && props.hit.data.propertyInfo.address.label) {
      window.open(`https://www.google.com/maps/place/${encodeURIComponent(props.hit.data.propertyInfo.address.label)}`, '_blank');
    }
  };

const PropertyCardList = (props) => {
    const { data, main = {}, community = {}, extraData={}} = props.hit;
    const {communityName} = community;
    const {
      propertyInfo = {},
     // lotInfo = {},
      mlsListingPrice = 'N/A',
      propertyType = 'N/A',
    } = data;
  
    const {
      address = {},
      bedrooms = 'N/A',
      bathrooms = 'N/A',
      buildingSquareFeet = 'N/A',
    } = propertyInfo;
    
    //const { lotSquareFeet = 'N/A' } = lotInfo;
    const publicId = main.publicId || 'N/A'; 
    const communityId = main.communityPublicID;
    const city = propertyInfo.address.city;
    const state = propertyInfo.address.state;
    
    //const [isPhotoOpen, setIsPhotoOpen] = useState(false);
    
    const { selectedProducts,addProductToSelection, removeProductFromSelection, viewProducts,addViewProductToSelection} = useProducts();
    const isProductSelected = selectedProducts  && selectedProducts.includes(publicId);
  
    //const isLoveitSelected = lovedProducts &&  lovedProducts.includes(publicId);
    const isviewSelected = viewProducts &&  viewProducts.includes(publicId);
  
    const { isOpen, onOpen, onClose } = useDisclosure();
//    const { ismenu=isOpen, menOpen = onOpen,closeMenu= onClose } = useDisclosure();
    const [modalHeader, setModalHeader] = useState('');
    const [modalBody, setModalBody] = useState(null);
    //const [menuOpen, setMenuOpen] = useState(false);
    const [userId, setUserId] = useState(null);
    // const fetchImages = async (propertyId) => {
    //   try {
    //     const response = await axiosInstanceES.get(`/properties/${propertyId}/photos`);
    //     //setImages(response.data);
    //     onOpen();
    //   } catch (error) {
    //     console.error('Error fetching images:', error);
    //   }
    // };
  
    useEffect(() => {
      // Check if user ID exists in local storage
      const storedUserId = localStorage.getItem('userid');
      if (storedUserId) {
        setUserId(storedUserId);
      }
    }, []);
  
    let storedLanguage = localStorage.getItem('i18nextLng');
  
    if (!storedLanguage || storedLanguage.trim() === '') {
        storedLanguage = 'en';
    }
  
     const handleCompare = productId => {
      if (isProductSelected) {
        removeProductFromSelection(productId);
      } else {
        addProductToSelection(productId);
      }
     }
  
     const handleViewList = productId =>{
       if(isviewSelected){
        addViewProductToSelection(productId);
       } else{
        addViewProductToSelection(productId);
        }
        let url;
        if (productId && productId !== 'N/A') {
          url = `/home/${storedLanguage}/${state}/${city}/${productId}/${props.hit.community?.communityName ? encodeURIComponent(props.hit.community.communityName) : ''}/${props.hit.data.propertyType ? encodeURIComponent(props.hit.data.propertyType) : ''}/`;
        } else {
           url =`/community/${storedLanguage}/${state}/${city}/${communityId}`;
        }
        window.open(url, '_blank');
     }
  
    const handleCloseModal = () => {
      setModalHeader('');
      setModalBody(null);
      onClose();
    };
  
    const handleOpenPhotoModal = () => {
      setModalHeader('Property Images');
      if (publicId && publicId !== 'N/A') {
      setModalBody(<PhotoModal isOpen={true} onClose={onClose} propertyId={publicId}/>);
      } else {
        setModalBody(<PhotoModal isOpen={true} onClose={onClose} propertyId={communityId}/>);  
      }
      onOpen();
    };
    
return (
    <>
  <Flex justifyContent="center" alignItems="flex-start" className="w-full">
  <Stack
    direction={{ base: "column", lg: "row" }}
    className="gap-10 w-full px-4 lg:px-10"
  >
    {/* Left Section */}
    <Box className="pt-4 w-full lg:w-1/2">
      <Box className="order-2 lg:order-1">
        {/* Price and details */}
        <Box className="flex items-center gap-2 pb-2 border-b border-dashed border-borderColor">
          {mlsListingPrice && mlsListingPrice !== "N/A" ? (
            <>
              <Text className="text-2xl lg:text-3xl font-bold text-paragraphColor">
                <PriceDisplay price={mlsListingPrice} />
              </Text>
              {extraData.modelName && (
                <Text
                  className="font-medium text-sm lg:text-base"
                  style={{ padding: "10px 0px" }}
                >
                  {extraData.modelName}
                </Text>
              )}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 0C3.5813 0 0 3.5813 0 8C0 12.4187 3.5813 16 8 16C12.4187 16 16 12.4187 16 8C16 3.5813 12.4187 0 8 0ZM8 14.5455C4.3908 14.5455 1.4546 11.6092 1.4546 8C1.4546 4.3908 4.3908 1.4545 8 1.4545C11.6092 1.4545 14.5454 4.3909 14.5454 8C14.5454 11.6091 11.6092 14.5455 8 14.5455ZM7.102 7.3171H8.898V12.859H7.102V7.3171ZM7.102 3.7906H8.898V5.5866H7.102V3.7906Z"
                  fill="#C1C4CC"
                />
              </svg>
            </>
          ) : (
            communityName && (
              <Text
                className="font-medium text-sm lg:text-base"
                style={{ padding: "10px 0px" }}
              >
                {communityName}
              </Text>
            )
          )}
        </Box>

        {/* Property Type */}
        {propertyType && propertyType !== "N/A" && (
          <Text className="text-paragraphColor text-sm lg:text-base pb-3 border-b border-dashed border-borderColor">
            {propTypeLabels[propertyType]}
          </Text>
        )}

        {/* Address */}
        {address.label && address.label !== "N/A" && (
          <Text className="text-paragraphColor text-sm lg:text-base pb-3 border-b border-dashed border-borderColor">
            {formatAddress(address.label)}
          </Text>
        )}

        {/* Property details */}
        {publicId && publicId !== "N/A" && (
          <Box className="py-3 flex flex-wrap gap-1.5 border-b border-dashed">
            {bedrooms && bedrooms !== "N/A" && (
              <CommonButton
                icon="bed"
                value={bedrooms}
                name="Bedrooms"
                isLink={false}
                color="green"
              />
            )}
            {bathrooms && bathrooms !== "N/A" && (
              <CommonButton
                icon="bath"
                value={bathrooms}
                name="Bathrooms"
                isLink={false}
                color="blue"
              />
            )}
            {buildingSquareFeet && buildingSquareFeet !== "N/A" && (
              <CommonButton
                icon="length"
                value={buildingSquareFeet}
                name="Sq Ft"
                isLink={false}
                color="purple"
              />
            )}
          </Box>
        )}

        {/* Community Details */}
        {publicId &&
          publicId !== "N/A" &&
          communityName &&
          communityName !== "N/A" && (
            <Box className="py-3 border-b border-dashed">
              <CommonButton
                icon="property"
                value={null}
                name={communityName}
                isLink={false}
                color="pink"
              />
            </Box>
          )}

        {/* Share Group */}
        {userId && publicId && publicId !== "N/A" && (
          <Box className="py-3 border-b border-dashed">
            <ShareGroupNames propertyId={publicId} />
          </Box>
        )}
      </Box>
    </Box>

    {/* Right Section */}
    <Box className="pt-0 w-full lg:w-1/2 mt-10">
      {publicId && publicId !== "N/A" && (
        <Box className="relative flex flex-wrap gap-1.5 py-3 border-b border-dashed">
          {userId && publicId && publicId !== "N/A" ? (
            <GroupMenu propertyId={publicId} />
          ) : (
            publicId &&
            publicId !== "N/A" && (
              <CommonButton
                icon="compare"
                name={isProductSelected ? "Remove" : "Shortlist"}
                color={isProductSelected ? "red" : "blue"}
                disablePointer={true}
                isLink={false}
                onClick={() => handleCompare(props.hit.main.publicId)}
              />
            )
          )}
          <CommonButton
            icon="photo"
            isLink={false}
            name="Photos"
            onClick={handleOpenPhotoModal}
            disablePointer={true}
            color="gray"
          />
          <CommonButton
            icon="map"
            isLink={false}
            name="View Map"
            onClick={() => handleMarkerClick(props)}
            disablePointer={true}
            color="gray"
          />
          {isOpen && (
            <ModalComponent
              isOpen={isOpen}
              onClose={handleCloseModal}
              modalHeader={modalHeader}
              modalBody={modalBody}
            />
          )}
        </Box>
      )}

      {/* View Details Button */}
      <Box className="mt-4">
        <button
          onClick={() => handleViewList(publicId)}
          className="text-textColor text-sm md:text-base font-semibold py-2.5 lg:py-3 bg-primary rounded-xl w-full"
        >
          View Details
        </button>
      </Box>
    </Box>
  </Stack>
</Flex>
 
  </>)
  }
  export default  PropertyCardList;