import React, { useState, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import {useToast} from '@chakra-ui/react';
import { useAuth } from '../user/AuthProvider';
import axiosInstance,{updateToken} from '../../utils/axio-instance';
import { useNavigate } from "react-router-dom";

const TicketCreation = (props) => {

  const { propertyId} = props;

  const [captchaToken, setCaptchaToken] = useState('');

  const profileId = localStorage.getItem('userid');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const toast = useToast(); 
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [profileInfo, setProfileInfo] = useState(null);


  const [formData, setFormData] = useState({
    propertyId: propertyId || "",
    userId:profileId || null,
    firstName: "",
    lastName: "",
    email: "",
    phone:"",
    contactMode: "",
    description: "",
    termsAccepted: false,
  });


  useEffect(() => {
    const storedUserId = localStorage.getItem("userid");
    if (storedUserId) {
      loadUser(storedUserId);
    }
  }, []);

  useEffect(() => {
    if (profileInfo) {
      setFormData((prev) => ({
        ...prev,
        firstName: profileInfo.first_name || "",
        lastName: profileInfo.last_name || "",
        email: profileInfo.email || "",
        phone: profileInfo.mobile || "",
      }));
    }
  }, [profileInfo]);

  const loadUser = (userId) => {
    updateToken(localStorage.getItem("token"));
    axiosInstance
      .get(`/users/${userId}/profile/`)
      .then((response) => {
        const storedProfileInfo = response.data;
        setProfileInfo(storedProfileInfo);
        setUser({ ...user, ...storedProfileInfo });
      })
      .catch((error) => {
        console.error("Profile API error:", error);
      });
  };


   const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };


  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let tempErrors = {};
    let valid = true;

    if (!formData.firstName.trim()) {
      tempErrors.firstName = "First Name is required";
      valid = false;
    }

    if (!formData.lastName.trim()) {
      tempErrors.lastName = "Last Name is required";
      valid = false;
    }

    if (!formData.email.trim()) {
      tempErrors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email address is invalid";
      valid = false;
    }

    if (!formData.termsAccepted) {
      tempErrors.termsAccepted = "You must accept the terms and conditions";
      valid = false;
    }

    setErrors(tempErrors);
    return valid;
  };


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
        setFormData({ ...formData, [name]: checked ? value : "" });
      } else {
        setFormData({ ...formData, [name]: value });
      }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = { ...formData, propertyId, captchaToken };


    if (validateForm() && captchaToken) {
        axiosInstance
          .post("/tickets/create", payload)
          .then((response) => {
            toast({
              title: "Success!",
              description: "Ticket submitted successfully.",
              status: "success",
              duration: 3000,
              position: 'top-right',
              isClosable: true,
            });
    
            // Clear the form after successful submission
            setFormData({
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              contactMode: false,
              description: "",
              termsAccepted: false,
            });
            setErrors({});
    
            // Stay on the same page if the user is logged in
            if (!profileId) {
              navigate("/sign-up/"); // Redirect to signup if the user is not logged in
            }
          })
          .catch((error) => {
            console.error("Error submitting ticket:", error);
    
            // Show error message in a toaster
            toast({
              title: "Error",
              description: "Failed to submit the ticket. Please try again.",
              status: "error",
              duration: 3000,
              position: 'top-right',
              isClosable: true,
            });

          });
      } else if (!captchaToken) {
        setErrors((prev) => ({
          ...prev,
          recaptcha: "Please complete the reCAPTCHA",
        }));
      }
  };


  return (
    <div className="bg-gray-100 font-linksans flex justify-center items-center p-4">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full"
      >

        <input value={propertyId} type="hidden" name="propertyId"/>
        <input value={profileId} type="hidden" name="userId"/>
        <div className="mb-4">
          <label className="block font-medium text-gray-700 mb-1">
            First Name <span className="text-red-500">*</span>
          </label>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          {errors.firstName && (
            <p className="text-red-500 text-sm">{errors.firstName}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block font-medium text-gray-700 mb-1">
            Last Name <span className="text-red-500">*</span>
          </label>
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          {errors.lastName && (
            <p className="text-red-500 text-sm">{errors.lastName}</p>
          )}
        </div>
        {/* Email */}
        <div className="mb-4">
          <label className="block font-medium text-gray-700 mb-1">
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            name="email"
            placeholder="example@example.com"
            value={formData.email}
            onChange={handleChange}
            className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          {errors.email && (
            <p className="text-red-500 text-sm">{errors.email}</p>
          )}
        </div>
        {/* Phone */}
        <div className="mb-4">
          <label className="block font-medium text-gray-700 mb-1">
          Mobile Number <span className="text-red-500">*</span>
          </label>
          <input
              type="tel"
              name="phone"
              placeholder="(000) 000-0000"
              value={formData.phone}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
        </div>
        {/* Contact Method */}
        <div className="mb-4">
          <label className="block font-medium text-gray-700 mb-1">
            Preferred Contact Method
          </label>
          <div className="flex items-center gap-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="contactMode"
                value="Email"
                onChange={handleChange}
                className="h-4 w-4 border-gray-300 rounded"
              />
              <span>Email</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="contactMode"
                value="Text Message"
                onChange={handleChange}
                className="h-4 w-4 border-gray-300 rounded"
              />
              <span>Text Message</span>
            </label>
          </div>
        </div>
        {/* Thoughts */}
        <div className="mb-4">
          <label className="block font-medium text-gray-700 mb-1">
            Add your thoughts
          </label>
          <textarea
            name="description"
            rows="4"
            value={formData.description}
            onChange={handleChange}
            className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Type your message here..."
          ></textarea>
        </div>
        {/* Terms */}
        <div className="mb-6">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="termsAccepted"
              checked={formData.termsAccepted}
              onChange={handleChange}
              className="h-4 w-4 border-gray-300 rounded"
            />
            <span className="text-gray-600 text-sm">
              Agree {" "}
              <a
                href="/terms/"
                className="text-black-500 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </a>{" "}
              and{" "}
              <a
                href="/privacy/"
                className="text-black-500 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>
            </span>
          </label>
          {errors.termsAccepted && (
            <p className="text-red-500 text-sm">{errors.termsAccepted}</p>
          )}
        </div>
        <div className="mb-6 flex justify-center">
            <div className="w-full max-w-xs sm:max-w-md">
                <div className="w-[304px] mx-auto">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                    onChange={(token) => setCaptchaToken(token)}
                    onExpired={() => setCaptchaToken("")}
                    size="normal"
                />
                </div>
                {errors.recaptcha && <p className="error text-red-500 text-sm">{errors.recaptcha}</p>}
            </div>
            </div>
        {/* Submit */}
        <div>
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default TicketCreation;
