import React, { useState, useEffect, useRef} from 'react';
import { InstantSearch, Hits,RefinementList, Pagination, Stats,  RangeInput, 
  SortBy,Configure, CurrentRefinements } from 'react-instantsearch-dom';
import {Box, useColorModeValue, Text, Flex, Divider, SimpleGrid} from "@chakra-ui/react";
import Client from '@searchkit/instantsearch-client';
import Searchkit from "searchkit";
import SquareBoxRatingMenu from '../../components/search/SquareBoxRatingMenu';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import mapping from './mapping';
import {
  GoogleMapsLoader,
  GeoSearch,
  Marker,
} from 'react-instantsearch-dom-maps';
import { usePageViews } from '../../tracking/PageViewProvider';
import { propTypeLabels} from '../../constants';
import CustomAutocomplete from './CustomAutocomplete';
import PropertyCard from './PropertyCard';
import PropertyCardList from './PropertyCardList';
import {formatAddress, formatPrice} from '../../utils/format.js';
import { FaFilter, FaMapMarkedAlt, FaTimesCircle } from "react-icons/fa";
import {states} from '../../data/us_states';

const CustomStats = () => (
  <Stats
  translations={{
    stats: (n, ms, nSortedHits, areHitsSorted) =>
      n >= 10000
        ? `10,000+ results found`
        : `${n} results found`
  }}
/>
);

const searchkitClient = new Searchkit({
  connection: {
    host: `${process.env.REACT_APP_ELASTIC_PROXY_URL}`,
    index: `${process.env.REACT_APP_ELASTIC_INDEX_NAME}`, 
  },
  search_settings: {
    highlight_attributes: [],
    search_attributes: ['data.propertyInfo.address.label', 'community.communityName', 'extraData.modelName', 'allFields'],
    result_attributes: ['data.id', 'data.mlsListingPrice', 'data.propertyInfo.address.label', 
        'data.mlsHistory.type', 'data.propertyInfo.bedrooms', 'data.propertyInfo.bathrooms', 
        'data.propertyInfo.buildingSquareFeet', 'data.propertyInfo.latitude', 
        'data.propertyInfo.longitude', ' data.lotInfo.lotSquareFeet', 'data.propertyType', 
        'data.propertyInfo.yearBuilt', 'data.mlsStatus', 'data.propertyInfo.address.county', 
        'data.propertyInfo.address.city',
        'main.publicId', 'data.propertyInfo.address.state', 
        'community.communityName', 'builder.builderName', 'main.communityPublicID', 'extraData.modelName'],
    facet_attributes: [
      { field: 'data.propertyType', attribute: 'data.propertyType', type: 'string'},
      { field: 'data.mlsListingPrice', attribute: 'data.mlsListingPrice', type: 'numeric' },
      { field: 'data.propertyInfo.bedrooms', attribute: 'data.propertyInfo.bedrooms', type: 'numeric', qualitative: true }, // Add bedrooms attribute
      { field: 'data.propertyInfo.bathrooms', attribute: 'data.propertyInfo.bathrooms', type: 'numeric' }, 
      { field: 'data.propertyInfo.address.county', attribute: 'data.propertyInfo.address.county', type: 'string'},
      { field: 'data.propertyInfo.address.city', attribute: 'data.propertyInfo.address.city', type: 'string'},
      { field: 'data.propertyInfo.address.state', attribute: 'data.propertyInfo.address.state', type: 'string'},
      { field: 'builder.builderName', attribute: 'builder.builderName', type: 'string'},
      { field: 'community.communityName', attribute: 'community.communityName', type: 'string'}
    ],
    sorting: {
      default: {
        field: '_score',
        order: 'asc'
      },
      price_asc: {
        field: 'data.mlsListingPrice',
        order: 'asc'
      },
      price_desc: {
        field: 'data.mlsListingPrice',
        order: 'desc'
      },
      sqft_asc: {
        field: 'data.propertyInfo.livingSquareFeet',
        order: 'asc'
      },
      sqft_desc: {
        field: 'data.propertyInfo.livingSquareFeet',
        order: 'desc'
      }
    },
    geo_attribute: 'location',
    debug: false
  }
})

//console.log("searchkitClientq2w212",searchkitClient);
const searchClient = Client(searchkitClient);

const searchStateToUrl = (searchState) => {
  const { query, page, boundingBox, refinementList } = searchState;
  let queryString = `?query=${query}&page=${page}&boundingBox=${boundingBox?JSON.stringify(boundingBox):''}`;
  if (refinementList) {
    // Iterate over each refinement list
    Object.keys(refinementList).forEach((attribute) => {
      const refinementValue = refinementList[attribute];
      if (Array.isArray(refinementValue) && refinementValue.length > 0) {
        // If the refinement value is an array and not empty
        const mappedAttribute = mapping.result_attributes.find(item => item.field === attribute);
        if (mappedAttribute) {
          const fieldName = mappedAttribute.name;
          const refinedValues = refinementValue.map(value => mapping.result_attributes.find(item => item.field === fieldName && item.name === value)?.field || value);
          queryString += `&${fieldName}=${encodeURIComponent(refinedValues.join(','))}`;
        }
      } else if (typeof refinementValue === 'string' && refinementValue.trim() !== '') {
        // If the refinement value is a string and not empty
        const mappedAttribute = mapping.result_attributes.find(item => item.attribute === attribute);
        if (mappedAttribute) {
          const fieldName = mappedAttribute.name;
          const mappedValue = mapping.result_attributes.find(item => item.field === fieldName && item.name === refinementValue)?.field || refinementValue;
          queryString += `&${attribute}=${encodeURIComponent(mappedValue)}`;
        }
      }
    });
  }
  return queryString;
}



const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
const endpoint = process.env.REACT_APP_GOOGLE_MAP_ENDPOINT;
//const customIconUrl =  process.env.PUBLIC_URL + '/img/Red-dot.svg';

const createCustomIcon = (price,  isHovered) => {
  const fillColor = isHovered ? '#1d5067' : '#017714';
  return `
    <svg class="gmarker" xmlns="http://www.w3.org/2000/svg" width="80" height="50">
      <!-- Rectangle for the label background -->
      <rect x="10" y="5" rx="5" ry="5" width="40" height="20" fill="${fillColor}" />
      
      <!-- Text for the price -->
      <text x="30" y="20" text-anchor="middle" fill="white" font-size="11" font-family="arial,Roboto">
        ${price}
      </text>

      <!-- Triangle for the pointer -->
      <polygon points="20,23 40,25 30,35" fill="${fillColor}" />
    </svg>
  `;
};

const createCustomMarker = (price, isHovered = false) => {
  const customIconSvg = createCustomIcon(price, isHovered);
  return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(customIconSvg)}`;
};

export default function Web(props) {
  const [selectedHit, setSelectedHit] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [doRefresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const popupRef = useRef(null);

  const [isMapView, setIsMapView] = useState(true);

  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsFilterVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const mapRef = useRef(null);

  const scrollToMap = () => {
    if (mapRef.current) {
      mapRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(()=>{
    document.title = "Search | KnockTheDoor.com"
  }, []);

  const handleMarkerClickWindow = (hit, map, marker, google) => {
    // Close the existing InfoWindow if any
    if (!google) {
      console.error('Google is not available');
      return;
    }

    if (infoWindow) {
      infoWindow.close();
    }
    
    const newInfoWindow = new google.maps.InfoWindow({
      content: `
        <div style="font-family: Arial, sans-serif; padding: 10px;">
          ${
            hit.main.publicId && hit.main.publicId !== 'N/A'
              ? `
                <!-- First Row: Model Name and Price -->
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <p style="font-weight: bold; font-size: 16px; margin: 0;">
                    ${hit.extraData?.modelName || 'N/A'}
                  </p>
                  &nbsp;
                  <p style="font-weight: medium; font-size: 16px; margin: 0;">
                    ${hit.data.mlsListingPrice === -1 || hit.data.mlsListingPrice === undefined || hit.data.mlsListingPrice === 'N/A' ? 'Model' : `$${hit.data.mlsListingPrice}`}
                  </p>
                </div>
        
                <!-- Second Row: Bedrooms and Bathrooms -->
                <div style="margin-top: 8px;">
                  <p style="font-size: 14px; margin: 0;">
                    ${hit.data.propertyInfo.bedrooms !== 'N/A' ? `${hit.data.propertyInfo.bedrooms} Beds` : 'N/A'} 
                    |
                    ${hit.data.propertyInfo.bathrooms !== 'N/A' ? `${hit.data.propertyInfo.bathrooms} Baths` : 'N/A'}
                  </p>
                </div>
        
                <!-- More Button -->
                <div style="margin-top: 12px; display: flex; justify-content: center;">
                  <button 
                    style="
                      background-color: #E6EBEF;
                      color: #576269;
                      padding: 6px 12px;
                      border: none;
                      border-radius: 4px;
                      font-size: 14px;
                      cursor: pointer;
                    "
                    onclick="window.open('/home/en/${hit.data.propertyInfo.address.state}/${hit.data.propertyInfo.address.city}/${hit.main.publicId}', '_blank')">
                    View Details
                  </button>
                </div>
              `
              : `
                <!-- Show Address Label when Public ID is N/A or undefined -->
                <div style="text-align: center;">
                  <p style="font-size: 12px; margin: 0;">
                    ${hit.community.communityName || 'Model'}
                  </p>
                  <p style="font-size: 12px; margin: 0;">
                    ${formatAddress(hit.data.propertyInfo.address.label || '')}
                  </p>
                </div>
                 <!-- More Button -->
                <div style="margin-top: 12px; display: flex; justify-content: center;">
                  <button 
                    style="
                      background-color: #3182ce;
                      color: white;
                      padding: 6px 12px;
                      border: none;
                      border-radius: 4px;
                      font-size: 14px;
                      cursor: pointer;
                    "
                    onclick="window.open('/community/en/${hit.data.propertyInfo.address.state}/${hit.data.propertyInfo.address.city}/${hit.main.communityPublicID}', '_blank')">
                    View Details
                  </button>
                </div>
              `
          }
        </div>
      `,
    });
    
    // Open the InfoWindow
    newInfoWindow.open(map, marker);
    setInfoWindow(newInfoWindow); // Set the InfoWindow instance in the state
  };

  useEffect(() => {
    return () => {
      // Clean up the InfoWindow when the component unmounts
      if (infoWindow) {
        infoWindow.close();
      }
    };
  }, [infoWindow]);

  const { trackSearchEvent, searchCount,searchStateData,setSearchStateData } = usePageViews();
  //console.log('searchstaedata at beginning', searchStateData)
  const updateAfter =2000;
  const itemsData = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 }
  ];
  const urlToSearchState = (urlParams) => {
    const { query, page, boundingBox,refinementList, ...rest } = urlParams;

    const searchState = {
      query: query || '',
      page: parseInt(page) || 1,
      boundingBox: (boundingBox)?JSON.parse(boundingBox):null,
      refinementList: {}
    };
  
    Object.keys(rest).forEach((param) => {
    const decodedValue =decodeURIComponent(urlParams[param] || '');

      // Check if the parameter corresponds to a refinement list attribute
      const mappedAttribute = mapping.result_attributes.find(item => item.name === param);
      //console.log('mappedAttribute',mappedAttribute)
      if (mappedAttribute) {
        const attributeName = mappedAttribute.field;
       // console.log(decodedValue)
        const attributeValues = decodedValue.split(',');
        searchState.refinementList[attributeName] = attributeValues;
      } else {
        // If it's not a refinement list attribute, add it to the searchState directly
        searchState[param] = decodedValue;
      }
    });
  
    return searchState;
  }
  const [searchState, setSearchState] = useState(
    urlToSearchState(qs.parse(window.location.search.slice(1)))
  );

  const debouncedSetStateRef = useRef(null);

  useEffect(() => {
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handlePopState = ({ state: searchState }) => {
    setSearchState(searchState);
  };

  const onSearchStateChange = (searchState) => {
    // update the URL when there is a new search state.
    clearTimeout(debouncedSetStateRef.current);
    debouncedSetStateRef.current = setTimeout(() => {
      window.history.pushState(
        searchState,
        null,
        searchStateToUrl(searchState)
      );
    }, updateAfter);
    setRefresh(false);
    setSearchState((previousState) => {
      const hasQueryChanged =
        previousState.query !== searchState.query;

      return {
        ...previousState,
        ...searchState,
        boundingBox: !hasQueryChanged ? searchState.boundingBox : '',
      };
    });
  };

  const parameters = {};
  if (!searchState.boundingBox) {
    parameters.aroundLatLngViaIP = true;
    parameters.aroundRadius = 'all';
  }
  
  const propertyTypeLabels = (items) => {
    return items.map((item) => ({
      ...item,
      label: (propTypeLabels[item.label]?propTypeLabels[item.label]:item.label)
    }));
  };

  const stateLabels = (items) => {
    return items.map((item) => ({
      ...item,
      label: (states.filter(st=>st.abbreviation == item.label)[0]?.name || item.label)
    }));
  };
  
  const handleFilter = (autosearchObj)=>{
      if(autosearchObj){
        const newSearchParams = new URLSearchParams(window.location.search);
        newSearchParams.set('city', autosearchObj.city);
        newSearchParams.set('state', autosearchObj.state);
        let searchFils = searchState;
        searchFils['city'] = autosearchObj.city;
        searchFils['state'] = autosearchObj.state;
        setSearchState({...searchFils});
        setRefresh(true);
        window.location = '/search?'+newSearchParams.toString()
        // navigate({
        //   pathname: '/search', // Path to navigate
        //   search: newSearchParams.toString(),
        // });
      }
  }
  return (
      <div className="ais-InstantSearch bg-primary font-linksans">
      <InstantSearch
        searchClient={searchClient}
        searchState={searchState}
        indexName={process.env.REACT_APP_ELASTIC_INDEX_NAME} 
        onSearchStateChange={(searchState) => {
         // console.log('searchState',searchState)
          onSearchStateChange(searchState);
          setSearchStateData(searchState);
          trackSearchEvent(); // Call the function here
        }}
        refresh={doRefresh}
      >
     <Configure hitsPerPage={20} />

     <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap" className="gap-1">
     <div
          className="border border-gray-300 p-2 rounded flex items-center justify-center bg-white hover:border-gray-500 cursor-pointer"
          title="Filter"
          onClick={toggleFilter}
        >
          <FaFilter className="text-lg text-gray-500" />
      </div> 
     <Box flex={{ base: "1 1 100%", md: "6" }} className="w-full md:w-auto">
           {/* <CustomAutocomplete/> */}
           <CustomAutocomplete refine={handleFilter} />
      </Box>
      <div
        className="flex items-center gap-4 w-full md:w-auto md:justify-end mt-1 md:mt-0"
      >
        <SortBy
              defaultRefinement="default"
            items={[
              { value: ``, label: 'Relevance' },
              { value: `price_desc`, label: 'Price (High to Low)' },
              { value: `price_asc`, label: 'Price (Low to High)' },
              { value: `sqft_desc`, label: 'SQ FT (High to Low)' },
              { value: `sqft_asc`, label: 'SQ FT (Low to High)' }
            ]}
            className="flex-1 min-w-0 md:w-auto"
          />

        {/* Map Icon */}
        <div
          className="border border-gray-300 p-2 rounded flex items-center md:hidden justify-center bg-white hover:border-gray-500 cursor-pointer"
          title="Map"
          onClick={scrollToMap}
        >
          <FaMapMarkedAlt className="text-lg text-gray-500" />
        </div>
      </div>
    </Flex>
    <Flex className="search-3block-sec" wrap={'wrap'} width={'100%'}>
  
     <div
        className={`fixed inset-0 z-50 bg-gray-900 bg-opacity-50 ${
          isFilterVisible ? 'block' : 'hidden'
        }`}
        onClick={() => setIsFilterVisible(true)}
      >
        <div
          ref={popupRef}
          className="fixed top-0 left-0 h-full w-3/4 max-w-sm bg-white shadow-lg overflow-y-auto"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="p-4">
            <div className="flex justify-end p-4">
              <button
                onClick={() => setIsFilterVisible(false)}
                className="text-gray-600 text-2xl hover:text-gray-800"
                aria-label="Close"
              >
                <FaTimesCircle />
              </button>
            </div>

            {/* Filter Sections */}
            <div className="left-panel">
           <Text display="none"
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'}>
            Search By Address
            </Text>
          {/* <SearchBox className="filter-search" /> */}
          <Divider display="none" mt={'4'} borderColor={'#d9e2e8'}/>
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'1'}>
            Price
            </Text>
            <RangeInput attribute="data.mlsListingPrice" header="Range Input" />
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
         
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
             Search By Bedrooms
            </Text>
            <SquareBoxRatingMenu attribute="data.propertyInfo.bedrooms" items={itemsData}/> 
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
             Search By Bathrooms
            </Text>
            <SquareBoxRatingMenu attribute="data.propertyInfo.bathrooms" items={itemsData}/> 
            <Divider mt={'4'} borderColor={'#d9e2e8'}/>
            

          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
            Search By City
            </Text>
            <RefinementList 
              className="filter-search search-input" 
              attribute="data.propertyInfo.address.city" 
              searchable={true} 
              limit={5}
              showMore={true} 
            />
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
            Search By State
            </Text>
            <RefinementList 
              className="filter-search search-input" 
              attribute="data.propertyInfo.address.state" 
              searchable={true} 
              limit={5}
              showMore={true} 
              transformItems={stateLabels}
            />
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
            Search By House Type
            </Text>
            <RefinementList 
              className="filter-search search-input" 
              attribute="data.propertyType" 
              searchable={true} 
              transformItems={propertyTypeLabels}
            />
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
            Search By County
            </Text>
            <RefinementList 
              className="filter-search search-input" 
              attribute="data.propertyInfo.address.county" 
              searchable={true} 
              limit={5}
              showMore={true} 
            />
            <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
            Search By Community
            </Text>
            <RefinementList 
              className="filter-search search-input" 
              attribute="community.communityName"
              searchable={true}
              isMulti={true}
              />
           <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
            Search By Builder
            </Text>
            <RefinementList 
              className="filter-search search-input" 
              attribute="builder.builderName" 
              searchable={true} 
              limit={5}
              showMore={true} 
            />    
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
        </div>
          </div>
        </div>
      </div>


    {props.mapView?<>
      <Box className='search-list-sec'
       w={isFilterVisible ? { base: "100%", md: "50%" } : { base: "100%", md: "50%" }}
       pr={{ base: 0, md: 5 }}
       pl={isFilterVisible ? { base: 0, md: 5 } : 0}
      >
        <Hits hitComponent={PropertyCard}/>
      </Box>
      <Box w={isFilterVisible ? { base: "100%", md: "50%" } : { base: "100%", md: "50%" }} position="sticky"  top="0" overflow="hidden" ref={mapRef} height="700px">
        <div className="flex-auto w-full py-2">
              <CustomStats />
            </div>
            <div style={{ height: '78%', overflow: 'hidden' }}>
            <GoogleMapsLoader apiKey={apiKey} endpoint={endpoint}>
            {(google, map) => (
              <GeoSearch
                google={google}
                zoom={10}
                fullscreenControl={true}
                streetViewControl={true}
                zoomControl={true}
                mapTypeControl={true}
                mapTypeControlOptions={{ mapTypeIds: ['roadmap', 'satellite'] }}
              >

              
              {({ hits }) => (
                <>
                  {hits.map((hit) => {

                      const price = hit.data.mlsListingPrice;
                      const formattedPrice = formatPrice(price);
                      const isHovered = hoveredMarker === hit.objectID;
                      const customIconUrl = createCustomMarker(formattedPrice, isHovered);

              
                      return (
                      <Marker
                          key={hit.objectID}
                          hit={hit}
                          position={hit._geoloc}
                          icon={customIconUrl}
                          onClick={(event) =>
                          handleMarkerClickWindow(hit, map, event.marker, google)
                        }
                        onMouseOver={() => setHoveredMarker(hit.objectID)} 
                        onMouseOut={() => setHoveredMarker(null)} 
                      />
                    );
                    })}
                </>
              )}
            </GeoSearch>
          )}
          </GoogleMapsLoader>
          </div>
      </Box>
    </>:<>
        <Box className="search-list-view-sec" width={{ base: "100%", md: "100%" }}>
        <div className="flex-auto w-full py-2">
          <CustomStats />
        </div>
        <div className="w-full">
          <Hits hitComponent={PropertyCardList} />
        </div>
      </Box>
    </>}  
    <Pagination />
    </Flex>
    </InstantSearch>
  </div>);
}