import React, { useMemo, useState, useEffect } from "react";
import { useReactTable, getCoreRowModel, flexRender, getFilteredRowModel, createColumnHelper, getPaginationRowModel } from "@tanstack/react-table";
import axiosInstance from "../../utils/axio-instance";
import { Button, useToast, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Input, Text, Select, Table, Thead, Tbody, Tr, Th, Td, useDisclosure } from '@chakra-ui/react';
import { roles, StatusOptions} from '../../constants';
import { useTranslation } from 'react-i18next'; 
import Swal from "sweetalert2";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import DateDisplay, {formatDateHumanReadable} from '../../utils/DateDisplay';

const TicketManagement = () => {
  const apiUrl = "/tickets/list";

  // State management
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);
  const [editRowId, setEditRowId] = useState(null);
  const [editRowData, setEditRowData] = useState({});
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [focusedField, setFocusedField] = useState(null);

  
  const toast = useToast();  
  const { t} = useTranslation();
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [validationErrors, setValidationErrors] = React.useState({});

  const fetchData = async () => {
    //setLoading(true);
    try {
      const response = await axiosInstance.get(apiUrl, {
        params: {
          from: pageIndex*pageSize,
          size: pageSize,
          filters: columnFilters.reduce((acc, filter) => {
            acc[filter.id] = filter.value;
            return acc;
          }, {}),
        },
      });

      const { result: tickets, totalCount } = response.data;

      setData([...tickets]);
      setTotalPages(Math.ceil(totalCount / pageSize));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };
  // Fetch data from server
  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize, columnFilters]);


  const equalsFilterFn = (row, columnId, filterValue) => {
    const cellValue = row.getValue(columnId);
    return filterValue === "" || cellValue.includes(filterValue);
  };

  // Define columns
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "User Name",
        filterFn: "includesString",
        Filter: ({ column }) => (
          <input
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder="Filter by Name"
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
            autoFocus={focusedField === "name"} 
            onFocus={() => setFocusedField("name")}  
          />
        ),
      },
      { 
        accessorKey: "email",
         header: "Email",
         filterFn: "includesString",
         Filter: ({ column }) => (
           <input
             value={column.getFilterValue() ?? ""}
             onChange={(e) => column.setFilterValue(e.target.value)}
             placeholder="Filter by Email"
             style={{
               marginTop: "5px",
               width: "100%",
               padding: "4px",
               border: "1px solid #ccc",
               borderRadius: "4px",
               color: "#000",
             }}
             autoFocus={focusedField === "email"} 
             onFocus={() => setFocusedField("email")} 
           />
         ),
        
        },
        {
          accessorKey: "phone",
          header: "Phone"
        },
      {
        accessorKey: "subject",
        header: "Subject"
      },
      { 
        accessorKey: "description", 
        header: "Description",
         cell: ({ row }) => {
        const description = row.original.description || "";
        return description.length > 30
        ? `${description.substring(0, 30)}...`
        : description;
        },
      },
      { 
        accessorKey: "property_id", 
        header: "Property",
       cell: ({ row }) => {
        return (
          <span
            className="cursor-pointer hover:underline"
            onClick={() =>
              window.open(
                `/home/en/${row.original.property_id}`,
                "_blank"
              )
            }
          >
            {row.original.property_id}
          </span>
        );
      },
     },
      {
        accessorKey: "created_time",
        header: "Last Modified",
        cell: ({ row }) => {
          const updatedTime = row.original.created_time;
          return updatedTime
            ? <DateDisplay date={updatedTime} />
            : 'N/A'; // Display 'N/A' if no updated_time is available
        },
      },
    ],
    [editRowId, editRowData]
  );

  const table = useReactTable({
    data,
    columns,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    manualFilters: true,
    getFilteredRowModel: getFilteredRowModel(),
    pageCount: Math.ceil(data.length / pageSize),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
    state: {
      globalFilter,
      columnFilters,
      pagination: {
        pageSize,
        pageIndex: 0, // Initial page index
      },
    },
  });

  // Handle pagination changes
  const handlePageClick = (page) => setPageIndex(page);

  const handlePreviousPage = () => {
    if (pageIndex > 0) setPageIndex((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (pageIndex < totalPages - 1) setPageIndex((prev) => prev + 1);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setPageIndex(0);
  };

  // Pagination Range Logic
  const getPaginationRange = () => {
    const visiblePages = 5; // Show 5 page buttons at most
    const pages = [];

    // Add first page
    if (pageIndex > 2) {
      pages.push(0);
      if (pageIndex > 3) pages.push("...");
    }

    // Add pages around the current page
    for (
      let i = Math.max(pageIndex - 2, 0);
      i <= Math.min(pageIndex + 2, totalPages - 1);
      i++
    ) {
      pages.push(i);
    }

    // Add last page
    if (pageIndex < totalPages - 3) {
      if (pageIndex < totalPages - 4) pages.push("...");
      pages.push(totalPages - 1);
    }

    return pages;
  };

  const paginationRange = getPaginationRange();

  return (
    <div className="bg-primary font-linksans min-h-svh">
    <div className="w-full px-5 py-12">
    <h3 className="text-2xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-4xl font-bold text-textColor pb-10">
    {t("ticket_mgt_title")}
        </h3>

      {loading ? (
        <p>{t("loading")}</p>
      ) : (
        <div className="overflow-x-auto">
          <table
           className="w-full border border-gray-300 bg-gray-50"
           style={{
             borderCollapse: "collapse",
           }}
          >
            <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="border-b bg-slate-300 border-gray-300 text-center p-2"
                style={{
                  minWidth: "150px", // Adjust this width as needed for better responsiveness
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}

                 {/* Column Filters */}
                 {header.column.getCanFilter() ? (
                    <div className="mt-1">
                      {flexRender(
                        header.column.columnDef.Filter,
                        header.getContext()
                      )}
                    </div>
                  ) : null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
      {table.getRowModel().rows.length > 0 ? (
        table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                 className="border-b border-gray-300 p-2 text-center"
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))
      ) : (
        <tr>
          <td
            colSpan={table.getAllColumns().length}
            className="p-4 text-center text-gray-500"
          >
            {t("no_ticket_message")}
          </td>
        </tr>
      )}      
      </tbody>
          </table>


          
      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        <Button
          onClick={handlePreviousPage}
          disabled={pageIndex === 0}
          style={{ marginRight: "10px" }}
        >
           <FaChevronLeft />
        </Button>

        {paginationRange.map((page, idx) =>
          page === "..." ? (
            <span key={idx} style={{ margin: "0 5px" }}>
              ...
            </span>
          ) : (
            <Button
              key={page}
              onClick={() => handlePageClick(page)}
              style={{
                marginRight: "5px",
                backgroundColor: pageIndex === page ? "blue" : "gray",
                color: pageIndex === page ? "gray" : "black",
              }}
            >
              {page + 1}
            </Button>
          )
        )}

        <Button
          onClick={handleNextPage}
          disabled={pageIndex >= totalPages - 1}
          style={{ marginLeft: "10px" }}
        >
         <FaChevronRight />
        </Button>
      </Box>
      {/* Page Size Selector */}
      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        <Select
          value={pageSize}
          onChange={handlePageSizeChange}
          width="auto"
          style={{ marginLeft: "10px" }}
        >
          {[10, 20, 50, 100, 500].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </Select>
      </Box>

{/* 
          <div style={{ marginTop: "10px" }}>
            Page {pageIndex + 1} of {totalPages} ({data.length} records on this
            page)
          </div> */}
        </div>
      )}
    </div>
    </div>
  );
};

export default TicketManagement;
