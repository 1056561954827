import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Image,
  IconButton,
  Select,
  Input,
  Tabs, 
  TabList, 
  TabPanels,
  Tab,
  TabPanel,
  HStack,
  SimpleGrid,
  useDisclosure
} from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import { axiosInstanceIMG } from '../../utils/axio-instance';
import { imagecategories } from '../../constants';
import { useCustomToast } from '../../utils/showToast';

const WebsiteImagesModal = ({ isOpen, onClose, propertyId }) => {
  const [categories, setCategories] = useState(imagecategories);
  const [category, setCategory] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [showNewCategoryInput, setShowNewCategoryInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [files, setFiles] = useState([]);
  const [selectedImgaes, setSelectedImgs] = useState([]);

  const toast = useCustomToast();

  const [webImages, setWebImages] = useState([]);

  const { isOpen: isImageModalOpen, onClose: closeImageModal } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "add-new") {
      setShowNewCategoryInput(true);
    } else {
      setShowNewCategoryInput(false);
      setCategory(selectedValue);
    }
  };
  const handleSelect = (imgUrl, selected)=>{
    if(selected){
      setSelectedImgs([...selectedImgaes, imgUrl])
    }else{
      const existing = selectedImgaes.filter(url=> url != imgUrl);
      setSelectedImgs([...existing]);
    }
  }
  const handleSaveCategory = () => {
    if (newCategory) {
      const newCategoryOption = { value: newCategory.toLowerCase().replace(/\s+/g, ""), label: newCategory };
      setCategories((prevCategories) => [...prevCategories, newCategoryOption]);
      setCategory(newCategoryOption.value); // Set value as category for consistency
      setNewCategory("");
      setShowNewCategoryInput(false);
    }
  };

  const handleFetchImages = async () => {
    try {
      setLoading(true);
      const response = await axiosInstanceIMG.get(`/images/website/images?url=${websiteUrl}`);
     
      setWebImages(response.data.images || []);

    } catch (error) {
      console.error('Error fetching photos:', error);
    }finally {
      setLoading(false);
    }
  };

  const handleUploadImages = async ()=>{
    console.log(selectedImgaes);
    const saveObj = {
      images: selectedImgaes,
      category: category || "default"
    }
    
    try {
      const response = await axiosInstanceIMG.post(`/images/websiteimages/${propertyId}`, saveObj);

      if (response.status) {
        setSelectedImgs([]);
        webImages.forEach(img =>{
          img.selected = false;
        });
        toast.success("Upload Successful", 'Images uploaded successfully');
      }
    } catch (error) {
      console.log(error);
      toast.error("Upload Failed", 'Error uploading images');
    }
  }

  return (
     <>
    <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
    <ModalOverlay />
    <ModalContent
      maxH={{ base: "95vh", md: "90vh" }}
      borderRadius="md"
      display="flex"
      flexDirection="column"
    >
      <ModalHeader>Upload Property Images</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        overflowY="auto"
        px={6}
        flex="1" // Makes ModalBody take the available space for scrolling
      >
        <HStack align="start" spacing={2}>
          {/* Select Category */}
          <Select
            placeholder="Select Category"
            value={category}
            onChange={handleCategoryChange}
           width={"200px"}
          >
            {categories.map((cat) => (
              <option key={cat.value} value={cat.value}>
                {cat.label}
              </option>
            ))}
            <option value="add-new">Add New</option>
          </Select><Input
                placeholder="Enter URL"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
              />
              <Button onClick={handleFetchImages} colorScheme="blue">
                Fetch Images
              </Button>

        </HStack>
  {/* Add New Category */}
  {showNewCategoryInput && (
            <HStack spacing={4}>
              <Input
                placeholder="Enter new category"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
              />
              <Button onClick={handleSaveCategory} colorScheme="blue">
                Save
              </Button>
            </HStack>
          )}
        {/* Tabs for Uploaded Photos */}
        <Tabs variant="enclosed" mt={1}>
          <TabList>
              <Tab>Website images </Tab>
          </TabList>
          <TabPanels>
          {webImages.length > 0 ? (
              <TabPanel key={1}>

                  <Box
                    maxH={{ base: "auto", md: "65vh" }}
                    overflowY={{ base: "visible", md: "auto" }}
                    textAlign="center"
                    pt={4}
                  >
                    <SimpleGrid
                      columns={{ base: 2, sm: 3, md: 4 }}
                      spacing={4}
                      justifyItems="center"
                    >
                      {webImages.map((photo, index) => (<>
                        <Box
                          key={index}
                          position="relative"
                          width="100%"
                          display="flex"
                          justifyContent="center"
                          cursor="pointer"
                        >
                          <Image
                            src={photo.src}
                            alt={`${photo.alt}`}
                            maxWidth="100%"                            
                            borderRadius="md"
                            backgroundColor={"gray.200"}
                          />                           
                          <IconButton
                            icon={<FaCheck />}
                            colorScheme="gray"
                            top="2"
                            right="10"
                            borderColor={photo.selected? "blue.400": "gray.100"}
                            backgroundColor={photo.selected? "blue.400": "gray.100"}
                            onClick={(e) => {
                              e.stopPropagation(); 
                              photo.selected = !photo.selected;
                              handleSelect(photo.src, photo.selected);
                    
                            }}
                          />
                         <Box style={{position:"relative", left:"-200px", zIndex:"2", display:"inline" }}>{photo.width+"X"+photo.height}</Box>
                        </Box>
                        
                        </>
                      ))}
                    </SimpleGrid>
                  </Box>                
              </TabPanel>
          ) : <p>{loading?"Loading...":"No Images found"}</p>}
        </TabPanels>

        </Tabs>
      </ModalBody>
  
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
        <Button
          colorScheme="blue"
          ml={3}
          onClick={handleUploadImages}
        > 
          Upload
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>

  <Modal isOpen={isImageModalOpen} onClose={closeImageModal} size="5xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={0}>
            <Image src={selectedImage} width='100%' alt="Full View" />
          </ModalBody>
        </ModalContent>
      </Modal>
  </>
  );
};

export default WebsiteImagesModal;
