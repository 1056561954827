import React from 'react';
import {
  Text,
  Button,
} from '@chakra-ui/react';

const ReadyMadeGroups = () => {
  return (
    <div className="bg-primary  font-linksans">
      <div className="container px-10 py-12">
        <div className="flex-col items-center flex justify-center">
     
      <Text fontSize="lg" mb={6} textAlign="center">
        Discover the benefits of buying or renting properties within a specific group of listings.
        Enjoy exclusive deals, detailed insights, and a hassle-free process tailored to meet your real estate needs.
      </Text>

      {/* CTA Button */}
      <Button colorScheme="blue" size="lg" width="100%" mb={6}>
        Explore Available Groups List
      </Button>
    </div>
    </div>
    </div>
  );
};

export default ReadyMadeGroups;
