export const roles = [
    { value: "buyer", label: "Buyer" },
    { value: "selleragent", label: "Agent" },
    { value: "builder", label: "Builder" },
     { value: "builderagent", label: "Builder Team" },
     { value: "admin", label: "Admin" },
     { value: "staff", label: "Staff" },
     { value : "buyergrouprental", label: "Buyer & Group Buyers"},
     { value : "grouprental", label: "Group Buyers"},
     { value : "dataentry", label: "Data Entry"}
  ];

  export const StatusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "In-Active" },
  ];

 export const userroles = ['buyer', 'seller', 'admin'];

 export const propTypeLabels = {
  "sfr":"Single Family Residence",
  "single-family homes":"Single-Family Residence",
  "townhome":"Town Home",
  "townhomes":"Town Homes",
  "condo":"Condo",
  "Condo":"CondoU",
  "duet":"Duet",
  "community":"Community",
  "mfr":"Multi-Family Residence",
  'Multi-Family':"Multi-Family Residence",
  "mobile":"Mobile",
  "duplex":"Duplex",
  "villas":"Villas",
  "onelevelliving":"One Level Living",
  "nextgen":"Next Gen"
};


 export const propertyTypeOptions = [
    { value: 'sfr', label: 'Single Family Residency' },
    { value: 'townhome', label: 'Townhome' },
    { value: 'condo', label: 'Condo' },
    { value: 'duet', label: 'Duet' },
    { value: 'mfr', label: 'Multi-Family' },
    { value: 'mobile', label: 'Mobile' },
    { value: 'community', label: 'Community' },

  ];


 export const mlsStatusOptions = [
    { value: 'active', label: 'ACTIVE' },
    { value: 'pending', label: 'PENDING' },
    { value: 'cancelled', label: 'CANCELLED' },
    { value: 'activeundercontract', label: 'ACTIVE UNDER CONTRACT' },
    { value: 'closed', label: 'CLOSED' },
    { value: 'comingsoon', label: 'COMING SOON' },
    { value: 'expired', label: 'EXPIRED' },
    { value: 'temporaryoffmarket', label: 'TEMPORARILY OFF MARKET' },
    { value: 'withdrawn', label: 'WITHDRAWN' },
  ];

  export const referralStatus = [
    { value: 'accepted', label: 'Accepted' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'connected-to-client', label: 'Connected To Client' },
    { value: 'meeting-scheduled-with-client', label: 'Meeting Scheduled With Client' },
    { value: 'met-in-person', label: 'Met-In-Person' },
    { value: 'toured-property', label: 'Toured-Property' },
    { value: 'offer-signed', label: 'Offer-Signed' },
    { value: 'contract-signed', label: 'Contract-Signed' },
    { value: 'in-escrow', label: 'In-Escrow' },
    { value: 'transaction-closed', label: 'Transaction-Closed' },
    { value: 'transaction-failed', label: 'Transaction-Failed' },
  ];


  export const languages = [
    { code: 'en', label: 'English' },
    { code: 'es', label: 'Spanish' },
  ];

  // Before Login home links

  export const HomeLinks = [
    {
      label: 'Rental Home Buyers', 
      path: '/groupbuyers/home/',
      subcategories: [
        { label: 'Home', path: '/groupbuyers/home/' },
        { label: 'Getting Started', path: '/groupbuyers/get-started/' },
        { label: 'Why Choose US', path: '/groupbuyers/home#why-choose-us' },
        { label: 'Still Why', path: '/groupbuyers/home#still-why' },
        { label: 'Join Co-Buying Club', path: '/groupbuyers/home#join-co-buying-club' },
      ]
    },
    { 
      label: 'New Home Buyers', 
      path: '/buyer/home/',
      subcategories: [
        { label: 'Home', path: '/buyer/home/' },
        { label: 'Getting Started', path: '/buyer/get-started/' },
        { label: 'Why Choose US', path: '/buyer/home#why-choose-us' },
        { label: 'Search', path: '/search/' },
        { label: 'Shortlists', path: '/shortlisted/' }
      ]
     },
    { 
      label: 'Builders',
      path: '/builder/home/',
      subcategories: [
        { label: 'Home', path: '/builder/home/' },
        { label: 'Getting Started', path: '/builder/get-started/' },
        { label: 'Why Choose US', path: '/builder/home#why-choose-us' }
      ]
    },
    { 
      label: 'Agents',
      path: '/agent/home/',
      subcategories: [
        { label: 'Home', path: '/agent/home/' },
        { label: 'Getting Started', path: '/agent/get-started/' },
        { label: 'Why Choose US', path: '/agent/home#why-choose-us' }
      ]
    },
    { label: 'Journey Blog', path: 'https://journey.knockthedoor.com/' },
    { label: 'Login', path: '/login' },
  ]

  //After login role based get Nav links and header link
  export const Links = {
    buyer: [
      { label: 'Profile', path: '/buyer/profile/' },
      { label: 'Preferences', path: '/buyer/buyer-preferences/' },
      { label: 'Groups', path: '/groups/' },
      { label: 'Search', path: '/search/' },
      { label: 'Shortlists', path: '/shortlisted/' }
    ],
    buyergrouprental: [
      { label: 'Profile', path: '/buyer/profile/' },
      { label: 'Preferences', path: '/buyer/buyer-preferences/' },
      { label: 'Groups', path: '/groups/' },
  ],
  grouprental: [
      { label: 'Profile', path: '/groupbuyers/profile/' },
      { label: 'Preferences', path: '/groupbuyers/preferences/' },
  ],
    buyeragent: [
      { label: 'My Dashboard', path: '/dashboard/' },
      { label: 'Profile', path: '/buyeragent/profile/' },
      { label: 'My Preferences', path: '/buyeragent/buyer-preferences/' },
      { label: 'Invite Friend', path: '/buyeragent/invites/' },
      { label: 'My Assignments', path: '/buyeragent/my-assignments/' },
    ],
    seller: [
      { label: 'My Dashboard', path: '/dashboard' },
      { label: 'Profile', path: '/seller/profile' },
      { label: 'My Properties', path: '/seller/properties' },
    ],
   selleragent:[
    { label: 'Profile', path: '/agent/profile/' },
    { label: 'My Properties', path: '/agent/properties/' },
    { label: 'Preferences & Info', path: '/agent/agent-licence-info/' },
    { label: 'My Referrals', path: '/agent/referrals/' },
     ],
    admin: [
      { label: 'Profile', path: '/admin/profile/' },
      { label: 'Users', path: '/admin/users/' },
      { label: 'Properties', path: '/admin/properties/' },
      { label: 'Referrals', path: '/admin/assign-agent/' },
      { label: 'Enquiries', path: '/admin/support-tickets/' },
    ],
    staff: [
      { label: 'Profile', path: '/staff/profile/' },
      { label: 'Properties', path: '/staff/properties/' },
      { label: 'Users', path: '/staff/users/' },
      { label: 'Referrals', path: '/staff/assign-agent/' },
      { label: 'Enquiries', path: '/staff/support-tickets/' },
  
    ],
    builder: [
      { label: 'Profile', path: '/builder/profile/' },
      { label: 'Builder Info', path: '/builder/builder-info/' },
      { label: 'My Properties', path: '/builder/properties/' },
      { label: 'Referrals', path: '/builder/referrals/' },
      ],
    builderagent: [
        { label: 'My Dashboard', path: '/dashboard' },
        { label: 'Profile', path: '/builderagent/profile' },
        { label: 'Builder Info', path: '/builderagent/builder_info' }, 
        { label: 'My Properties', path: '/builderagent/properties' },
        { label: 'My Preferences', path: '/builderagent/agent_preferences' },
        { label: 'My Referrals', path: '/builderagent/my_assignments' },
    ],
    dataentry: [
      { label: 'Profile', path: '/staff/profile/' },
      { label: 'Properties', path: '/staff/properties/' },
      { label: 'Users', path: '/staff/users/' },
      { label: 'Referrals', path: '/staff/assign-agent/' },
    ],
  };
  
  export const imagecategories = [
    { value: "default", label: "Default" },
    { value: "kitchen", label: "Kitchen" },
    { value: "floorplans", label: "Floor Plans" },
    { value: "bedroom", label: "Bed Room" },
    { value: "bathroom", label: "Bath Room" },
    { value: "exterior", label: "Exterior" },
    { value: "search-page", label: "Search Page" },
    { value: "elevation", label: "Elevation" },
    { value: "interior", label: "Interior" },
  ];

  export const creditScoreRange = [
    { value: "<575", label: "575 or below" },
    { value: "575-624", label: "575 to 624" },
    { value: "625-674", label: "625 to 674" },
    { value: "675-724", label: "675 to 724" },
    { value: "725-774", label: "725 to 774" },
    { value: "775<", label: "775+" },
    { value: "idnotknow", label: "I don't know" }
  ];

  export const whenToBuyPlan = [
    { value: "now", label: "Now" },
    { value: "0-3", label: "0 - 3 months" },
    { value: "3-6", label: "3 to 6 months" },
    { value: "6<", label: "6+ months" },
  ];

  export const countryCodes = [
    { code: '+1', label: 'USA/Canada' },
    { code: '+91', label: 'India' },
    { code: '+44', label: 'UK' },
    { code: '+61', label: 'Australia' },
    { code: '+81', label: 'Japan' },
    { code: '+49', label: 'Germany' },
    { code: '+33', label: 'France' },
    { code: '+86', label: 'China' },
    { code: '+55', label: 'Brazil' },
    { code: '+7', label: 'Russia' },
  ];
  