import React from 'react';
import { useAuth } from '../../components/user/AuthProvider';
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { Links } from "../../constants";
import { useProducts } from '../compare/ProductsProvider';


const getUserLinks = (roles) => {
  const uniqueLinks = new Set();
  
  roles.forEach(role => {
    if (Links[role]) {
      Links[role].forEach(link => uniqueLinks.add(JSON.stringify(link)));
    }
  });

  return Array.from(uniqueLinks).map(link => JSON.parse(link));
};

const NavLinks = ({ profileInfo, onClose }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => { 
    logout(); 
  };

  const handleLinkClick = (index) => {
    if (onClose) {
      onClose(); // Close the drawer when a link is clicked
    }
  };

  const drawerItems = profileInfo ? getUserLinks(profileInfo.role) : [];

  const { selectedProducts, groupProductCounts } = useProducts();


  return (
    <>
             {drawerItems.map((item, index) => (
                <Link
                  key={`${index}_navlink`}
                  to={item.path}
                  onClick={() => handleLinkClick(index)}
                  className="block text-center hover:bg-primary transition-all duration-300 py-3"
                >
                  <div className="inline-flex items-center justify-center gap-2">
                    <h4 className="text-base font-medium">
                      {item.label}
                    </h4>
                    {groupProductCounts !== null && item.label === 'Shortlists' && (
                      <span className="inline-flex items-center py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        ( {groupProductCounts?.total > 100 ? '100+' : groupProductCounts?.total || 0} )
                      </span>
                    )}
                  </div>
                </Link>
              ))}


            <Link to="#" onClick={handleLogout}>
              <h4 className="text-base font-medium text-center hover:bg-primary transition-all duration-300 py-3">
                Logout
              </h4>
            </Link>
            </>
  );
};

export default NavLinks;
