import React, { useEffect, useState } from 'react';
import {
  Badge,
  Tooltip,
  Stack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Box,
  Flex,
  Text,
  IconButton,
} from '@chakra-ui/react';
import axiosInstance from '../../utils/axio-instance';
import { FaExchangeAlt } from 'react-icons/fa'; 
import { useNavigate } from 'react-router-dom';
import { useProducts } from '../compare/ProductsProvider';
import { useTranslation } from 'react-i18next'; 
import CommonButton from "../../components/compare/CommonButton";


function ShareGroupNames({ propertyId }) {
  const [groupsData, setGroupsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userId = localStorage.getItem('userid');
  const navigate = useNavigate();
  const [groupCounts, setGroupCounts] = useState({});
  const { t} = useTranslation();
  
  const { groupsUpdated} = useProducts();
  useEffect(() => {
    const fetchGroupsData = async () => {
      try {
        // Fetch group data by propertyId
        const response = await axiosInstance.get(`/usrgroup/property/${propertyId}`);

        const groupDataPromises = response.data.map(async (group) => {
          const groupResponse = await axiosInstance.get(`/usrgroup/${group.id}/users`);

          return {
            groupId: group.id,
            groupName: group.name,
            invitees: groupResponse.data.map((invitee) => `${invitee.first_name} ${invitee.last_name}`),
          };
        });

        const groupDataArray = await Promise.all(groupDataPromises);
        const groupsDataObject = groupDataArray.reduce((acc, group) => {
          acc[group.groupName] = {
            groupId: group.groupId,
            invitees: group.invitees,
          };
          return acc;
        }, {});

        setGroupsData(groupsDataObject);
      } catch (err) {
        setError(err.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchGroupsData();
  }, [propertyId,groupsUpdated]);

  const fetchGroupCounts = async () => {
    try {
      const response = await axiosInstance.get(`/usrgroup/all/count`);
      setGroupCounts(response.data || {});
    } catch (error) {
      setError(error.message || 'Failed to fetch group counts');
    }
  };

  useEffect(() => {
    fetchGroupCounts();
  }, []);

  if (loading) return <div>{t('loading')}</div>;

  if (error) {
    return userId ? (
      <Badge colorScheme="pink" cursor="pointer" mt={4}>
        {t('invite_friends_share')}
      </Badge>
    ) : (
      <Badge colorScheme="pink" cursor="pointer" mt={4}>
       {t('invite_friends_share_public')}
      </Badge>
    );
  }

  const groupNames = Object.keys(groupsData);
  const firstTwoGroups = groupNames.slice(0, 2);
  const remainingGroups = groupNames.slice(2);

  const handleCompareGroup = (groupId) => {
    const path = `/shortlisted?ids=${groupId}`;
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="flex flex-col gap-3 sm:flex-row sm:gap-2 mt-3 items-start sm:items-center">
          {groupNames.length > 0 && (
            <p className="text-xs sm:text-sm font-medium px-2 py-1 sm:px-2.5 sm:py-1.5">
              Shortlist By :
            </p>
          )}

          <div className="flex flex-wrap gap-2">
            {firstTwoGroups.map((group, index) => (
              <div key={index} className="relative">
                <Tooltip
                  label={groupsData[group]?.invitees?.join(", ") || ""}
                  placement="top"
                >
                  <CommonButton
                    name={group}
                    isLink={false}
                    color={"darkgreen"}
                    disablePointer={true}
                    onClick={() => handleCompareGroup(groupsData[group]?.groupId)}
                  />
                </Tooltip>
              </div>
            ))}
          </div>

          {remainingGroups.length > 0 && (
            <div>
              <CommonButton
                icon={"more"}
                name={"View More"}
                isLink={false}
                color={"gray"}
                onClick={onOpen}
                disablePointer={true}
              />
            </div>
          )}
        </div>


      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('moregroups')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              {groupNames.map((group, index) => (
                <Box key={index} position="relative">
                  <Flex
                    align="center"
                    bg="gray.100"
                    p={4}
                    borderRadius="md"
                    cursor="pointer"
                    _hover={{ bg: 'gray.200' }}
                  >
                    <Text flex="1">
                      {group}
                      {groupsData[group]?.invitees?.length > 0 && ` - ${groupsData[group].invitees.join(', ')}`}
                    </Text>
                  </Flex>
                  <Box position="absolute" top="15px" right="10px">
                    <Tooltip label={t('groupcomparetooltip')} aria-label={t('groupcomparelabel')}>
                      <IconButton
                        aria-label="Shortlist"
                        icon={<FaExchangeAlt />}
                        colorScheme="blue"
                        size="sm"
                        onClick={() => handleCompareGroup(groupsData[group]?.groupId)}
                      />
                    </Tooltip>
                    <Box position="absolute" top="-12px" right="-4px">
                      <Badge colorScheme="blue">
                        {groupCounts[groupsData[group]?.groupId]?.property_count || 0}
                      </Badge>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ShareGroupNames;
