import React, { useState, useEffect } from 'react';
import {
  ChakraProvider, Box, Flex, Heading,
  Button, Input, Stack,
  Text, IconButton, Collapse,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
  Tooltip,
  Icon,
  useToast
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon, CopyIcon } from '@chakra-ui/icons';
import { FaExchangeAlt, FaSignOutAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axio-instance';
import CommonStatusButton from "../../pages/InvitePage/CommonStatusButton"; 

function GroupManagement() {
  const [groups, setGroups] = useState([]);
  const [groupsDataById, setGroupsDataById] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [groupNameValid, setGroupNameValid] = useState(true);
  const [isDuplicateGroupName, setIsDuplicateGroupName] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const { t } = useTranslation();
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [firstNameValid, setFirstNameValid] = useState(true);
  const [lastNameValid, setLastNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [error, setError] = useState(null);
  const localtion = useLocation();
  const navigate = useNavigate();
  const userId = localStorage.getItem('userid');
  const toast = useToast();
  const [groupCounts, setGroupCounts] = useState({});

  const handleCopy = (inviteeToken) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    let inviteUrl = `${baseUrl}sign-up?token=${inviteeToken}`;
    if (inviteeToken && inviteeToken.indexOf("_E") > 0) {
      inviteUrl = `${baseUrl}groups/joingroup?token=${inviteeToken}`;
    }
    navigator.clipboard.writeText(inviteUrl);
    toast({
      title: t('copied-clipboard'),
      description: t('copied-clipboard-message', { inviteUrl }),
      status: 'success',
      duration: 2000,
      isClosable: true,
      position: 'top-right',
    });
  };

  const [inviteSubmit, setInviteSubmit] = useState(false);


  useEffect(() => {
    if (localtion.pathname.indexOf("groups") > 0) {
      fetchGroups();
      fetchGroupCounts();
    }
  }, [localtion.pathname]);

  const fetchGroupCounts = async () => {
    //usrgroup/all/count
    try {
      const response = await axiosInstance.get(`/usrgroup/all/count`);
      //const responseData = await response.json();
      setGroupCounts(response.data || {});
    } catch (error) {
      setError(error.message);
    }
  }

  const fetchGroups = async () => {
    // Fetch groups from an API endpoint
    try {
      const response = await axiosInstance.get(`/usrgroup` );
     // const responseData = await response.json();
      setGroups(response.data || []);
      //localstorage.usrgroups
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchGroupDataById = async (groupId) => {
    // Fetch group data by ID from an API endpoint
    try {
      setInviteSubmit(false);
      const response = await axiosInstance.get(`/usrgroup/${groupId}/users` );
      const responseData = response.data;
      //console.log("responseData", responseData);
      setGroupsDataById((prevData) => ({ ...prevData, [groupId]: { users: responseData || [] } }));
    } catch (error) {
      setError(error.message);
    }
  }

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
    setGroupNameValid(true);
    setIsDuplicateGroupName(false);
  };

  const MAX_GROUPS = parseInt(process.env.REACT_APP_MAX_GROUPS);
  //const MAX_INVITES_PER_GROUP = process.env.REACT_APP_MAX_INVITES_PER_GROUP;

  const handleCreateGroup = async () => {
    let isValid = true;

    // Validate group name
    if (!groupName.trim() || groupName.trim().length > 50) {
      setGroupNameValid(false);
      isValid = false;
    }

    const groupExists = groups.some(group => group.name.toLowerCase() === groupName.trim().toLowerCase());
    if (groupExists) {
      setIsDuplicateGroupName(true);
      toast({
        title: 'Error',
        description: t('group-alreadyexistmessage'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      isValid = false;
    }

    // Perform additional validations for other fields if needed

    if (!isValid) {
      return;
    }

    try {
      if (groups.length >= MAX_GROUPS) {
        let errorMessage = t('group-limitmessage');
        toast({
          title: 'Error',
          description: errorMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return;
      }

      const response = await axiosInstance.post(`/usrgroup`, { name: groupName });
      const data = response.data;
      const newGrpSet = [...groups, data];
      setGroups(newGrpSet);
      localStorage.setItem('usrgroups', JSON.stringify(newGrpSet));
      setGroupName('');
      toast({
        title: t('group-successmessage'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      setError(error.message);

      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
    //  setLoading(false);
    }
  };

  const handleInviteUsers = async () => {
    try {
      let isFormValid = true;

      // Validate first name
      if (!userData.firstName.trim()) {
        setFirstNameValid(false);
        isFormValid = false;
      } else {
        setFirstNameValid(true);
      }

      // Validate last name
      if (!userData.lastName.trim()) {
        setLastNameValid(false);
        isFormValid = false;
      } else {
        setLastNameValid(true);
      }

      // Validate email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!userData.email.trim() || !emailRegex.test(userData.email.trim())) {
        setEmailValid(false);
        isFormValid = false;
      } else {
        setEmailValid(true);
      }

      const phoneRegex = /^\+\d{1,3}\d{9}$/;
      if (!userData.phone.trim() || !phoneRegex.test(userData.phone.trim())) {
        setPhoneValid(false);
        isFormValid = false;
      } else {
        setPhoneValid(true);
      }
      if (!isFormValid) {
        return;
      }

      const inviteData = {
        invites: [
          {
            first_name: userData.firstName,
            last_name: userData.lastName,
            email: userData.email,
            phone: userData.phone,
          }
        ]
      };
      setInviteSubmit(true);
      const response = await axiosInstance.post(`/usrgroup/${selectedGroup.id}/invite_batch`,
        inviteData );

      if (response.status === 200 && response.data.insertedCount) {

        // Update the user data and reset the form
        setUserData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        });
        setShowAddUserForm(false);
        toast({
          title: t('inviteuser-successmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        //fetchGroupDataById
        fetchGroupDataById(selectedGroup.id)

      } else {
        setInviteSubmit(false);
        setError(error.message);
        toast({
          title: 'Error',
          description: error,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }

    } catch (error) {
      setInviteSubmit(false);
      setError(error.message);
      toast({
        title: 'Error',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };


  const handleInviteClose = () => {
    setUserData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    });
    setShowAddUserForm(false);
  };


  const handleDeleteGroup = async (groupId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: t('group-deleteconfirmmessage'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`/usrgroup/${groupId}`);

        setGroups(groups.filter((group) => group.id !== groupId));
        toast({
          title: t('group-deletemessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        setError(error.message);
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  const handleExitGroup = async (groupId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: t('group-exitconfirmmessage'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, exit it!',
      cancelButtonText: 'Cancel'
    });

    if (result.isConfirmed) {
      try {
       await axiosInstance.delete(`/usrgroup/${groupId}/user/${userId}`);

        setGroups(groups.filter((group) => group.id !== groupId));
        toast({
          title: t('group-exitmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        setError(error.message);
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  const handleCompareGroup = (groupId) => {
    const path = `/shortlisted?ids=${groupId}`;
    navigate(path);
  };

  const handleGroupClick = async (group, e) => {
    if (e.target.type === 'checkbox' || e.target.closest('.chakra-checkbox')) {
      return;
    }
    if (selectedGroup !== group) {
      await fetchGroupDataById(group.id);
    }
    setSelectedGroup(selectedGroup === group ? null : group);
  };

  const handleUnfriend = async (invitee) => {
    const inviteeEmail = invitee.email;
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: t('unfriend-confirmmessage', { inviteeEmail }),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, unfriend!',
      cancelButtonText: 'Cancel',
      customClass: {
        container: 'swal2-container',
        popup: 'swal2-popup',
        backdrop: 'swal2-backdrop',
        confirmButton: 'swal2-confirm',
        cancelButton: 'swal2-cancel',
      }
    });

    if (result.isConfirmed) {
      let urlPath = ''
      if (invitee.user_id) {
        urlPath = 'user/' + invitee.user_id;
      } else {
        urlPath = 'user/0?email=' + invitee.email;
      }
      try {
        await axiosInstance.delete(`/usrgroup/${selectedGroup.id}/${urlPath}`);

        fetchGroupDataById(selectedGroup.id);
        toast({
          title: t('unfriend-message', { inviteeEmail }),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        setError(error.message);
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };
  const handleAddUser = () => {
    setShowAddUserForm(true);
  };

  const handleUserDataChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  return (
    <ChakraProvider>
       <div className="bg-primary  font-linksans min-h-svh">
       <div className="container px-5 py-12">
       <div className="flex-col items-center flex justify-center">
         <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-textColor pb-3">
         {t('groups_mgt_title')}
          </h3>
          </div>
      <Box p={4}>
        <Flex align="center" justify="space-between" mb={4}>
          <Heading size="md"></Heading>
          <Stack direction="row" spacing={4}>
            <FormControl isInvalid={!groupNameValid || isDuplicateGroupName} isRequired>
              <Input
                bg="white" color="gray.500" fontSize="sm" px={3}  py={3} borderRadius="lg" 
                placeholder="Group Name"
                value={groupName}
                onChange={handleGroupNameChange}
              />
              <FormErrorMessage>
                {!groupName.trim()
                  ? 'Group name is required.'
                  : groupName.trim().length > 50
                    ? 'Group name must be 50 characters or less.'
                    : isDuplicateGroupName
                      ? 'Group name already exists.'
                      : null}
              </FormErrorMessage>
            </FormControl>

            <Button
              leftIcon={<AddIcon />}
              size="md"
              bg="white"
              width="200px"
              onClick={handleCreateGroup}
            >
              Create
            </Button>
          </Stack>
        </Flex>
        <Stack spacing={4}>
          {groups && groups.map((group) => (
            <Box key={group.id} position="relative">
              <Flex
                align="center"
                bg="white"
                p={4}
                borderRadius="md"
                cursor={group.name === "Personal" ? "default" : "pointer"}
                _hover={{ bg: 'gray.100' }}
                onClick={(e) => handleGroupClick(group, e)}
              >
                <Text
                 flex="1"
                  >{group.name}</Text>
                <Flex align="center">
                  {group.user_id == userId ? (
                    <Tooltip label="Delete Group" aria-label="Delete Group">
                      <IconButton
                        aria-label="Delete Group"
                        icon={<DeleteIcon />}
                        colorScheme="gray"
                        size="sm"
                        onClick={() => handleDeleteGroup(group.id)}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip label="Exit Group" aria-label="Exit Group">
                      <IconButton
                        aria-label="Exit Group"
                        icon={<Icon as={FaSignOutAlt} />}
                        colorScheme="gray"
                        size="sm"
                        onClick={() => handleExitGroup(group.id)}
                      />
                    </Tooltip>
                  )}
                </Flex>
              </Flex>
              <Box position="absolute" top="15px" right="60px">
                <Tooltip label={t('groupcomparetooltip')} aria-label={t('groupcomparelabel')}>
                  <IconButton
                    aria-label="Shortlist"
                    icon={<FaExchangeAlt />}
                    colorScheme="gray"
                    size="sm"
                    onClick={() => handleCompareGroup(group.id)}
                  />
                </Tooltip>
                <Box position="absolute" right="-4px">
                <span className="w-10 h-4 flex text-xs -right-2 -top-10 justify-center items-center absolute rounded-full bg-[#FFAB00] p-2">
                    {groupCounts && groupCounts[group.id]?.property_count || 0}
                    </span>
                </Box>
              </Box>
              <Collapse in={selectedGroup && selectedGroup.id === group.id &&
                !['Personal'].includes(group.name)}
                animateOpacity>
                <Box p={4}>
                  {!showAddUserForm && (
                    <Button bg="white" mb={4} onClick={handleAddUser}>
                      Add Invite User
                    </Button>
                  )}

                <div className="flex justify-center">
                  <div className="w-full sm:w-[972px] bg-white rounded-xl">
                    {(groupsDataById[group.id]?.users || []).length > 0 ? (
                      <div className="hidden sm:table w-full">
                        {/* Table Body */}
                        <div className="table-row-group">
                          {groupsDataById[group.id].users.map((invitee, index) => (
                            <div key={index} className="table-row border-b">
                              <div className="table-cell py-2 px-4">
                                {invitee.first_name} {invitee.last_name}
                              </div>
                              <div className="table-cell py-2 px-4">{invitee.email}</div>
                              <div className="table-cell py-2 px-4">
                                <CommonStatusButton
                                  color={invitee.status === 'invited' ? 'red' : 'green'}
                                  text={invitee.status === 'invited' ? 'Invited' : 'Accepted'}
                                />
                              </div>
                              <div className="table-cell py-2 px-4">
                                <div className="flex items-center gap-2">
                                  {invitee.status !== 'registered' && (
                                    <Tooltip label="Copy invite URL" placement="top">
                                      <IconButton
                                        icon={<CopyIcon />}
                                        size="sm"
                                        onClick={() => handleCopy(invitee.token)}
                                        aria-label="Copy invite URL"
                                      />
                                    </Tooltip>
                                  )}
                                  {group.user_id == userId && (
                                    <div
                                      className="p-1 bg-primary cursor-pointer rounded-xl"
                                      onClick={() => handleUnfriend(invitee)}
                                    >
                                      {/* Delete Icon */}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 25 24"
                                        fill="none"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M15.5 3V4H20.5V6H19.5V19C19.5 20.1 18.6 21 17.5 21H7.5C6.4 21 5.5 20.1 5.5 19V6H4.5V4H9.5V3H15.5ZM7.5 19H17.5V6H7.5V19ZM9.5 8H11.5V17H9.5V8ZM15.5 8H13.5V17H15.5V8Z"
                                          fill="#212A30"
                                        />
                                      </svg>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="text-center text-gray-500 py-4">No users found</div>
                    )}
                    {/* Mobile Grid Format */}
                    {(groupsDataById[group.id]?.users || []).length > 0 && (
                      <div className="sm:hidden grid gap-4">
                        {groupsDataById[group.id].users.map((invitee, index) => (
                          <div
                            key={index}
                            className="grid grid-cols-12 gap-4 p-4 bg-gray-50 rounded-lg border"
                          >
                            <div className="col-span-12">
                              <h4 className="font-medium text-paragraphColor">
                                {invitee.first_name} {invitee.last_name}
                              </h4>
                              <p className="text-sm text-gray-500">{invitee.email}</p>
                            </div>
                            <div className="col-span-12 flex items-center justify-between">
                              <CommonStatusButton
                                color={invitee.status === 'invited' ? 'red' : 'green'}
                                text={invitee.status === 'invited' ? 'Invited' : 'Accepted'}
                              />
                              <div className="flex gap-2 items-center">
                                {invitee.status !== 'registered' && (
                                  <Tooltip label="Copy invite URL" placement="top">
                                    <IconButton
                                      icon={<CopyIcon />}
                                      size="sm"
                                      onClick={() => handleCopy(invitee.token)}
                                      aria-label="Copy invite URL"
                                    />
                                  </Tooltip>
                                )}
                                {group.user_id == userId && (
                                  <div
                                    className="p-1 bg-primary cursor-pointer rounded-xl"
                                    onClick={() => handleUnfriend(invitee)}
                                  >
                                    {/* Delete Icon */}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="25"
                                      height="24"
                                      viewBox="0 0 25 24"
                                      fill="none"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M15.5 3V4H20.5V6H19.5V19C19.5 20.1 18.6 21 17.5 21H7.5C6.4 21 5.5 20.1 5.5 19V6H4.5V4H9.5V3H15.5ZM7.5 19H17.5V6H7.5V19ZM9.5 8H11.5V17H9.5V8ZM15.5 8H13.5V17H15.5V8Z"
                                          fill="#212A30"
                                        />
                                      </svg>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  {showAddUserForm && (
                    <Box mt={4} className='font-linksans'>
                      <Heading size="md">Add Invite User</Heading>
                      <FormControl mt={2} isRequired isInvalid={!firstNameValid}>
                        <FormLabel>First Name</FormLabel>
                        <Input bg="white" color="gray.500" fontSize="md" px={3}  py={3} borderRadius="lg"  name="firstName" value={userData.firstName} onChange={handleUserDataChange} />
                        <FormErrorMessage>First name is required.</FormErrorMessage>
                      </FormControl>
                      <FormControl mt={2} isRequired isInvalid={!lastNameValid}>
                        <FormLabel>Last Name</FormLabel>
                        <Input  bg="white" color="gray.500" fontSize="md" px={3}  py={3} borderRadius="lg"  name="lastName" value={userData.lastName} onChange={handleUserDataChange} />
                        <FormErrorMessage>Last name is required.</FormErrorMessage>
                      </FormControl>
                      <FormControl mt={2} isRequired isInvalid={!emailValid}>
                        <FormLabel>Email</FormLabel>
                        <Input  bg="white" color="gray.500" fontSize="md" px={3}  py={3} borderRadius="lg"  type="email" name="email" value={userData.email} onChange={handleUserDataChange} />
                        <FormErrorMessage>Email is invalid.</FormErrorMessage>
                      </FormControl>
                      <FormControl mt={2} isRequired isInvalid={!phoneValid}>
                        <FormLabel>Phone</FormLabel>
                        <Input bg="white" color="gray.500" fontSize="md" px={3}  py={3} borderRadius="lg"  name="phone" value={userData.phone} onChange={handleUserDataChange} />
                        <FormErrorMessage>Mobile number must be in the format +[country code][number]</FormErrorMessage>
                      </FormControl>
                      {!inviteSubmit ? <Button bg="white" mt={4} onClick={handleInviteUsers}>
                        Submit
                      </Button> : <Spinner size="sm" />}
                      <Button bg="white" mt={4} ml={4} onClick={handleInviteClose}>
                        Cancel
                      </Button>
                    </Box>
                  )}
                </Box>
              </Collapse>
            </Box>
          ))}
        </Stack>
      </Box>
      </div>
      </div>
    </ChakraProvider>
  );
}

export default GroupManagement;
