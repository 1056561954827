import React, { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react'; // Chakra UI components
import axiosInstance,{axiosInstanceES} from '../../utils/axio-instance';
import { propertyTypeOptions, creditScoreRange, whenToBuyPlan} from '../../constants';
import { useTranslation } from 'react-i18next'; 
import { useNavigate } from 'react-router-dom';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";


const BuyerGetStarted = () => {
  const profileId = localStorage.getItem('userid');
  const [creditScoreRangeValue, setCreditScoreRangeValue] = useState("");
  const [whenToBuyPlanValue, setWhenToBuyPlanValue] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [message, setMessage] = useState("");
  const [location, setLocation] = useState('');
  const [city, setCity] = useState('');
  
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [priceMin, setPriceMin] = useState('');
  const [priceMax, setPriceMax] = useState('');
  const [complist, setCompList] = useState([]);
  const [company, setCompany] = useState("");
  const [companyId, setCompanyId] = useState(""); 
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [phone, setPhone] =useState('');
  const[firstName, setFirstName]=useState('');
  const[lastName, setLastName]=useState('');
  const[role, setRole]=useState('buyer');
  const [canWeSms, setCanWeSms] = useState('no');


  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t} = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const {budget, setBudget} = useState('');

  useEffect(()=>{
    axiosInstanceES.get(`/companies`)
      .then(response => {
        const frags = {states:new Set(), cities: new Set()};
        setCompList(response.data);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
  },[]);

  
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
  
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=initAutocomplete&libraries=places&v=weekly`;
    script.defer = true;
    document.head.appendChild(script);

    window.initAutocomplete = () => {
      const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById("address"), {
        types: ["(cities)"], // Restrict to cities only
        componentRestrictions: { country: "us" },
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
  
        // Extract city and state
        const addressComponents = place.address_components || [];
        const cityComponent = addressComponents.find((component) =>
          component.types.includes('locality')
        );
        const stateComponent = addressComponents.find((component) =>
          component.types.includes('administrative_area_level_1')
        );
  
        setCity(cityComponent?.long_name || '');
        setLocation(stateComponent?.short_name || '');
  
        // Extract latitude and longitude
        const lat = place.geometry?.location?.lat();
        const lng = place.geometry?.location?.lng();
        setLatitude(lat || '');
        setLongitude(lng || '');
  
        // Update address field with formatted address
        setAddress(place.formatted_address || '');
      });
    };
  }, []);

  const handleSuggestionClick = (description) => {
    setAddress(description);
    setSuggestions([]);
  };

  const validate = () => {
    let formErrors = {};

    if (!firstName) formErrors.firstName = 'FirstName is required';
    if (!lastName) formErrors.lastName = 'LastName is required';
    if (!email) {
      formErrors.email = 'Email is required';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        formErrors.email = 'Invalid email format';
      }
    }
  
    // Phone Number Validation
    if (!phone) {
      formErrors.phone = 'Phone Number is required';
    } else {
      const phoneDigits = phone.replace(/\D/g, ''); // Remove non-digit characters
      
      if (phoneDigits.length < 10 || phoneDigits.length > 14) {
        formErrors.phone = 'Phone Number sholud not be less then 11 digits';
      }
    }
  
    if (!address) formErrors.address = 'Address is required';
    if (!propertyType) formErrors.propertyType = 'Property type is required';
    if (!creditScoreRangeValue) formErrors.creditScoreRange = 'creditScoreRange is required';
    if (!whenToBuyPlanValue) formErrors.whenToBuyPlan = 'When to Buy filed is required';
    if (!downPayment) formErrors.downPayment = 'Down Payment is required';

      const priceMinValue = parseFloat(priceMin.replace(/[^0-9.-]+/g, '')); // Remove currency formatting
      if (isNaN(priceMinValue) || priceMinValue < 0) {
        formErrors.priceMin = 'Min price must be a valid non-negative number';
      }
    
      // PriceMax Validation
      const priceMaxValue = parseFloat(priceMax.replace(/[^0-9.-]+/g, '')); // Remove currency formatting
      if (isNaN(priceMaxValue) || priceMaxValue < priceMinValue) {
        formErrors.priceMax = 'Max price must be greater than or equal to Min price';
      }
    
      setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage('');
    setErrorMessage('');

    if (!validate()) {
      setIsSubmitting(false);
      return;
    }


    const updatedPreferences = {
      role: "buyer",
      preferences: {
        email: email,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        preferredLocation: [address],
        preferredPropertyTypes: [propertyType],
        preferredPriceRange: [priceMin, priceMax],
        creditScoreRangeValue: creditScoreRangeValue,
        whenToBuyPlanValue: whenToBuyPlanValue,
        downPayment: downPayment,
        message: message,
        latitude: latitude,
        longitude: longitude,
      }
    };

    try {
      const response = await axiosInstance.post(`/users/online/preferences`, updatedPreferences);
      
      if (response.status === 200) {
        setSuccessMessage(t('saved'));
        toast({
          title: t('getstarted-successmessage'),
          status: 'success',
          duration: 10000,
          isClosable: true,
          position: 'top-right',
        });

        sessionStorage.setItem('userPreferences', JSON.stringify({
          firstName,
          lastName,
          email,
          role, 
        }));

        window.scrollTo({ top: 0, behavior: 'smooth' });

        setFirstName('');
        setLastName('');
        setEmail('');
        setCanWeSms('');
        setPhone('');
        setAddress('');
        setPropertyType('');
        setPriceMin('');
        setPriceMax('');
        setCreditScoreRangeValue('');
        setWhenToBuyPlanValue('');
        setDownPayment('');
        setMessage('');
        setLatitude('');
        setLongitude('');

        navigate('/sign-up');

      }
    } catch (error) {
      let errorMessage = 'Failed to update preferences. Please try again later.';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      setErrorMessage(t('failed'));
      toast({
        title: 'Error',
        description: t('getstarted-errormessage'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsSubmitting(false);
    }
  };


  const handlePriceChange = (e, setPriceMax) => {
    const input = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(input || 0); // Format as currency
  
    setPriceMax(input); // Store raw numeric value
    e.target.value = formattedValue; // Update input display
  };

  const handleDownPaymentChange = (e, setDownPayment) => {
    const input = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(input || 0); // Format as currency
  
    setDownPayment(input); // Store raw numeric value
    e.target.value = formattedValue; // Update input display
  };

  const handlePriceMinChange = (e, setPriceMin) => {
    const input = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(input || 0); // Format as currency
    console.log("formateed input",input);
    setPriceMin(input); // Store raw numeric value
    e.target.value = formattedValue; // Update input display
  };

  const handleEmailChange = (e) => {
    const value = e.target.value.trim();
    setEmail(value);
  }

  const handlePhoneChange = (value) => {
    setPhone(value); // Value is the phone number string
  };

  const handleCompanyChange = (e) => {
    const value = e.target.value.trim();
    setCompany(value);

    // Filter the companies based on the input
    const filtered = complist.filter((company) =>
      company.company_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCompanies(filtered);
  };

  const handleSelectCompany = (company) => {
    setCompany(company.company_name || "");
    setCompanyId(company.companyid || ""); // Set the company ID
    setFilteredCompanies([]); // Clear the filtered list
  };

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timeout
    }
  }, [successMessage, errorMessage]);

  return (
    <div className="bg-primary  font-linksans pb-12">
      <div className="container px-5 py-2 min-h-svh">
        <div className="flex-col items-center flex justify-center mt-4">
        <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-textColor pb-10">
        Getting Started
        </h3>

      <div className="w-full flex justify-center">
      <form action="" className="w-[500px] max-w-xl flex flex-col gap-3">

      <div className="flex flex-wrap gap-4">
            {/* First Name */}
            <div className="flex flex-col gap-2 w-full md:flex-1">
                <label className="text-sm text-paragraphColor" htmlFor="first-name">
                First Name <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                type="text"
                placeholder="Enter First Name"
                className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white w-full"
                id="first-name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                />
                {errors.firstName && (
                <span className="text-sm text-red-400">{errors.firstName}</span>
                )}
            </div>

            {/* Last Name */}
            <div className="flex flex-col gap-2 w-full md:flex-1">
                <label className="text-sm text-paragraphColor" htmlFor="last-name">
                Last Name <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                type="text"
                placeholder="Enter Last Name"
                className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white w-full"
                id="last-name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                />
                {errors.lastName && (
                <span className="text-sm text-red-400">{errors.lastName}</span>
                )}
            </div>
            </div>

             {/* Email Field */}
             <div className="flex flex-col gap-2 w-full md:flex-1">
                    <label className="text-sm text-paragraphColor" htmlFor="email">
                    Email <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                    type="email"
                    id="email"
                    placeholder="Enter Email"
                    className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white "
                    value={email}
                    onChange={handleEmailChange}
                    />
                    {errors.email && <span className="text-sm text-red-400">{errors.email}</span>}
                  </div>

       <div className="flex flex-wrap gap-4">
          {/* Phone Section */}
          <div className="flex flex-col gap-2 w-full md:w-[48%]">
            <label className="text-sm text-paragraphColor" htmlFor="phone">
              Phone <span style={{ color: "red" }}>*</span>
            </label>
            <div className="flex gap-2">
              {/* Phone Number Field */}
              <PhoneInput
                id="phone"
                placeholder="Enter Phone Number"
                className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white border w-full"
                value={phone}
                onChange={handlePhoneChange}
                defaultCountry="US"
                international
                withCountryCallingCode={false} 
              />
            </div>
            {errors.phone && <span className="text-sm text-red-400">{errors.phone}</span>}
          </div>

          {/* Can We Send SMS Section */}
          <div className="flex flex-col gap-2 w-full md:w-[48%]">
            <div className="flex items-center gap-6 md:mt-10 md:ml-2">
              <label className="text-sm text-paragraphColor" htmlFor="can-we-sms">
                Can We Send SMS?
              </label>
              <label className="inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" checked={canWeSms === 'yes'} onChange={(e) => setCanWeSms(e.target.checked ? 'yes' : 'no')} />
                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label  className="text-sm text-paragraphColor" htmlFor="address">Preferred Location <span style={{ color: "red" }}>*</span></label>
          <input
            id="address"
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white"
            placeholder="Enter your address"
          />
          {loading && <p>Loading suggestions...</p>}
          {suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleSuggestionClick(suggestion.description)}
                >
                  {suggestion.description}
                </li>
              ))}
            </ul>
          )}
         
           {errors.address && (
              <span className="text-sm text-red-500">{errors.address}</span>
            )}
        </div>

        <div className="flex flex-col gap-2 mt-2">
            <label className="text-sm text-paragraphColor" htmlFor="propertyType">
            Property Preferences <span style={{ color: "red" }}>*</span>
            </label>
            <select 
            className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" 
            id="propertyType" 
            value={propertyType} 
            onChange={(e) => setPropertyType(e.target.value)}
            >
            <option value="">Select Property Type</option>
            {propertyTypeOptions.map(option => (
                <option key={option.value} value={option.value}>
                {option.label}
                </option>
            ))}
            </select>
            {errors.propertyType && <span className="text-sm text-red-400">{errors.propertyType}</span>}
        </div>

        <div className="flex flex-wrap gap-4">
              <div className="flex flex-col gap-2 w-full md:flex-1">
                <label className="text-sm text-paragraphColor" htmlFor="min-price">
                  Min Budget <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Min Price"
                  className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white w-full"
                  id="min-price"
                  onChange={(e) => handlePriceMinChange(e, setPriceMin)}
                  value={
                    priceMin
                      ? new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                        }).format(priceMin)
                      : "" // Display empty string if no value
                  }
                  min="0"
                />
                {errors.priceMin && (
                  <span className="text-sm text-red-400">{errors.priceMin}</span>
                )}
              </div>

              <div className="flex flex-col gap-2 w-full md:flex-1">
                <label className="text-sm text-paragraphColor" htmlFor="max-price">
                  Max Budget <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Max Price"
                  className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white w-full"
                  id="max-price"
                  onChange={(e) => handlePriceChange(e, setPriceMax)}
                  value={
                    priceMax
                      ? new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                        }).format(priceMax)
                      : "" // Display empty string if no value
                  }
                />
                {errors.priceMax && (
                  <span className="text-sm text-red-400">{errors.priceMax}</span>
                )}
              </div>
            </div>
    
    {/* When to Buy Plan Dropdown */}
    <div className="flex flex-col gap-2">
      <label className="text-sm text-paragraphColor" htmlFor="whenToBuyPlan">
        When to Buy Plan <span style={{ color: "red" }}>*</span>
      </label>
      <select 
        className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" 
        id="whenToBuyPlan" 
        value={whenToBuyPlanValue} 
        onChange={(e) => setWhenToBuyPlanValue(e.target.value)}
      >
        <option value="">Select When to Buy Plan</option>
        {whenToBuyPlan.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errors.whenToBuyPlan && (
        <span className="text-sm text-red-400">{errors.whenToBuyPlan}</span>
      )}
    </div>

    {/* Credit Score Range Dropdown */}
    <div className="flex flex-col gap-2">
    <label className="text-sm text-paragraphColor" htmlFor="creditScoreRange">
        Credit Score Range <span style={{ color: "red" }}>*</span>
    </label>
    <select 
        className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" 
        id="creditScoreRange" 
        value={creditScoreRangeValue} 
        onChange={(e) => setCreditScoreRangeValue(e.target.value)}
    >
        <option value="">Select Credit Score Range</option>
        {creditScoreRange.map((option) => (
        <option key={option.value} value={option.value}>
            {option.label}
        </option>
        ))}
    </select>
    {errors.creditScoreRange && (
        <span className="text-sm text-red-400">{errors.creditScoreRange}</span>
    )}
    </div>


    <div className="flex flex-col gap-2">
      <label className="text-sm text-paragraphColor" htmlFor="downPayment">Down Payment <span style={{ color: "red" }}>*</span></label>
      <input 
        type="text"
        className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" 
        id="downPayment" 
        placeholder="Enter down payment amount"
        onChange={(e) => handleDownPaymentChange(e, setDownPayment)}
        value={
          downPayment
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
              }).format(downPayment)
            : "" // Display empty string if no value
        }
      />
      {errors.downPayment && (
        <span className="text-sm text-red-400">{errors.downPayment}</span>
      )}
    </div>


      {/* Message Textarea */}
            <div className="flex flex-col gap-2">
            <label className="text-sm text-paragraphColor" htmlFor="message">Message</label>
            <textarea 
              id="message" 
              className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" 
              placeholder="Enter your message here..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {errors.message && (
              <span className="text-sm text-red-400">{errors.message}</span>
            )}
          </div>

            <div className="flex  gap-8 items-center pt-6">
            <div className="flex-1"><button className="w-full border-gray-300 2xl:py-4 xl:py-4 lg:py-3.5 md:py-3 py-3 rounded-lg font-semibold text-gray-600  border">Cancel</button></div>
                 <div className="flex-1"><button type="submit" className="w-full text-sm 2xl:text-base lg:text-base xl:text-base md:text-sm 2xl:py-4 xl:py-4 lg:py-3.5 md:py-3 py-3 rounded-lg font-semibold text-paragraphColor bg-white  border" onClick={handleSubmit}>Save</button></div>
            </div>
        </form>
      </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerGetStarted;