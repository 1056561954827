import React ,{useState,useEffect, useRef}from 'react';
import { useLocation,useNavigate, Link} from 'react-router-dom'; 
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';
import {
  Box,
  Button,
  Text,
  useColorModeValue,
  Divider,
  Container,
  Flex,
  MenuItem,
  MenuButton,
  HStack,
  Menu,
  MenuList,
  useDisclosure
} from '@chakra-ui/react'; 
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { useProducts } from './ProductsProvider'; 
import { ChevronDownIcon } from '@chakra-ui/icons';
import CommonButton from './CommonButton';
import { propTypeLabels } from '../../../src/constants';
import noImage from '../../assets/images/nopropertyimg.png';
import PriceDisplay from '../../pages/DetailsPage/PriceDisplay';
import ModalComponent from '../../../src/components/ModalComponent';
import PhotoModal from '../../../src/components/user/PhotoModal';


const CustomAttributeLabel = ({ children }) => (
  <Box my={2} overflow="hidden">
    <Text h={35} p={2} textTransform="capitalize" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
      {children}
    </Text>
    <Divider orientation="horizontal" my={2} borderColor="gray.400" />
  </Box>
);
export { CustomAttributeLabel };

const CustomAttributeLabeldefault = ({ children }) => (
  <Box my={2} overflow="hidden">
    <Text h={35} p={2} textTransform="capitalize">
      {children}
    </Text>
    <Divider borderColor='grey.200' />
  </Box>  
);
export { CustomAttributeLabeldefault };

const CustomColorLabel = ({ backgroundColor }) => (
  <Box w={20} m={1} bg={'green.300'} />
);
export { CustomColorLabel };
const CustomPriceLabel = ({ children }) => (
  <Text color="green" fontSize="sm">
    {children}
  </Text>
);
export { CustomPriceLabel };


const ComparisonTable = ({ids, filters, props }) => {
  const {selectedProducts, removePropertyFromGroup,removeProductFromSelection, groupProductCounts } = useProducts();
  const [data, setData] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('Groups');
  const [userGroups, setUserGroups]= useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  let storedLanguage = localStorage.getItem('i18nextLng');

    if (!storedLanguage || storedLanguage.trim() === '') {
        storedLanguage = 'en';
    }

  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [total, setTotal] = useState(0);
  const itemsPerPage = 5;

  const { isOpen, onOpen, onClose,onToggle } = useDisclosure();
  const [modalHeader, setModalHeader] = useState('');
    const [modalBody, setModalBody] = useState(null);

  const containerRef = useRef(null);
  const [isEndReached, setIsEndReached] = useState(false);
  const newloadingRef = useRef(true);

  //const startIndex = (currentPage - 1) * itemsPerPage;
  //const currentPageData = data.slice(startIndex, startIndex + itemsPerPage);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    setData([]);
    newloadingRef.current=true;
    const storedUserId = localStorage.getItem('userid');
    if (storedUserId) {
      setUserId(storedUserId);
    }
    if (storedUserId) {
      //loading user groups
      async function loadingGroups() {
        const groupsResponse = await axiosInstance.get(`/usrgroup`);
        const groupsResponseData = groupsResponse.data;
        setUserGroups(groupsResponseData);
      };
      loadingGroups();
    }
  }, []);

  useEffect(()=>{
    if(groupProductCounts && groupProductCounts?.total>0){
      setTotal(groupProductCounts.total);
    }
  }, [groupProductCounts]);

  // useEffect(()=>{
  //   handleNavigateAndCallAPI();
  // }, [startIndex]);

  const handleCloseModal = () => {
    setModalHeader('');
    setModalBody(null);
    onClose();
  };

  const handleOpenPhotoModal = (item) => () => {
    setModalHeader("Property Images");
    const propertyId = item.id && item.id !== "N/A" 
      ? item.id 
      : item.id;
  
    setModalBody(
      <PhotoModal isOpen={true} onClose={onClose} propertyId={propertyId} />
    );
  
    onOpen();
  };

  const handleNavigateAndCallAPI = async () => {
    try {
      if(!ids){
        setData([]);
        const path = ids ? `/shortlisted?ids=${ids}` : `/shortlisted?ids=all`;
        navigate(path);
        return;
      }
      let propertyIdsArray = [];
      if (ids == 'all') {
        const detailsResponse = await axiosInstance.get(`/usrgroup/all/properties?from=${startIndex}&size=${itemsPerPage}`);
        
        // Convert response to JSON
        const data = detailsResponse.data;
        
        // Map over response data
        const compareList = Object.keys(data).map(id => Number(id));
        const propertyIdsCommaSeparated = compareList.join(',');
         propertyIdsArray = propertyIdsCommaSeparated.split(',');
      
      } else {
        const detailsResponse = await axiosInstance.get(`/usrgroup/${ids}/properties?from=${startIndex}&size=${itemsPerPage}`);
        
        // axios automatically returns parsed data
        const compareList = Object.keys(detailsResponse.data).map(id => Number(id));
        const propertyIdsCommaSeparated = compareList.join(',');
         propertyIdsArray = propertyIdsCommaSeparated.split(',');
      }

      const propertiesResponse = await axiosInstanceES.get(`/multiplepropertiesdata?ids=${propertyIdsArray}`);
      if(propertiesResponse.data && propertiesResponse.data.length>0){
        if(newloadingRef.current){
          setData([...propertiesResponse.data]); // Assuming the API returns an array
        }else{
          setData([...data, ...propertiesResponse.data]); // Assuming the API returns an array
        }
        newloadingRef.current=false;
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: 400, behavior: 'smooth' });
            setIsEndReached(false);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleNavigateWithoutLogin = async()=>{
    //const path = `/compare?ids=all`;
    // Navigate to the constructed path
   // navigate(path);

    const propertiesResponse = await axiosInstanceES.get(`/multiplepropertiesdata?ids=${selectedProducts.join(',')}`);
  
    setData([...data, ...propertiesResponse.data]); // Assuming the API returns an array
    if (containerRef.current) {
         containerRef.current.scrollBy({ left: (propertiesResponse.data.length*150), behavior: 'smooth' });
    }

  }

  useEffect(() => {
    if(userId != null){
      handleNavigateAndCallAPI();
    } else {
      handleNavigateWithoutLogin();
    }
  }, [ids, userId, startIndex]); 

  const handleRemoveProp = (propertyId)=>{
    if(userId != null){
      if (ids !== 'all') {
        removePropertyFromGroup(ids, propertyId);
      }else{
        // no group selected
      }
    }else{
      removeProductFromSelection(propertyId);
    }
  }
  const [images, setImages] = useState({});
  
  const getPhotosForProperty = async (propertyId) => {
    try {
      const response = await axiosInstanceES.get(`/properties/${propertyId}/smallphotos`);
      return response.data.map(item => ({ image: item.url }));
    } catch (error) {
      console.error('Error fetching photos:', error);
      return [];
    }
  };

  useEffect(() => {
    if (!data) return;

    const fetchImagesForProperties = async () => {
      const imagesMap = {};
      for (const item of data) {
        const images = await getPhotosForProperty(item.id);
        imagesMap[item.id] = images[0]?.image || '';
      }
      setImages(imagesMap);
    };

    fetchImagesForProperties();
  }, [data]);

  const scrollOneLeft = ()=>{
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  }
  const scrollOneRight = ()=>{
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
        // Check if the scroll has reached the end
        if (scrollLeft + clientWidth >= (scrollWidth-2)) {
            setIsEndReached(true);
        }else{
          setIsEndReached(false);
        }
  }
  
  //left or previous
  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -500, behavior: 'smooth' });
    }
  };

  //right or nextpage
  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 500, behavior: 'smooth' });
    }
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    console.log(scrollLeft, clientWidth, scrollWidth)
    // Check if the scroll has reached the end
    if (scrollLeft + clientWidth >= (scrollWidth-5)) {
      const totalpages = parseInt((total-1)/itemsPerPage)+1;
      const cp = currentPage+1;
      if(cp > totalpages){
        setCurrentPage(totalpages);
      }else{
        setCurrentPage(cp);
      }
      if((currentPage * itemsPerPage) < total)
        setStartIndex(currentPage * itemsPerPage);
      setIsEndReached(true);
    }else{
      setIsEndReached(false);
    }
  };

  useEffect(() => {
    if (!userId) return;
    const params = new URLSearchParams(location.search);
    const ids = params.get('ids');
    if (ids === 'all') {
      setSelectedGroup('All Groups');
      setTotal(groupProductCounts?.total || 0);
  } else {
      const matchedGroup = userGroups.find(group => group.id == parseInt(ids, 10));
      if (matchedGroup) {
          setSelectedGroup(matchedGroup.name);
      } else {
          setSelectedGroup('Unknown Group'); // Handle case where no match is found
      }
      setTotal(groupProductCounts?.total || 0);
  }
  
  }, [location.search, userGroups]);

  const groupFilter = (id)=>{
      setStartIndex(0);
      setCurrentPage(1)
      setData([]);
      newloadingRef.current=true;
       const path = `/shortlisted?ids=${id}`;
      navigate(path);
  }

 return (
    
<Container maxW='100%'>
<HStack align='Justify' spacing="5" mt="3">
    <Text fontSize="lg" color={'blue.500'} fontWeight="bold" mt={3}>Selected Properties</Text>
    {userId && (
    <Menu>
      <MenuButton mt={2} size='sm' as={Button} rightIcon={<ChevronDownIcon />}
      colorScheme={'blue'}
      hover={{ bg: 'gray.400' }}
      >
      {selectedGroup}
      </MenuButton>
      <MenuList>
       <MenuItem key="all" onClick={() => groupFilter('all')}>
          All Groups
        </MenuItem>
        {(userGroups||[]).map(group=>(
          <MenuItem key={group.id} onClick={() => groupFilter(group.id)}>{group.name}</MenuItem>
        ))}
      </MenuList>
    </Menu>
     )}
    </HStack>
    <Flex mt='5' justifyContent="space-between">
        <Button leftIcon={<FiArrowLeft />}  px="4" py="5" onClick={scrollLeft} mr={2}>
        </Button>
        {/* currentPage:{currentPage} startIndex: {startIndex} */}
        <Button leftIcon={<FiArrowRight />} px="4" py="5" onClick={scrollRight}>
        </Button>
      </Flex>
<Container ref={containerRef} className="compare-prdt-container" bgColor={"#fff"} maxWidth={'100%'} mt={5} gap={2}>
       {data.map((item, rowIndex) => {
          return (
            <Box className="compare-prdt-list" key={rowIndex} borderColor={"#fff"} borderWidth="1px" borderRadius="lg" gap={2}>
              <Box as="div" position="relative" p={4} bg="white" borderRadius="md" boxShadow="sm">
            <div
            key={rowIndex}
            className="grid grid-cols-1 shadow-lg w-full bg-white rounded-xl gap-6 p-6"
          >
            <div className="col-span-1 order-2">
              <div className="flex items-center gap-2 pb-3">
                <h3 className="2xl:text-3xl xl:text-3xl lg:text-2xl: md:text-2xl text-2xl font-bold text-paragraphColor">
                <PriceDisplay price={item.price} />
                </h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 0C3.5813 0 0 3.5813 0 8C0 12.4187 3.5813 16 8 16C12.4187 16 16 12.4187 16 8C16 3.5813 12.4187 0 8 0ZM8 14.5455C4.3908 14.5455 1.4546 11.6092 1.4546 8C1.4546 4.3908 4.3908 1.4545 8 1.4545C11.6092 1.4545 14.5454 4.3909 14.5454 8C14.5454 11.6091 11.6092 14.5455 8 14.5455ZM7.102 7.3171H8.898V12.859H7.102V7.3171ZM7.102 3.7906H8.898V5.5866H7.102V3.7906Z"
                    fill="#C1C4CC"
                  />
                </svg>
              </div>
              <p className="text-paragraphColor 2xl:text-base xl:text-base lg:text-base md:text-base text-sm pb-3 border-b border-dashed border-borderColor">
              {item.modelName}
              </p>
              <p className="text-paragraphColor 2xl:text-base xl:text-base lg:text-base md:text-base text-sm pb-3 border-b border-dashed border-borderColor">
              {propTypeLabels[item.property_type] || item.property_type}
              </p>
  
              <div className="flex flex-col divide-y divide-dashed  gap-1.5 border-b border-dashed">
                <div className="pt-1.5 pb-0.5 "> 
                <CommonButton
                  icon={"bed"}
                  value={item.bedrooms}
                  name={"Bedrooms"}
                  isLink={false}
                  color={"green"}
                ></CommonButton>
                </div>
                <div className="pt-1.5 pb-0.5 ">
                <CommonButton
                  icon={"bath"}
                  value={item.bathrooms}
                  name={"Bathrooms"}
                  isLink={false}
                  color={"blue"}
                ></CommonButton>
                </div>
               <div className="pt-1.5 pb-1.5 ">
               <CommonButton
                  icon={"length"}
                  value={item.sqft}
                  name={"Sq Ft"}
                  isLink={false}
                  color={"purple"}
                ></CommonButton>
               
               </div>
  
               <div className="pt-1.5 pb-1.5 ">
               <CommonButton
                  icon={"bath"}
                  value={item.partial_bathrooms}
                  name={"Half Bath Room"}
                  isLink={false}
                  color={"orange"}
                ></CommonButton>
               </div>
               <div className="pt-1.5 pb-1.5 ">
               <CommonButton
                  icon={"garage"}
                  value={item.garage_spaces}
                  name={"Garage"}
                  isLink={false}
                  color={"gray"}
                ></CommonButton>
               </div>
               <div className="pt-1.5 pb-1.5 ">
               <CommonButton
                  icon={"parking"}
                  value={item.parking}
                  name={"Parking"}
                  isLink={false}
                  color={"yellow"}
                ></CommonButton>
               </div>
               <div className="pt-1.5 pb-1.5 ">
               <CommonButton
                  icon={"stories"}
                  value={item.no_of_stories}
                  name={"Stories"}
                  isLink={false}
                  color={"red"}
                ></CommonButton>
               </div>
              </div>
  
              <div className="py-1.5 border-b border-dashed">
                <CommonButton
                  icon={"property"}
                  value={null}
                  name={item.modelName}
                  isLink={false}
                  color={"pink"}
                ></CommonButton>
              </div>
  
              <div className="flex flex-wrap  gap-1.5 py-3 border-b border-dashed">
                <CommonButton
                  icon={"photo"}
                  name={"Photos"}
                  isLink={false}
                  color={"gray"}
                  disablePointer={true}
                  onClick={handleOpenPhotoModal(item)}
                ></CommonButton>
              </div>

              {isOpen && (
              <ModalComponent isOpen={isOpen} onClose={handleCloseModal} modalHeader={modalHeader} modalBody={modalBody} />
            )} 
  
              <div className="mt-4">
                <Link to={`/home/${storedLanguage}/${item.state}/${item.city}/${item.id}/${encodeURIComponent(item.address)}/${item.communityname}/${item.property_type}/`} rel="noopener noreferrer" className="">
                  <button className="text-textColor hover:text-white hover:bg-[#0077ED] duration-300 transition-all font-semibold 2xl:py-4 xl:py-4 lg:py-4 md:py-3 py-2.5 border rounded-xl w-full">
                    View Details
                  </button>
                </Link>
                {ids&& ids !='all'&& 
                <Link to="#" rel="noopener noreferrer" className="">
                  <button onClick={()=>handleRemoveProp(item.id)} className="mt-4 text-textColor hover:text-white hover:bg-[#e40606] duration-300 transition-all font-semibold 2xl:py-4 xl:py-4 lg:py-4 md:py-3 py-2.5 border rounded-xl w-full">
                    Remove
                  </button>
                </Link>}
              </div>
             
            </div>
            <div className="w-full h-[250px]  col-span-1  ">
            {images[item.id] ? (
                <img
                  className=" h-full w-full object-cover rounded-xl"
                  src={images[item.id]}
                  alt={item.modelName}
                />
              ) : (  <img
                className=" h-full w-full object-cover rounded-xl"
                src={noImage}
                alt="Default Image"
              />
            )}
            </div>
          </div>
          </Box>
          </Box>
          );
        })} 
        {data.length==0&&<Box className="text-center w-full min-h-svh" pt={40} borderWidth="1px" borderRadius="lg" gap={2}>
          No Data found
          </Box>}

      </Container>
      {data.length> 3 &&
          <Flex mt='5' justifyContent="space-between">
            <Button leftIcon={<FiArrowLeft />}  px="4" py="5" onClick={scrollLeft} mr={2}>Previous
            </Button>
            <Button leftIcon={<FiArrowRight />} px="4" py="5" onClick={scrollRight}>Next
            </Button>
          </Flex>
      }
 </Container>
  );
};

export default ComparisonTable;
