
import {  useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import axios from 'axios';
import { useProducts } from "../compare/ProductsProvider";
import PopoverLogin from "../Popover";
import ShareWithFriendsModal from "../user/ShareWithFriendsModal";
import PhotoModal from "../user/PhotoModal";
import { propTypeLabels } from '../../constants';
import ShareGroupNames from '../user/ShareGroupNames';
import ModalComponent from '../ModalComponent';
import GroupMenu from '../user/GroupMenu';
import CommonButton from "../compare/CommonButton";
import PriceDisplay from "../../pages/DetailsPage/PriceDisplay";
import {formatAddress} from '../../utils/format.js'

const PropertyCard = (props) => {

    const { data, main = {}, community = {}, extraData={} } = props.hit;
    const { communityName } = community;
    const {
        propertyInfo = {},
        lotInfo = {},
        mlsListingPrice = 'N/A',
        mlsStatus = 'N/A',
        propertyType = 'N/A',
    } = data;

    const {
        address = {},
        bedrooms = 'N/A',
        bathrooms = 'N/A',
        buildingSquareFeet = 'N/A',
        yearBuilt = 'N/A',
    } = propertyInfo;

   // const { lotSquareFeet = 'N/A' } = lotInfo;
    const publicId = main.publicId || 'N/A';
    const city = propertyInfo.address.city;
    const state = propertyInfo.address.state;

   // const [isPhotoOpen, setIsPhotoOpen] = useState(false);

    const { selectedProducts, addProductToSelection, removeProductFromSelection, lovedProducts, addProductToLovedlist, removeProductFromLovedlist, viewProducts, addViewProductToSelection } = useProducts();
    const isProductSelected = selectedProducts && selectedProducts.includes(publicId);

    const isLoveitSelected = lovedProducts && lovedProducts.includes(publicId);
    const isviewSelected = viewProducts && viewProducts.includes(publicId);

    const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
    const [modalHeader, setModalHeader] = useState('');
    const [modalBody, setModalBody] = useState(null);
    const [images, setImages] = useState([]);

    const [userId, setUserId] = useState(null);

    const fetchImages = async (propertyId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/properties/${propertyId}/photos`);
            setImages(response.data);
            onOpen();
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    useEffect(() => {
        // Check if user ID exists in local storage
        const storedUserId = localStorage.getItem('userid');
        if (storedUserId) {
            setUserId(storedUserId);
        }
    }, []);

    let storedLanguage = localStorage.getItem('i18nextLng');

    if (!storedLanguage || storedLanguage.trim() === '') {
        storedLanguage = 'en';
    }

    const handleLoveList = productId => {
        if (isLoveitSelected) {
            removeProductFromLovedlist(productId);
        } else {
            addProductToLovedlist(productId);
        }
    }

    const handleCompare = productId => {
        if (isProductSelected) {
            removeProductFromSelection(productId);
        } else {
            addProductToSelection(productId);
        }
    }

    const handleViewList = productId => {
        if (isviewSelected) {
            addViewProductToSelection(productId);
        } else {
            addViewProductToSelection(productId);
        }

        //navigate(`/home/${storedLanguage}/${productId}/${encodeURIComponent(address.label)}/${props.hit.data.community?.communityName ? encodeURIComponent(props.hit.data.community.communityName) : ''}/${props.hit.data.propertyType ? encodeURIComponent(props.hit.data.propertyType) : ''}/`);
        const url = `/home/${storedLanguage}/${state}/${city}/${productId}/${encodeURIComponent(address.label)}/${props.hit.community?.communityName ? encodeURIComponent(props.hit.community.communityName) : ''}/${props.hit.data.propertyType ? encodeURIComponent(props.hit.data.propertyType) : ''}/`;
        window.open(url, '_blank');
    }

    const handleMarkerClick = (props) => {  
        if (props.hit.data.propertyInfo && props.hit.data.propertyInfo.address.label) {
          window.open(`https://www.google.com/maps/place/${encodeURIComponent(props.hit.data.propertyInfo.address.label)}`, '_blank');
        }
      };

    const handleShareWithFriend = () => {
        setModalBody(<div><PopoverLogin onClose={handleClose} /></div>);
        onOpen();
    };

    const handleClose = () => {
        onClose();
    };

    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleOpenModal = () => {
        setModalHeader('Invite & Share with Friends');
        setModalBody(<ShareWithFriendsModal isOpen={true} onClose={onClose} propertyId={publicId} />);
        onOpen();
    };

    const handleCloseModal = () => {
        setModalHeader('');
        setModalBody(null);
        onClose();
    };

    const handleOpenPhotoModal = () => {
        setModalHeader('Property Images');
        setModalBody(<PhotoModal isOpen={true} onClose={onClose} propertyId={publicId} />);
        onOpen();
    };

    return (
        <>
            {publicId && publicId !== "N/A" && (
                // <Flex justifyContent="space-between" alignItems="flex-start" position="relative" width="100%">
                //     <Stack direction="row" spacing="5" width="100%">
                //         <Box pt="4" position="relative" className="Community-property-item">
                //             <Stack spacing="0.5">
                //                 <Stack direction={'row'} align={'center'}>
                //                 {extraData.modelName && <Text fontWeight="medium" fontSize="14px" padding={'10px 0px'}>
                //                                 {extraData.modelName}
                //                             </Text>}
                //                     {mlsListingPrice && mlsListingPrice !== 'N/A' && (
                //                         <>
                //                             <Text fontWeight="medium" fontSize="18px" padding={'10px 0px'}>
                //                                 ${mlsListingPrice}
                //                             </Text>
                //                             <Text textDecoration={'line-through'} color={'gray.600'}>
                //                                 ${mlsListingPrice}
                //                             </Text>
                //                         </>
                //                     )}
                //                 </Stack>
                //                 <Text color={mode('gray.600', 'gray.400')} style={{ fontWeight: '400', padding: '10px 0px', fontSize: '14px', width: '80%' }}>
                //                     {bedrooms !== 'N/A' && `${bedrooms} Beds`} {bedrooms !== 'N/A' && <span className="vline">| &nbsp;&nbsp;</span>}
                //                     {bathrooms !== 'N/A' && `${bathrooms} Baths`} {bathrooms !== 'N/A' && <span className="vline">| &nbsp;&nbsp;</span>}
                //                     {buildingSquareFeet !== 'N/A' && `${buildingSquareFeet} Sq Ft`} {buildingSquareFeet !== 'N/A' && <span className="vline">| &nbsp;&nbsp;</span>}
                //                     {lotSquareFeet !== 'N/A' && `${lotSquareFeet} Sq Ft (lot)`}
                //                 </Text>
                //                 {address.label && (
                //                     <Text fontWeight="medium" padding={'10px 0px'}>{address.label}</Text>
                //                 )}
                //             </Stack>

                //             <HStack spacing="1" mt="3" color={mode('gray.600', 'gray.400')}>
                //                 {propertyType !== 'N/A' && (
                //                     <>
                //                         <Icon as={FaHome} boxSize="4" />
                //                         <Text fontSize="sm">
                //                             {propTypeLabels[propertyType]}
                //                         </Text>
                //                         <Text>&nbsp;</Text>
                //                     </>
                //                 )}
                //                 {yearBuilt !== 'N/A' && (
                //                     <>
                //                         <Icon as={FaCalendar} boxSize="4" />
                //                         <Text fontSize="sm">
                //                             {yearBuilt}
                //                         </Text>
                //                     </>
                //                 )}
                //             </HStack>

                //             {(userId && publicId && publicId != "N/A" ) && <ShareGroupNames propertyId={publicId}/>}

                //             <HStack spacing="1" mt="3">
                //             {userId ? (
                //             <GroupMenu propertyId={publicId} />
                //             ) : (
                //             props.hit.main.publicId && props.hit.main.publicId !== 'N/A' && (
                //                 <Button
                //                 mt={2}
                //                 colorScheme={isProductSelected ? 'red' : 'blue'}
                //                 size='sm'
                //                 leftIcon={<FaExchangeAlt />}
                //                 onClick={() => handleCompare(props.hit.main.publicId)}
                //                 >
                //                 {isProductSelected ? 'Remove' : 'Compare'}
                //                 </Button>
                //             )
                //             )}

                //                 <Button mt={2} colorScheme='blue' size='sm' onClick={handleOpenPhotoModal} leftIcon={<FiImage />} >
                //                     Photo
                //                 </Button>
                //             </HStack>
                //         </Box>
                //     </Stack>
                //     <Icon
                //         as={FaEllipsisV}
                //         boxSize={6}
                //         color="blue.500"
                //         ml={0}
                //         mt={4}
                //         style={{ cursor: 'pointer', position: 'relative', zIndex: 1, marginLeft: '-18px' }}
                //     />

                //     <Menu>
                //         <MenuButton
                //             as={Icon}
                //             icon={<FaEllipsisV />}
                //             boxSize={6}
                //             color="blue.500"
                //             ml={0}
                //             mt={4}
                //             style={{ cursor: 'pointer', position: 'relative', zIndex: 1, marginLeft: '-18px' }}
                //         />
                //         <MenuList>
                //             <MenuItem onClick={() => handleMarkerClick(props)} >View on Map</MenuItem>
                //             <MenuItem onClick={() => handleViewList(publicId)} formTarget='_blank'>Detail View</MenuItem>
                //         </MenuList>
                //     </Menu>
                //     {isOpen && (
                //         <ModalComponent isOpen={isOpen} onClose={handleCloseModal} modalHeader={modalHeader} modalBody={modalBody} />
                //     )}

                // </Flex>
                
                <div className="p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 2xl:p-14 gap-4 rounded-xl bg-white font-linksans">
                <div className="flex flex-col sm:flex-row justify-between w-full relative">
                  {/* Left Column */}
                  <div className="w-full sm:w-2/3 flex flex-col gap-4">
                    {/* Price and Details */}
                    <div className="flex items-center gap-2 pb-2 sm:pb-3">
                      {mlsListingPrice && mlsListingPrice !== "N/A" ? (
                        <>
                          <p className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800">
                            <PriceDisplay price={mlsListingPrice} />
                          </p>
                          {extraData.modelName && (
                            <p className="text-sm font-medium text-gray-600">{extraData.modelName}</p>
                          )}
                          <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 16 16" fill="none">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8 0C3.5813 0 0 3.5813 0 8C0 12.4187 3.5813 16 8 16C12.4187 16 16 12.4187 16 8C16 3.5813 12.4187 0 8 0ZM8 14.5455C4.3908 14.5455 1.4546 11.6092 1.4546 8C1.4546 4.3908 4.3908 1.4545 8 1.4545C11.6092 1.4545 14.5454 4.3909 14.5454 8C14.5454 11.6091 11.6092 14.5455 8 14.5455ZM7.102 7.3171H8.898V12.859H7.102V7.3171ZM7.102 3.7906H8.898V5.5866H7.102V3.7906Z"
                              fill="#C1C4CC"
                            />
                          </svg>
                        </>
                      ) : (
                        communityName && (
                          <p className="text-sm font-medium text-gray-600 py-2">{communityName}</p>
                        )
                      )}
                    </div>
              
                    {/* Property Type */}
                    {propertyType && propertyType !== "N/A" && (
                      <p className="text-gray-700 text-sm sm:text-base pb-3 border-b border-dashed border-gray-300">
                        {propTypeLabels[propertyType]}
                      </p>
                    )}
              
                    {/* Address */}
                    {address.label && address.label !== "N/A" && (
                      <p className="text-gray-700 text-sm sm:text-base pb-3 border-b border-dashed border-gray-300">
                        {formatAddress(address.label)}
                      </p>
                    )}
              
                    {/* Property Details */}
                    {publicId && publicId !== "N/A" && (
                      <div className="py-3 flex flex-wrap gap-2 border-b border-dashed border-gray-300">
                        {bedrooms && (
                          <CommonButton icon="bed" value={bedrooms} name="Bedrooms" color="green" />
                        )}
                        {bathrooms && (
                          <CommonButton icon="bath" value={bathrooms} name="Bathrooms" color="blue" />
                        )}
                        {buildingSquareFeet && (
                          <CommonButton icon="length" value={buildingSquareFeet} name="Sq Ft" color="purple" />
                        )}
                      </div>
                    )}
              
                    {/* Community Name */}
                    {publicId && communityName && (
                      <div className="py-3 border-b border-dashed border-gray-300">
                        <CommonButton icon="property" value={null} name={communityName} color="pink" />
                      </div>
                    )}
              
                    {/* Shared Group Names */}
                    {userId && publicId && (
                      <div className="py-3 border-b border-dashed border-gray-300">
                        <ShareGroupNames propertyId={publicId} />
                      </div>
                    )}
                  </div>
              
                  {/* Right Column */}
                  <div className="w-full sm:w-1/3 flex flex-col gap-4">
                    {/* Action Buttons */}
                    {publicId && (
                      <div className="flex flex-wrap gap-2 py-3 border-b border-dashed border-gray-300">
                        {userId && (
                          <GroupMenu propertyId={publicId} />
                        )}
                        <CommonButton icon="photo" name="Photos" color="gray" disablePointer={true} onClick={handleOpenPhotoModal} />
                        <CommonButton icon="map" name="View Map" color="gray" disablePointer={true} onClick={() => handleMarkerClick(props)} />
                        {isOpen && (
                          <ModalComponent isOpen={isOpen} onClose={handleCloseModal} modalHeader={modalHeader} modalBody={modalBody} />
                        )}
                      </div>
                    )}
              
                    {/* View Details Button */}
                    <div className="mt-4">
                      <button
                        onClick={() => handleViewList(publicId)}
                        className="text-sm sm:text-base font-semibold py-3 bg-blue-600 text-white rounded-xl w-full"
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              
            )}
        </>
    )
}
export default PropertyCard;
