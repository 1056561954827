import Hero from "./Hero"
import Analyze from "./Analyze"
import ReadyToBuy from "./ReadyToBuy"
import LetsTalk from "../../components/home/LetsTalk"
import Whatweoffer from "./Whatweoffer"
import SitemapCityStates from "./sitemapCityStates"


function HomeComponent() {
  return (
    <div className="font-linksans">
        <Hero></Hero> 
        <div >
        <ReadyToBuy></ReadyToBuy>
        <Whatweoffer></Whatweoffer>
        <Analyze></Analyze> 
        <SitemapCityStates />
        <LetsTalk></LetsTalk>
        </div>
    </div>
  )
}

export default HomeComponent;
